import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Echo from "laravel-echo";
import { API } from "../../services/base";
import axios from "../../services/https";
import { useNavigate } from "react-router-dom";

// Style
import "../../style/gameMaster/InGame.scss";

import GoBack from "../GoBack";
import moment from "moment";
import SpecificTeam from "./SpecificTeam";

// Redux
import { connect, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getTeams,
  member,
  startedGame,
  showGameId,
  completedSteps,
  givenHint,
  roomReset,
  finish,
  finisedTeam,
  getTeamsNewValue,
} from "../../redux/actions/app.js";

// Images
import InactiveSvg from "../gameMaster/InactiveSvg.js";
import ActiveSvg from "../gameMaster/ActiveSvg.js";
import inGameLogo from "../../images/inGameLogo.svg";
import logo from "../../images/logo.svg";
import light from "../../images/light.gif";

const InGame = () => {
  const [roomChannel, setRoomChannel] = useState("");
  const navigate = useNavigate();

  //   All about token
  const [cards, setCards] = useState(localStorage.getItem("token"));
  // const [otherTeam, setOtherTeam] = useState(
  //   JSON.parse(localStorage.getItem("persons"))
  // );
  const [soloCheck, setSoloCheck] = useState(localStorage.getItem("soloTrue"));
  let [token, setToken] = useState([]);
  token = window.location.href.split("/").pop();
  const [socketCh, setSocketCh] = useState("");
  const [gameId, setGameId] = useState("");

  const [allTeams, setAllTeams] = useState([]);
  const [otherTeams, setOtherTeams] = useState(null);
  const [bestTimes, setBestTimes] = useState([]);
  const [savedTeamsOnRefresh, setSavedTeamsOnRefresh] = useState([]);
  // let [currentTime, setCurrentTime] = useState("");

  let [checkTime, setCheckTime] = useState("");

  //   Redux
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  // Btn animate

  const [shake, setShake] = useState(false);
  const [glow, setGlow] = useState(false);

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setShake(false), 2000);
  };

  const removeGlow = () => {
    setTimeout(() => {
      setGlow(false);
    }, 2000);
  };

  useEffect(() => {
    // Call the removeGlow function when glow changes to true
    if (glow) {
      removeGlow();
    }
  }, [glow]);

  const getAllTeams = function (gameId) {
    axios.get(`${API}/game/${gameId}`).then((res) => {
      setBestTimes(res.data.data.bestTimes);
      setAllTeams(res.data.data.teams);
      dispatch(getTeams(res.data.data.teams));

      localStorage.setItem(
        "bestTimes",
        JSON.stringify(res.data.data.bestTimes)
      );
    });
  };

  useEffect(() => {
    // Retrieve bestTimes from localStorage on component mount
    const storedBestTimes = localStorage.getItem("bestTimes");
    if (storedBestTimes) {
      setBestTimes(JSON.parse(storedBestTimes));
    }
  }, []);

  // End timer

  // useEffect(() => {
  //   const teamInterval = setInterval(() => {
  //     if (localStorage.getItem("gameId")) {
  //       getAllTeams(localStorage.getItem("gameId"));
  //     }
  //   }, 3000);
  // }, []);

  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };

  useEffect(() => {
    axios
      .get(`${API}/room`)
      .then((res) => {
        setRoomChannel(res.data.data.roomScreenChannel);
        localStorage.setItem("roomChannel", res.data.data.roomScreenChannel);
        localStorage.setItem("checkGame", res.data.data.gameInProgress);
      })
      .catch((err) => {
        console.log(err);
        switch (err.response.status) {
          case 403:
            navigate("/");
          default:
            break;
        }
      }, []);

    if (localStorage.getItem("checkGame") != "null") {
      axios
        .get(`${API}/game/${localStorage.getItem("checkGame")}`)
        .then((res) => {
          setAllTeams(res.data.data.teams);
          dispatch(getTeams(res.data.data.teams));
          localStorage.setItem("socketChannel", res.data.data.socketChannel);

          const reloadCount = localStorage.getItem("reloadCount");
          if (reloadCount < 2) {
            localStorage.setItem("reloadCount", String(reloadCount + 1));
            window.location.reload();
          } else {
            localStorage.removeItem("reloadCount");
          }
        });

      localStorage.setItem("gameId", localStorage.getItem("checkGame"));

      dispatch(showGameId(localStorage.getItem("checkGame")));
      SetSubscribed(true);

      // Zakomentarisao 25.03
      // axios
      //   .get(`${API}/game/${localStorage.getItem("checkGame")}/winner`)
      //   .then((res) => {
      //     setBestTimes(res.data.data.bestTimes);
      //   })
      //   .catch();
    }

    localStorage.setItem("token", token);
  }, [localStorage.getItem("checkGame")]);

  useEffect(() => {
    setAllTeams(app.get);
  }, [app.get]);

  // Zakomentarisao 25.03

  // useEffect(() => {
  //   if (app.gameid != null) {
  //     axios
  //       .get(`${API}/game/${app.gameid}/winner`)
  //       .then((res) => {
  //         setBestTimes(res.data.data.bestTimes);
  //       })
  //       .catch();
  //   }
  // }, [app.gameid]);

  //   Socket

  window.Pusher = require("pusher-js");

  // channel.private-game-1 = roomScreenChannel
  let [count, setCount] = useState(0);
  const [subsribed, SetSubscribed] = useState(false);

  useEffect(() => {
    setCards(localStorage.getItem("token"));
  }, []);

  useEffect(() => {
    // alert("asdf");
    if (roomChannel) {
      // alert(localStorage.getItem("socketChannel"));
      window.Echo = new Echo({
        broadcaster: "pusher",
        key: "2UhByxffTQ7XjcYWgfHbumDj",
        cluster: "mt1",
        authEndpoint: `https://prod.arenacompetition.world/broadcasting/auth`,
        wsHost: "prod.arenacompetition.world",
        auth: {
          headers: {
            accept: "application/json",
            Authorization: "Bearer " + `${cards}`,
          },
        },
        wsPort: 6001,
        wssPort: 6001,
        disableStats: true,
        encrypted: true,
        enabledTransports: ["ws", "wss"],
        transports: ["websocket"],
      });

      window.Echo.channel(`${roomChannel}`)
        .listen(".redraw", (e) => {
          window.location.reload(false);

          localStorage.setItem("gameId", e.gameId);
          localStorage.setItem("socketChannel", e.socketChannel);

          dispatch(showGameId(e.gameId));
          SetSubscribed(true);
        })
        .listen(".reset", (e) => {
          navigate(`/in-game/${cards}`);
          localStorage.removeItem("persist:root");
          localStorage.removeItem("roomChannel");
          localStorage.removeItem("gameId");
          localStorage.removeItem("socketChannel");
          window.location.reload(false);
        });

      if (localStorage.getItem("gameId")) {
        dispatch(showGameId(localStorage.getItem("gameId")));
        getAllTeams(localStorage.getItem("gameId"));
      }

      if (localStorage.getItem("socketChannel")) {
        window.Echo.channel(localStorage.getItem("socketChannel"))
          .listen(".game.game-started", (e) => {
            dispatch(startedGame(e));
            setCount(count++);
            window.location.reload(false);
          })
          .listen(".game.hint-given", (e) => {
            dispatch(givenHint(e));
            setShake(!shake);

            var element = document.getElementById("allTeams");
            element.classList.remove("anim");
            element.classList.add("animi");

            const teamElement = document.getElementById(e.teamId);
            teamElement.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });

            setTimeout(() => {
              console.log("test");
              element.classList.remove("animi");
              element.classList.add("anims");

              // Continue scrolling after 1 second
              const scrollOptions = {
                behavior: "smooth",
                block: "end",
              };
              window.scrollTo(scrollOptions);
            }, 1000);
          })
          .listen(".game.step-changed", (e) => {
            dispatch(completedSteps(e));
            setGlow(!glow);

            var element = document.getElementById("allTeams");
            element.classList.remove("anim");

            const teamElement = document.getElementById(e.teamId);
            teamElement.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });

            setTimeout(() => {
              console.log("test");
              element.classList.remove("animi");
              element.classList.add("anims");

              // Continue scrolling after 1 second
              const scrollOptions = {
                behavior: "smooth",
                block: "end",
              };
              window.scrollTo(scrollOptions);
            }, 1000);
          })
          .listen(".game.team-joined", (e) => {
            dispatch(member(e));
          })
          .listen(".game.team-finished", (e) => {
            dispatch(finisedTeam(e));
          })
          .listen(".game.game-finished", (e) => {
            dispatch(finish(e));
            navigate("/winning-display");
          });
      }
    }
  }, [roomChannel]);

  // TIMER

  const [endDate, setEndDate] = useState(null);

  var end = endDate;

  const updateTimeLeft = () => {
    setAllTeams((previousState) =>
      previousState.map((team, index) => {
        if (team.gameStartedAt && !team.gameFinishedAt) {
          const myzone = moment.tz.guess();
          const myTime = moment().tz("UTC");
          const gameStartedAt = moment(team.gameStartedAt).tz("UTC", true);
          const elapsedTimeInSeconds = myTime.diff(gameStartedAt, "seconds");
          const roomDurationInSeconds = parseInt(team.roomDuration) * 60;
          const timerInSecond = roomDurationInSeconds - elapsedTimeInSeconds;

          const timer =
            String(Math.floor(timerInSecond / 60)).padStart(2, "0") +
            ":" +
            String(Math.floor(timerInSecond % 60)).padStart(2, "0");

          // if (timerInSecond === 0) {
          //   const id = localStorage.getItem("gameId");
          //   axios
          //     .post(`${API}/game/${id}/finish`, {
          //       game: id,
          //     })
          //     .then((res) => {
          //       navigate("/winning-display");
          //     })
          //     .catch((err) => {
          //       console.log(err);
          //     });
          // }

          team.roomTimer = timer;
        } else if (team.gameStartedAt && team.gameFinishedAt) {
          const gameStartedAt = moment(team.gameStartedAt);
          const gameFinishedAt = moment(team.gameFinishedAt);
          const timerInSecond = gameFinishedAt.diff(gameStartedAt, "seconds");
          const roomDurationInSeconds = parseInt(team.roomDuration) * 60;
          const timerBetween = roomDurationInSeconds - timerInSecond;

          const timer =
            String(Math.floor(timerBetween / 60)).padStart(2, "0") +
            ":" +
            String(Math.floor(timerBetween % 60)).padStart(2, "0");

          team.roomTimer = timer;
        }

        return team;
      })
    );
  };

  useEffect(() => {
    const intervalId = setInterval(updateTimeLeft, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // const trueObjects = app.get.filter((obj) => true);
  const trueObjects = app.get.filter((obj) => obj.gameStartedAt);

  useEffect(() => {
    if (localStorage.getItem("gameId")) {
      getAllTeams(localStorage.getItem("gameId"));
    }
  }, [count]);

  const [state, setState] = useState({ num: 0 });
  const counter = React.useRef(0);

  useEffect(() => {
    counter.current += 1;
    const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);

    return () => clearTimeout(timer);
  }, [state]);

  return (
    <div className="inGame">
      <GoBack />
      {localStorage.getItem("checkGame") === "null" ? (
        <div className="inGameLogo">
          <img src={logo} />
        </div>
      ) : (
        <div>
          <div className="inGameContent">
            <img className="inLogo" src={inGameLogo} alt={inGameLogo} />
            <div className="topBoard">
              <ul>
                <li>Team Photo</li>
                <li>Team Name</li>
                <li>company & room</li>
                <li>city</li>
                <li>hints given</li>
                <li>time left</li>
                <li>points</li>
              </ul>
            </div>

            <div
              className={
                trueObjects.length > 4 ? "teamsInGame teamsGame" : "teamsInGame"
              }
            >
              <div
                className={trueObjects.length > 4 ? "anim" : ""}
                id={trueObjects.length > 4 ? "allTeams" : ""}
              >
                {!allTeams
                  ? ""
                  : trueObjects.map((item, index) => {
                      return (
                        <div
                          className="specificTeam"
                          key={item.id}
                          id={item.id}
                        >
                          <div className="starginPlayer">
                            <span>{index + 1}</span>
                            <img
                              src={`https://prod.arenacompetition.world${item.image}`}
                              alt={`https://prod.arenacompetition.world${item.image}`}
                            />
                          </div>
                          <div className="wraperProgress">
                            <div className="teamName">
                              {" "}
                              <h6>{item.name}</h6>
                            </div>
                            <div className="companyAndRoom">
                              <h5
                                className={
                                  item.companyName.length > 15
                                    ? "companyAndRoomResize"
                                    : ""
                                }
                              >
                                {item.companyName}
                              </h5>
                              <h6>{item.roomName}</h6>
                            </div>
                            <div className="teamCity">
                              <h6>
                                {" "}
                                {!item.roomLocation ? "-" : item.roomLocation}
                              </h6>
                            </div>
                            {(() => {
                              if (item.hintCount === 1) {
                                return (
                                  <div className="givenHints">
                                    <div className="hintWrapp">
                                      <ActiveSvg
                                        className={shake ? `shake` : null}
                                      />
                                      <div
                                        // src={light}
                                        className={shake ? `imgShake` : null}
                                      />
                                    </div>

                                    <InactiveSvg />
                                    <InactiveSvg />
                                    <InactiveSvg />
                                    <InactiveSvg />
                                  </div>
                                );
                              } else if (item.hintCount === 2) {
                                return (
                                  <div className="givenHints">
                                    <ActiveSvg />
                                    <div className="hintWrapp">
                                      {" "}
                                      <ActiveSvg
                                        className={shake ? `shake` : null}
                                      />
                                      <div
                                        // src={light}
                                        className={shake ? `imgShake` : null}
                                      />
                                    </div>

                                    <InactiveSvg />
                                    <InactiveSvg />
                                    <InactiveSvg />
                                  </div>
                                );
                              } else if (item.hintCount === 3) {
                                return (
                                  <div className="givenHints">
                                    <ActiveSvg />
                                    <ActiveSvg />
                                    <div className="hintWrapp">
                                      {" "}
                                      <ActiveSvg
                                        className={shake ? `shake` : null}
                                      />
                                      <div
                                        // src={light}
                                        className={shake ? `imgShake` : null}
                                      />
                                    </div>
                                    <InactiveSvg />
                                    <InactiveSvg />
                                  </div>
                                );
                              } else if (item.hintCount === 4) {
                                return (
                                  <div className="givenHints">
                                    <ActiveSvg />
                                    <ActiveSvg />
                                    <ActiveSvg />
                                    <div className="hintWrapp">
                                      {" "}
                                      <ActiveSvg
                                        className={shake ? `shake` : null}
                                      />
                                      <div
                                        // src={light}
                                        className={shake ? `imgShake` : null}
                                      />
                                    </div>
                                    <InactiveSvg />
                                  </div>
                                );
                              } else if (item.hintCount === 5) {
                                return (
                                  <div className="givenHints">
                                    <ActiveSvg />
                                    <ActiveSvg />
                                    <ActiveSvg />
                                    <ActiveSvg />
                                    <div className="hintWrapp">
                                      {" "}
                                      <ActiveSvg
                                        className={shake ? `shake` : null}
                                      />
                                      <div
                                        // src={light}
                                        className={shake ? `imgShake` : null}
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (item.hintCount > 5) {
                                return (
                                  <div className="givenHints">
                                    <ActiveSvg />
                                    <ActiveSvg />
                                    <ActiveSvg />
                                    <ActiveSvg />
                                    <ActiveSvg />
                                    <div className="aditionalHints">
                                      + {`${item.hintCount - 5}`}
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="givenHints">
                                    <InactiveSvg />
                                    <InactiveSvg />
                                    <InactiveSvg />
                                    <InactiveSvg />
                                    <InactiveSvg />
                                  </div>
                                );
                              }
                            })()}

                            <div className="timeLeft">
                              <div className="time">
                                {item.roomTimer <= "00:00"
                                  ? "00:00"
                                  : item.roomTimer}
                              </div>
                            </div>
                            <div className="points">
                              {Math.round(`${item.points}`)}
                            </div>

                            <div
                              className={
                                glow ? `progresses glow` : "progresses"
                              }
                            >
                              <progress
                                id="file"
                                value={item.progress}
                                max="100"
                                data-progress={item.progress}
                              ></progress>
                              <div
                                className="progress-dot"
                                style={{
                                  left: `calc(${item.progress}% - 3px)`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
          {/* Top players */}
          {trueObjects.length === 1 ? (
            <div className="topWrapper">
              <h4>Top 10 challenge</h4>
              <div className="topPlayers">
                <div className="leftSide">
                  {bestTimes.slice(0, 5).map((item, index) => {
                    return (
                      <div className="topPlayerCard" key={item.id}>
                        <div className="teamRate">{index + 1}</div>
                        <div className="teamName">{item.name}</div>
                        <div className="teamScore">
                          <span>{item.points} POINTS</span> &nbsp; /{" "}
                          {item.hintCount} HINTS / {item.timeLeft.slice(0, 2)}m{" "}
                          {item.timeLeft.slice(3, 5)}s LEFT
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="rightSide">
                  {bestTimes.slice(5, 10).map((item, index) => {
                    return (
                      <div className="topPlayerCard" key={item.id}>
                        <div className="teamRate">{index + 6}</div>
                        <div className="teamName">{item.name}</div>
                        <div className="teamScore">
                          <span>{item.points} POINTS</span> &nbsp; /{" "}
                          {item.hintCount} HINTS / {item.timeLeft.slice(0, 2)}m{" "}
                          {item.timeLeft.slice(3, 5)}s LEFT
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* End top players */}
        </div>
      )}
    </div>
  );
};

export default InGame;
