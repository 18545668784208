import React from "react";

import logo from "../images/newLogo.svg";

const LogoPosition = () => {
  return (
    <div style={{ textAlign: "center" }}>
      {" "}
      <img
        src={logo}
        alt="logo"
        style={{ width: "fit-content", margin: "0 auto" }}
      />
    </div>
  );
};

export default LogoPosition;
