import React from "react";

// Style
import "../../../style/home/Rooms.scss";

// Images
import Baner from "../../../images/Winning_Banner.png";
import Dots from "../../../images/dots.png";

const TopTen = () => {
  const handleRoute = () => {
    const contactElement = document.getElementById("contact");
    if (contactElement) {
      contactElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="roomWrapper">
      <div className="line"></div>

      <img className="dots" src={Dots} alt={Dots} />

      <div className="box">
        <h2>Elite Top 5: Turn Customers into Raving Fans</h2>
      </div>
      <div className="boxList">
        <ul>
          <li>
            <h5>Showcase the best of the best</h5>
            <p>
              This isn't just a leaderboard; it's a magnet for competition and
              excitement. Our cutting-edge AWS cloud technology is always at
              work, updating and showcasing the best scores from each of your
              rooms. Imagine this: a sleek TV display, strategically placed in
              your reception area, facing the bustling street, hallways, or even
              in the restrooms – anywhere it can spark curiosity and challenge
              your customers.
            </p>
          </li>
          <li>
            <h5>Free Marketing</h5>
            <p>
              Every time a team makes it into the Elite Top 5, they'll want to
              share their achievement on social media, advertising your escape
              room for free. Every shared photo is a shout-out to your escape
              room, showcasing its name, city, and the variety of rooms you
              offer.
            </p>
          </li>
          <li>
            <h5>what’s the record for this room?</h5>
            <p>
              Elite Top 5 answers this common customer question with style. No
              more vague responses about room records or fairness concerns.
            </p>
          </li>
        </ul>

        <button className="contactUs" onClick={() => handleRoute()}>
          <span>Speak To Our Team</span>
        </button>
      </div>

      <img className="banerImage" src={Baner} alt={Baner} />
    </div>
  );
};

export default TopTen;
