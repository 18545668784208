import React, { useEffect, useState } from "react";
import "../../style/gameMaster/ListingPage.scss";
import hint from "../../images/hint-inactive.svg";
import hintActive from "../../images/hint-active-red.svg";
import team from "../../images/team.jpg";
import undoIcon from "../../images/undo-icon.svg";

import { useTransition, animated } from "react-spring";
import Button from "../../elements/Button";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { API } from "../../services/base";
import axios from "../../services/https";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GoBack from "../GoBack";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import { TailSpin } from "react-loader-spinner";

import { domen } from "../../services/base";

const ListingPage = () => {
  const [complete, setComplete] = useState(false);
  const [giveHint, setGiveHint] = useState(false);
  const [resetRoom, setResetRoom] = useState(false);
  const [undoStep, setUndoStep] = useState(false);
  let [hintCount, setHintCount] = useState(0);
  const [matchId, setMatchiTD] = useState(localStorage.getItem("gameId"));
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [stepUndo, setStepUndo] = useState("");

  let [newHints, setNewHints] = useState(0);
  let [currentTime, setCurrentTime] = useState("");

  const [listingDetails, setListingDetails] = useState("");
  const [steps, setSteps] = useState([]);

  const [expanded, setExpanded] = useState("panel1");

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //   Complete
  const transitionsComplete = useTransition(complete, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const completeTransitions = useTransition(complete, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });
  //   End complete

  //   Give hint
  const transitionsHint = useTransition(giveHint, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const hintTransitions = useTransition(giveHint, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });
  //   End give hint

  // Undo step
  const transitionsUndo = useTransition(undoStep, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const undoTransitions = useTransition(undoStep, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });
  // End undo step

  //   Reset room
  const transitionsReset = useTransition(resetRoom, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const resetTransitions = useTransition(resetRoom, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });
  //   End reset room

  const [teamGameId, setTeamGameId] = useState("");
  const [gmData, setGmData] = useState("");

  const fetchGmData = () => {
    axios
      .get(`${API}/room`)
      .then((res) => {
        setListingDetails(res.data.data);
        setTeamGameId(res.data.data.gameInProgress.id);

        const fetchedGmData = res.data.data.gameInProgress.teams;
        setGmData(fetchedGmData);
        // setGmData(res.data.data.gameInProgress.teams);

        // Initialize steps with dynamic data from the API
        // Initialize steps with dynamic data from the API
        const dynamicSteps = res.data.data.steps;

        // Retrieve the checked values from localStorage if they exist
        // const storedCheckedSteps =
        //   JSON.parse(localStorage.getItem("checkedSteps")) || {};

        // Merge the dynamic data with the stored checked values
        // const updatedSteps = dynamicSteps.map((step) => ({
        //   ...step,
        //   checked: storedCheckedSteps[step.id] || false, // Use stored value or set to false
        // }));

        setSteps(res.data.data.steps);
        console.log(steps);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [elapsedTime, setElapsedTime] = useState({
    minutes: "00",
    seconds: "00",
  });
  const [roomDuration, setRoomDuration] = useState("");

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    axios.get(`${API}/room`).then((res) => {
      const foundItem = res.data.data.gameInProgress.teams.find(
        (item) => item.roomId === listingDetails.id
      );
      setLoader(false);
      setRoomDuration(res.data.data.duration);

      if (foundItem) {
        const initialElapsedTime = foundItem.elapsedTime;
        const [minutes, seconds] = initialElapsedTime.split(":");
        setElapsedTime({
          minutes: minutes,
          seconds: seconds,
        });

        const durationInSeconds = res.data.data.duration * 60;
        const minutesDuration = Math.floor(durationInSeconds / 60);
        const secondsDuration = durationInSeconds % 60;
        const formattedDuration = `${minutesDuration}:${secondsDuration
          .toString()
          .padStart(2, "0")}`;

        const intervalId = setInterval(() => {
          setElapsedTime((prevElapsedTime) => {
            let { minutes, seconds } = prevElapsedTime;
            seconds++;

            if (seconds === 60) {
              minutes++;
              seconds = 0;
            }

            if (minutes >= minutesDuration) {
              const data = {
                game: teamGameId,
              };
              axios
                .post(`${API}/game/${teamGameId}/finish`, data)
                .then((res) => {
                  const completeTeam = res.data.data.teams.find((obj) => {
                    return obj.id === app.team.id;
                  });
                  navigate("/congratulations");
                })
                .catch((err) => {
                  console.log(err);
                });
              clearInterval(intervalId);
            }

            // Format minutes and seconds separately
            const formattedMinutes = minutes.toString().padStart(2, "0");
            const formattedSeconds = seconds.toString().padStart(2, "0");

            return {
              minutes: formattedMinutes,
              seconds: formattedSeconds,
            };
          });
        }, 1000);

        return () => {
          clearInterval(intervalId);
        };
      }
    });
  }, [listingDetails.id]);

  useEffect(() => {
    fetchGmData();
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(`${API}/room`)
  //     .then((res) => {
  //       setListingDetails(res.data.data);
  //       setTeamGameId(res.data.data.gameInProgress.id);
  //       const dynamicSteps = res.data.data.steps;

  //       // Retrieve the checked values from localStorage if they exist
  //       const storedCheckedSteps =
  //         JSON.parse(localStorage.getItem("checkedSteps")) || {};

  //       // Merge the dynamic data with the stored checked values
  //       const updatedSteps = dynamicSteps.map((step) => ({
  //         ...step,
  //         checked: storedCheckedSteps[step.id] || false, // Use stored value or set to false
  //       }));

  //       setSteps(updatedSteps);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const handleCompleteRoom = () => {
    const data = {
      game: teamGameId,
    };
    axios
      .post(`${API}/game/${teamGameId}/finish`, data)
      .then((res) => {
        navigate("/congratulations");

        setHintCount(0);
        localStorage.removeItem("hintCount");
        const updatedSteps = steps.map((step) => ({
          ...step,
          checked: false,
        }));
        setSteps(updatedSteps);

        // Update the checked values in local storage
        const storedCheckedSteps = {};
        localStorage.setItem(
          "checkedSteps",
          JSON.stringify(storedCheckedSteps)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGiveHint = () => {
    const data = {
      game: teamGameId,
    };
    axios
      .post(`${API}/game/${teamGameId}/hint`, data)
      .then((res) => {
        setGiveHint(!giveHint);
        // setHintCount(hintCount + 1);
        fetchGmData();
        // localStorage.setItem("hintCount", hintCount + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleResetRoom = () => {
    const data = {
      game: teamGameId,
    };

    axios
      .post(`${API}/game/${teamGameId}/finish`, data)
      .then((res) => {
        navigate(`/check/${token}`);
        setTimeout(() => {
          axios
            .post(`${API}/room/reset`)
            .then((res) => {
              navigate(`/check/${token}`);
              localStorage.removeItem("persist:root");
              localStorage.removeItem("roomChannel");
              localStorage.removeItem("gameId");
              localStorage.removeItem("socketChannel");

              window.location.reload(false);

              setHintCount(0);
              localStorage.removeItem("hintCount");
              const updatedSteps = steps.map((step) => ({
                ...step,
                checked: false,
              }));
              setSteps(updatedSteps);

              // Update the checked values in local storage
              const storedCheckedSteps = {};
              localStorage.setItem(
                "checkedSteps",
                JSON.stringify(storedCheckedSteps)
              );
            })
            .catch((err) => {
              console.log(err);
            });
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     var myzone = moment.tz.guess();
  //     let start = moment.utc(app.time).tz(myzone);
  //     currentTime = moment();
  //     let diff = currentTime.diff(start);

  //     currentTime = moment.utc(diff).format("mm:ss");

  //     setCurrentTime(moment.utc(diff).format("mm:ss"));
  //   }, 600);

  //   const roomDurationInSeconds = parseInt(app.team.roomDuration) * 60;

  //   const timer =
  //     String(Math.floor(roomDurationInSeconds / 60)).padStart(2, "0") +
  //     ":" +
  //     String(Math.floor(roomDurationInSeconds % 60)).padStart(2, "0");

  //   if (currentTime === timer) {
  //     // const data = {
  //     //   game: matchId,
  //     // };
  //     // axios
  //     //   .post(`${API}/game/${matchId}/finish`, data)
  //     //   .then((res) => {
  //     //     const completeTeam = res.data.data.teams.find((obj) => {
  //     //       return obj.id === app.team.id;
  //     //     });
  //     //     navigate("/congratulations");
  //     //   })
  //     //   .catch((err) => {
  //     //     console.log(err);
  //     //   });
  //   }

  //   return () => clearInterval(interval);
  // }, [currentTime]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     var myzone = moment.tz.guess();
  //     let start = moment.utc(app.time).tz(myzone);
  //     currentTime = moment();
  //     let diff = currentTime.diff(start);

  //     currentTime = moment.utc(diff).format("mm:ss");

  //     setCurrentTime(moment.utc(diff).format("mm:ss"));
  //   }, 600);

  //   const roomDurationInSeconds = parseInt(app.team.roomDuration) * 60;

  //   const timer =
  //     String(Math.floor(roomDurationInSeconds / 60)).padStart(2, "0") +
  //     ":" +
  //     String(Math.floor(roomDurationInSeconds % 60)).padStart(2, "0");

  //   if (currentTime >= timer) {
  //     const data = {
  //       game: teamGameId,
  //     };
  //     axios
  //       .post(`${API}/game/${teamGameId}/finish`, data)
  //       .then((res) => {
  //         navigate("/congratulations");
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  //   return () => clearInterval(interval);
  // }, [currentTime]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const myzone = moment.tz.guess();
  //     const start = moment.utc(app.time).tz(myzone);
  //     const currentTime = moment();
  //     const diff = currentTime.diff(start);
  //     const currentTimeFormatted = moment.utc(diff).format("mm:ss");

  //     setCurrentTime(currentTimeFormatted);

  //     const roomDurationInSeconds = parseInt(app.team.roomDuration) * 60;

  //     // Calculate the timer in seconds
  //     const timerInSeconds = roomDurationInSeconds - diff / 1000;

  //     if (timerInSeconds <= 0) {
  //       // The time has run out, perform your action
  //       const data = {
  //         game: teamGameId,
  //       };

  //       axios
  //         .post(`${API}/game/${teamGameId}/finish`, data)
  //         .then((res) => {
  //           navigate("/congratulations");
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });

  //       // Clear the interval
  //       clearInterval(interval);
  //     }
  //   }, 600);

  //   // Clean up the interval when the component unmounts
  //   return () => clearInterval(interval);
  // }, [app.time, app.team.roomDuration, teamGameId, navigate]);

  // const handleResetRoom = () => {
  //   axios
  //     .post(`${API}/room/reset`)
  //     .then((res) => {
  //       navigate(`/check/${token}`);
  //       // navigate("/available-matches");
  //       // dispatch(getResetRoom(app.team));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleCheckBox = (event, index, id) => {
    const checked = event.target.checked;

    const element = document.getElementById(id);
    element.style.display = "block";

    const data = {
      game: teamGameId,
      step: id,
      checked,
    };

    axios
      .post(`${API}/game/${teamGameId}/step/${id}`, data)
      .then((res) => {
        // Handle the response data as needed
        console.log(res.data.data.completedSteps, "post");

        // Update the steps in the state
        const updatedSteps = steps.map((step) =>
          step.id === id ? { ...step, checked } : step
        );
        setSteps(updatedSteps);

        // Update the checked values in local storage
        const storedCheckedSteps =
          JSON.parse(localStorage.getItem("checkedSteps")) || {};
        storedCheckedSteps[id] = checked;
        localStorage.setItem(
          "checkedSteps",
          JSON.stringify(storedCheckedSteps)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const savedClickedSteps = localStorage.getItem("clickedSteps");
    if (savedClickedSteps) {
      const parsedClickedSteps = JSON.parse(savedClickedSteps);
      console.log("Parsed Clicked Steps:", parsedClickedSteps);
      setSteps(parsedClickedSteps);
    }
  }, []);

  const handleUndoStepPopup = () => {
    const data = {
      game: teamGameId,
      step: stepUndo,
    };

    axios
      .delete(`${API}/game/${teamGameId}/step/${stepUndo}`, data)
      .then((res) => {
        setUndoStep(!undoStep);

        setSteps((prevSteps) =>
          prevSteps.map((step) =>
            step.id === stepUndo ? { ...step, checked: false } : step
          )
        );

        const storedCheckedSteps =
          JSON.parse(localStorage.getItem("checkedSteps")) || {};
        storedCheckedSteps[stepUndo] = false; // Set to false
        localStorage.setItem(
          "checkedSteps",
          JSON.stringify(storedCheckedSteps)
        );

        const element = document.getElementById(stepUndo);
        element.style.display = "none";

        const text = document.getElementById("step" + stepUndo);
        text.style.color = "#fff";

        document.getElementById("check" + stepUndo).checked = false;

        // var x = document.getElementById("check" + stepUndo);
        // var i;
        // for (i = 0; i <= x.length; i++) {
        //   x[i].value = false;
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUndo = (id) => {
    setStepUndo(id);
    setUndoStep(!undoStep);
  };

  // Convert duration from minutes to mm:ss format
  const durationInMinutes = listingDetails.duration;
  const minutes = Math.floor(durationInMinutes);
  const seconds = Math.floor((durationInMinutes - minutes) * 60);

  // Create mm:ss formatted string
  const formattedDuration = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return (
    <div className="listingPage">
      <div className="listingPageWrap">
        <GoBack />
        {/* Leave match */}
        {transitionsComplete(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="popupBackground"
                onClick={() => setComplete(false)}
              ></animated.div>
            )
        )}

        {completeTransitions(
          (styles, item) =>
            item && (
              <animated.div style={styles} className="matchPopup">
                <div className="matchContent">
                  <h2 className="leaveMatchTitle">
                    {/* Are You Sure You Want To Complete Room? */}
                    Please confirm
                  </h2>
                  <Button
                    title="COMPLETE THE ROOM"
                    className="stayBtn"
                    onClick={handleCompleteRoom}
                  />
                  <Link
                    className="leaveMatch"
                    onClick={() => setComplete(false)}
                  >
                    go back
                  </Link>
                </div>
              </animated.div>
            )
        )}
        {/* End leave match */}

        {/* Give hint */}
        {transitionsHint(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="popupBackground"
                onClick={() => setGiveHint(false)}
              ></animated.div>
            )
        )}

        {hintTransitions(
          (styles, item) =>
            item && (
              <animated.div style={styles} className="matchPopup">
                <div className="matchContent">
                  <h2 className="leaveMatchTitle">
                    {/* Are you sure you want to give hint to your team? */}
                    Give a hint?
                  </h2>
                  <Button
                    title="Yes!"
                    className="stayBtn"
                    onClick={handleGiveHint}
                  />
                  <Link
                    className="leaveMatch"
                    onClick={() => setGiveHint(false)}
                  >
                    go back
                  </Link>
                </div>
              </animated.div>
            )
        )}
        {/* End give hint */}

        {/* Undo */}
        {transitionsUndo(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="popupBackground"
                onClick={() => setUndoStep(false)}
              ></animated.div>
            )
        )}

        {undoTransitions(
          (styles, item) =>
            item && (
              <animated.div style={styles} className="matchPopup">
                <div className="matchContent">
                  <h2 className="leaveMatchTitle">
                    Are you sure you want to undo step?
                  </h2>
                  <Button
                    title="UNDO STEP"
                    className="stayBtn"
                    onClick={handleUndoStepPopup}
                  />
                  <Link
                    className="leaveMatch"
                    onClick={() => setUndoStep(false)}
                  >
                    go back
                  </Link>
                </div>
              </animated.div>
            )
        )}
        {/* End give undo */}

        {/* Reset room */}
        {transitionsReset(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="popupBackground"
                onClick={() => setResetRoom(false)}
              ></animated.div>
            )
        )}

        {resetTransitions(
          (styles, item) =>
            item && (
              <animated.div style={styles} className="matchPopup">
                <div className="matchContent">
                  <h2 className="leaveMatchTitle">
                    Are you sure you want to RESET your escape room?
                  </h2>
                  <Button
                    title="RESET ROOM"
                    className="stayBtn"
                    onClick={handleResetRoom}
                  />
                  <Link
                    className="leaveMatch"
                    onClick={() => setResetRoom(false)}
                  >
                    go back
                  </Link>
                </div>
              </animated.div>
            )
        )}
        {/* End reset room */}

        <div className="header resetRoom">
          <button className="resetBtn" onClick={() => setResetRoom(!resetRoom)}>
            RESET YOUR ESCAPE ROOM
          </button>
        </div>

        <div className="content">
          <div className="timeSpent">
            <h6>Time Spent</h6>

            <span className="timer">
              <span className="timer">
                {elapsedTime.minutes === roomDuration ? (
                  `${roomDuration}:00`
                ) : loader ? (
                  <TailSpin
                    height="40"
                    width="40"
                    color="#808080"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  `${elapsedTime.minutes}:${elapsedTime.seconds}`
                )}
              </span>
            </span>
          </div>
          <div className="hints">
            <h6>Hints</h6>
            <div>
              {Array.isArray(gmData) ? (
                gmData.map((item, index) => {
                  if (item.roomId === listingDetails.id) {
                    return (
                      <div key={index}>
                        {" "}
                        {(() => {
                          if (item.hintCount === 1) {
                            return (
                              <div className="allHints">
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                              </div>
                            );
                          } else if (item.hintCount === 2) {
                            return (
                              <div className="allHints">
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                              </div>
                            );
                          } else if (item.hintCount === 3) {
                            return (
                              <div className="allHints">
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                              </div>
                            );
                          } else if (item.hintCount === 4) {
                            return (
                              <div className="allHints">
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                              </div>
                            );
                          } else if (item.hintCount === 5) {
                            return (
                              <div className="allHints">
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                              </div>
                            );
                          } else if (item.hintCount > 5) {
                            return (
                              <div className="allHints">
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                                <img src={hintActive} alt="hint-inactive" />
                                <span>+ {item.hintCount - 5}</span>
                              </div>
                            );
                          } else {
                            return (
                              <div className="allHints">
                                <img src={hint} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                                <img src={hint} alt="hint-inactive" />
                              </div>
                            );
                          }
                        })()}
                      </div>
                    );
                  }
                  return null;
                })
              ) : (
                <></>
              )}
            </div>

            <Button
              title="give a hint"
              onClick={() => setGiveHint(!giveHint)}
            />
          </div>

          <div className="steps">
            <h6>Escape Room Steps</h6>

            {loader ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <TailSpin
                  height="40"
                  width="40"
                  color="#808080"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="allSteps">
                {steps.map((item, i) => (
                  <div className="roomStep" key={item.id}>
                    <input
                      type="checkbox"
                      checked={item.checked}
                      onChange={(e) => handleCheckBox(e, i, item.id)}
                      id={`check${item.id}`}
                      disabled={item.checked ? true : false}
                    />
                    <span
                      style={{
                        color: item.checked ? "#767676" : "#fff",
                      }}
                      id={`step${item.id}`}
                    >
                      {item.name}
                    </span>
                    <img
                      className="undoIcon"
                      style={{
                        display: item.checked ? "block" : "none",
                      }}
                      id={item.id}
                      src={undoIcon}
                      alt="undoIcon"
                      onClick={() => handleUndo(item.id, item.checked)}
                    />
                  </div>
                ))}
              </div>
            )}
            <div></div>
          </div>

          <div className="teamCards">
            {loader ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <TailSpin
                  height="40"
                  width="40"
                  color="#808080"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="card">
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="globalDropdown"
                  >
                    <Typography component={"span"} className="teamDropdown">
                      <span>Team Name</span>
                      {/* {app.team.name} */}
                      {Array.isArray(gmData) ? (
                        gmData.map((item, index) => {
                          if (item.roomId === listingDetails.id) {
                            return <div key={index}>{item.name}</div>;
                          }
                          return null;
                        })
                      ) : (
                        <></>
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordionDetails">
                    <Typography
                      component={"span"}
                      className="teamImageAccordion"
                    >
                      <div className="teamImage">
                        <div className="image">
                          {Array.isArray(gmData) ? (
                            gmData.map((item, index) => {
                              if (item.roomId === listingDetails.id) {
                                return (
                                  <img
                                    src={`https://prod.arenacompetition.world${item.image}`}
                                    alt="team"
                                    key={index}
                                  />
                                );
                              }
                              return null;
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="teamInfo">
                        <div className="roomFranchize">
                          <span>Escape Room Company</span>
                          <h5>{listingDetails.company?.name}</h5>
                        </div>

                        <div className="location">
                          <span>Escape Room Location</span>
                          {!listingDetails.location ? (
                            <h5>-</h5>
                          ) : (
                            <h5>{listingDetails.location}</h5>
                          )}
                        </div>

                        <div className="roomName">
                          <span>Escape Room Name</span>
                          <h5>{listingDetails.name}</h5>
                        </div>

                        <div className="difficultyLevel">
                          <span>Difficulty Level</span>
                          <h5>{listingDetails.difficultyLevel}</h5>
                        </div>

                        <div className="solvingFrame">
                          <span>Time Frame For Solving</span>
                          <h5>{listingDetails.duration} minutes</h5>
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
          </div>

          <div className="resetRoom">
            <button
              className="completeRoom"
              onClick={() => setComplete(!complete)}
            >
              COMPLETE THE ROOM
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingPage;
