import { each } from "@react-spring/shared";
import moment from "moment";
import * as types from "../types/actionTypes";

const initialState = {
  team: null,
  room: null,
  game: null,
  teams: [],
  hints: null,
  hintsPage: null,
  members: [],
  teamarray: null,
  gethints: null,
  availableteams: [],
  test: [],
  progressid: null,
  progresscount: null,

  // new
  get: [],
  lobby: [],
  allFinished: null,
  gameid: null,
  time: null,
  team: [],
  joined: [],
  kickedTeam: null,
  kickedMember: null,
  rooms: [],
  keyCounter: 0,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    // NEW
    case types.GET_TEAMS:
      return {
        ...state,
        get: action.payload,
      };

    case types.GET_UPDATE_ROOMS:
      console.log(action.payload);
      return {
        ...state,
        rooms: action.payload,
        keyCounter: state.keyCounter + 1,
      };

    case types.MEMBER_IS_KICKED:
      return {
        ...state,
        kickedMember: action.payload,
      };

    case types.MEMBERS_JOINED:
      return {
        ...state,
        joined: state.joined.filter((item) => item.id !== action.payload),
        joined: [...state.joined, action.payload],
      };

    case types.MEMBER_LEFT:
      return {
        ...state,
        joined: state.joined.filter((card) => card.id !== action.payload),
        lastUpdated: Date.now(),
      };

    case types.KICKED_TEAM:
      // console.log(action.payload, "KICKED ID");

      return {
        ...state,
        joined: state.joined.filter((item) => item.id !== action.payload),
        lastUpdated: Date.now(),
      };

    case types.AVAILABLE_MATCHES_TEAMS:
      return {
        ...state,
        availableteams: action.payload,
      };

    case types.SAVE_MYMATCH_LOBBY:
      return {
        ...state,
        lobby: action.payload,
      };

    case types.STARTED_GAME:
      const existsInArray = state.get.some(
        (l) => l.id === action.payload.teamId
      );
      if (existsInArray) {
        const mem = state.get.map((item, index) => {
          if (item.id === action.payload.teamId) {
            return {
              ...item,
              gameStartedAt: action.payload.gameStartedAt,
            };
          }
          return item;
        });

        return {
          ...state,
          get: [...mem],
        };
      }

      return {
        ...state,
        // member: { ...state.member, gameStartedAt: moment() },
        get: [
          ...state.get,
          { ...state.member, gameStartedAt: action.payload.gameStartedAt },
        ],
      };

    // let index = state.get.findIndex((x) => x.id === action.payload);
    // if (index !== -1) {
    //   console.log("found team which started");
    //   return {
    //     ...state,
    //     get: [
    //       ...state.get.slice(0, index),
    //       Object.assign({}, state.get[index], {
    //         ...state.get[index],
    //         startDate: moment(),
    //       }),
    //       ...state.get.slice(index + 1),
    //     ],
    //   };
    // } else {
    //   console.log("didnt find team which started");
    //   return {
    //     ...state,
    //     get: [...state.get, { ...state.member, startDate: moment() }],
    //   };
    // }

    case types.START_TIME:
      return {
        ...state,
        time: action.payload,
      };

    case types.SHOW_GAME_ID:
      return {
        ...state,
        gameid: action.payload,
      };

    case types.FINISHED_GAME:
      return {
        ...state,
        allFinished: action.payload,
      };

    case types.GET_ROOM_RESET:
      return {
        ...state,
        get: state.get.filter((item) => action.payload !== item.roomId),
      };

    case types.TEAM_NEW_VALUE:
      return {
        ...state,
        get: action.payload,
      };

    case types.COMPLETED_STEPS:
      const steps = state.get.map((item, index) => {
        if (item.id === action.payload.teamId) {
          return {
            ...item,
            points: action.payload.points,
            progress: action.payload.progress,
          };
        }
        return item;
      });
      return {
        ...state,
        get: [...steps],
      };

    case types.TEAM_FINISHED:
      const teamFin = state.get.map((item, index) => {
        if (item.id === action.payload.teamId) {
          return {
            ...item,
            gameFinishedAt: action.payload.gameFinishedAt,
            progress: 100,
          };
        }
        return item;
      });
      return {
        ...state,
        get: [...teamFin],
      };

    case types.GIVEN_HINT:
      const givenhint = state.get.map((item, index) => {
        if (item.id === action.payload.teamId) {
          return {
            ...item,
            hintCount: item.hintCount + 1,
            points: action.payload.points,
          };
        }
        return item;
      });
      return {
        ...state,
        get: [...givenhint],
      };
      return {
        ...state,
        get: action.payload,
      };

    // END NEW

    // case types.GIVEN_HINT_PAGE:
    //   console.log(action.payload.team.team.id);
    //   console.log(state.get);
    //   const givenhintpage = state.get.map((item, index) => {
    //     if (item.id === action.payload.team.team.id) {
    //       return {
    //         ...item,
    //         hintCount: item.hintCount + 1,
    //       };
    //     }
    //     return item;
    //   });
    //   return {
    //     ...state,
    //     get: [...givenhintpage],
    //   };

    case types.GIVEN_HINT_PAGE:
      console.log(action.payload);
      return {
        ...state,
        hints: action.payload.team.hints + 1,
      };

    case types.SAVE_TEAM:
      return {
        ...state,
        team: action.payload,
      };

    case types.TEST:
      return {
        ...state,
        test: [...state.test, action.payload],
      };

    case types.PROGRESSID:
      return {
        ...state,
        progressid: action.payload,
      };

    case types.PROGRESSCOUNT:
      return {
        ...state,
        progresscount: action.payload,
      };

    case types.SAVE_ROOM:
      return {
        ...state,
        room: action.payload,
      };
    case types.SAVE_GAME:
      return {
        ...state,
        teams: action.payload,
      };
    case types.UPDATE_GAME: {
      return {
        ...state,
        game: {
          ...state.game,
          teams: [...state.game.teams, action.payload],
        },
      };
    }

    case types.GIVE_HINT:
      return {
        ...state,
        hints: action.payload,
      };

    case types.JOINED_MEMBER: {
      return {
        ...state,
        member: action.payload.team,
      };
    }

    case types.ARRAY_MEMBERS: {
      // return {
      //   ...state,
      //   members: [...state.members, state.member],
      // };

      const existsInArray = state.members.some((l) => l.id === state.member.id);
      if (existsInArray) {
        return state;
      }

      return {
        ...state,
        members: [...state.members, state.member],
      };
    }

    case types.TEAMS_ARRAY: {
      return {
        ...state,
        teamarray: {
          ...state.teamarray,
          get: [...state.get, action.payload],
        },
      };
    }

    case types.UPDATE_MEMBERS:
      const modifiedArrr1 = state.members.map((item, index) => {
        if (item.id === state.member.id) {
          return {
            ...item,
            progress: state.member.progress,
          };
        }
        return item;
      });
      return {
        ...state,
        members: [...modifiedArrr1],
      };

    case types.UPDATE_TEAMS:
      const arr = state.get;
      // const modifiedArr = arr.map((item, index) => {
      //   if (item.id === action.payload.id) {
      //     return {
      //       ...item,
      //       progress: action.payload.progress,
      //     };
      //   }
      //   return item;
      // });
      const modifiedArrr = state.get.map((item, index) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            progress: action.payload.progress,
          };
        }
        return item;
      });
      return {
        ...state,
        get: [...modifiedArrr],
      };

    case types.GET_HINTS:
    // return {
    //   ...state,
    //   gethints: action.payload,
    // };

    default:
      return state;
  }
};

export default appReducer;
