import React, { useState, useEffect, useMemo } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Echo from "laravel-echo";
import { useTransition, animated } from "react-spring";
import { API } from "../../services/base";
import axios from "../../services/https";

import { useDispatch } from "react-redux";
import {
  availablesTeams,
  saveMyMatchLobby,
  startTime,
  saveTeamsDate,
} from "../../redux/actions/app.js";
import { useSelector } from "react-redux";

import GoBack from "../GoBack";
import Pagination from "../../components/Pagination";

import "../../style/gameMaster/AvailableMatches.scss";

import LogoPosition from "../../components/LogoPosition";
import Button from "../../elements/Button";
import Icon_SeeMore from "../../images/icons/Icon_SeeMore.svg";
import RemoveText from "../../images/close-icon.svg";
import hexagon from "../../images/red.svg";
import backPage from "../../images/backPage.png";
import nextPage from "../../images/nextPage.png";
import clearSearch from "../../images/clearSearch.png";

import moment from "moment";
import "moment-timezone";

import { domen } from "../../services/base";

const AvailableMatches = () => {
  const [notification, setNotification] = useState("");
  const [cards, setCards] = useState(localStorage.getItem("token"));
  const [time, setTime] = useState(localStorage.getItem("time"));
  const app = useSelector((state) => state.app);

  const [noMath, showMatch] = useState([]);
  const [ot, setOt] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(3);

  // Search
  const [deleteSearch, setDeleteSearch] = useState(false);
  const [query, setQuery] = useState("");

  // Socket

  window.Pusher = require("pusher-js");

  window.Echo = new Echo({
    broadcaster: "pusher",
    key: "2UhByxffTQ7XjcYWgfHbumDj",
    cluster: "mt1",
    authEndpoint: `https://prod.arenacompetition.world/broadcasting/auth`,
    wsHost: "prod.arenacompetition.world",
    auth: {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + `${cards}`,
      },
    },
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    encrypted: true,
    enabledTransports: ["ws", "wss"],
    transports: ["websocket"],
  });

  // End socket

  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  var myzone = moment.tz.guess();
  // var localTime = moment.utc(`${time}`, "HH:mm").tz(myzone).format("HH:mm");
  var localTime = moment
    .utc(`${time}`, "HH:mm")
    .utcOffset(myzone)
    .format("HH:mm");

  window.Echo.channel("games").listen(".game.created", (e) => {
    if (
      moment
        .duration(
          moment
            .utc(`${time}`, "HH:mm")
            .utcOffset(myzone)
            .diff(e.estimatedStartTime)
        )
        .asMinutes() <= 5
    ) {
      reloadMatches();
    }
  });

  // console.log(moment.utc(`${time}`, "HH:mm").utcOffset(myzone).format("HH:mm"));

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const matchTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const handleNewMatch = () => {
    const data = {
      teamId: app.team.id,
      startTime: localTime,
    };
    axios
      .post(`${API}/game`, data)
      .then((res) => {
        dispatch(saveMyMatchLobby(res.data.data));
        navigate("/my-match-lobby");
        localStorage.setItem("checkSolo", false);
        console.log(res.data.data, "AVAILABLE MATCHES");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSolo = () => {
    const data = {
      teamId: app.team.id,
      startTime: localTime,
    };
    axios
      .post(`${API}/game`, data)
      .then((res) => {
        localStorage.setItem("soloGameId", res.data.data.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSoloMatch = () => {
    setOpen(!open);
  };

  const handleLink = (teams, id) => {
    dispatch(availablesTeams(teams));
    localStorage.setItem("gameId", id);
  };

  const handleProceed = () => {
    const data = {
      teamId: app.team.id,
      startTime: localTime,
    };
    axios
      .post(`${API}/game`, data)
      .then((res) => {
        dispatch(saveMyMatchLobby(res.data.data));
        localStorage.setItem("checkSolo", true);
        navigate("/my-match-lobby");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const reloadMatches = () => {
    axios
      .get(`${API}/game?startTime=${localTime}&page=${page}&search=${search}`)
      .then((res) => {
        showMatch(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    reloadMatches();
  }, [page, search]);

  // const [searchedTeams, setSearchedTeams] = useState([]);

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     axios
  //       .get(`${API}/game?startTime=${localTime}&search=${search}`)
  //       .then((res) => {
  //         setSearchedTeams(res.data.data);
  //         console.log(res.data.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }, 1000);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [search]);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = noMath.slice(firstPostIndex, lastPostIndex);

  const PageNumbers = [];

  // let NumOfTotalPost = Post.length;
  for (let i = 1; i <= Math.ceil(noMath.length / postsPerPage); i++)
    PageNumbers.push(i);

  const handleClearSearch = () => {
    setSearch("");
  };

  // const handleProceed = () => {
  //   const data = {
  //     teamId: app.team.id,
  //     startTime: localTime,
  //   };
  //   axios
  //     .post(`${API}/game`, data)
  //     .then((res) => {
  //       axios
  //         .post(`${API}/game/${res.data.data.id}/start`)
  //         .then((res) => {
  //           navigate("/solo-platform");
  //           localStorage.setItem("solo", res.data.data.id);
  //           localStorage.setItem("soloTrue", 0);

  //           const found = res.data.data.teams.find((obj) => {
  //             return obj.id === app.team.id;
  //           });

  //           dispatch(startTime(found.gameStartedAt));
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const s = noMath
    .filter((item) => {
      if (search === "") {
        return item;
      } else if (item.company.toLowerCase().includes(search.toLowerCase())) {
        return item;
      } else if (item.room.toLowerCase().includes(search.toLowerCase())) {
        return item;
      } else if (
        item.teams[0].name.toLowerCase().includes(search.toLowerCase())
      ) {
        return item;
      }
    })
    .map((item, index) => {
      return (
        <div className="matchCard" key={item.id}>
          <div className="header">
            <div className="cardNumber">
              <h6>{item.id}</h6>
            </div>
            <div className="numberOfTeams">
              {/* <h6>Match admin info</h6> */}
            </div>
          </div>
          <div className="content">
            <div className="roomFranchize">
              <span>Escape Room Company</span>
              <h5>{item.company}</h5>
            </div>

            <div className="location">
              <span>Escape Room Location</span>
              {item.location ? <h5>{item.location}</h5> : <h5>-</h5>}
            </div>

            <div className="roomName">
              <span>Escape Room Name</span>
              <h5>{item.room}</h5>
            </div>

            <div className="location">
              <span>Escape Room Address</span>
              {item.location ? <h5>{item.location}</h5> : <h5>-</h5>}
            </div>
          </div>
          <div className="seeMore">
            <Link
              to={`/join-match/${item.id}`}
              onClick={() => handleLink(item.teams, item.id)}
            >
              See more
            </Link>
          </div>
        </div>
      );
    });

  return (
    <div className="availableMatches">
      <div className="availableMatchesWrapp">
        <GoBack />
        {/* Solo match */}
        {transitions(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="popupBackground"
                onClick={() => setOpen(false)}
              ></animated.div>
            )
        )}

        {matchTransitions(
          (styles, item) =>
            item && (
              <animated.div style={styles} className="matchPopup">
                <div className="matchContent">
                  {/* <h2>Are You Sure You Want To Start Top 10 Challenge?</h2> */}
                  <h2>Start 10 top challenge?</h2>
                  {/* <p>
                    Once you switch to Top 10 Challenge, you will not be able to
                    compete with other Escape rooms.
                  </p> */}
                  <Button
                    title="Proceed"
                    className="stayBtn"
                    onClick={handleProceed}
                  />
                  <Link className="goBack" onClick={() => setOpen(false)}>
                    go back
                  </Link>
                </div>
              </animated.div>
            )
        )}
        {/* End solo match */}

        <div className="bar">
          <LogoPosition />
          <div className="search-container">
            <div id="search-wrap">
              <form role="search" method="get" action="">
                <input
                  type="text"
                  id="search-text"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  value={search}
                />
                {search.length > 0 ? (
                  <img
                    src={clearSearch}
                    alt={clearSearch}
                    onClick={handleClearSearch}
                  />
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        </div>

        {noMath.length > 0 ? (
          <div className="availableMatchesCards">
            {s.length != 0 ? (
              <div>
                <h2>Available Matches</h2>
                <div className="availableWrap">
                  <Button title="CREATE A NEW MATCH" onClick={handleNewMatch} />
                  <Button
                    title="START TOP 10 CHALLENGE"
                    onClick={handleSoloMatch}
                  />
                </div>
              </div>
            ) : (
              <h2>No Matches Found</h2>
            )}

            <div className="matchCardContent">
              <div>
                {search.length > 0
                  ? s
                  : currentPosts
                      .filter((item) => {
                        if (search === "") {
                          return item;
                        } else if (
                          item.company
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return item;
                        } else if (
                          item.room.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return item;
                        } else if (
                          item.teams[0].name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item, index) => {
                        return (
                          <div className="matchCard" key={item.id}>
                            {/* <div className="header">
                              <div className="cardNumber">
                                <h6>{item.id}</h6>
                              </div>
                              <div className="numberOfTeams">
                                <h6>Match admin info</h6>
                              </div>
                            </div> */}
                            <div className="content">
                              <div className="roomFranchize">
                                <span>Escape Room Company</span>
                                <h5>{item.company}</h5>
                              </div>

                              <div className="location">
                                <span>Escape Room Location</span>
                                {item.location ? (
                                  <h5>{item.location}</h5>
                                ) : (
                                  <h5>-</h5>
                                )}
                              </div>

                              <div className="roomName">
                                <span>Escape Room Name</span>
                                <h5>{item.room}</h5>
                              </div>

                              <div className="location">
                                <span>Escape Room Address</span>
                                {item.location ? (
                                  <h5>{item.location}</h5>
                                ) : (
                                  <h5>-</h5>
                                )}
                              </div>
                            </div>
                            <div className="seeMore">
                              <Link
                                to={`/join-match/${item.id}`}
                                onClick={() => handleLink(item.teams, item.id)}
                              >
                                See more
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                {/* Pagination */}

                {search.length === 0 ? (
                  <div className="paginationStyle">
                    <button
                      className="prevAndNext"
                      onClick={() => {
                        if (currentPage > 1) {
                          setCurrentPage(currentPage - 1);
                        }
                      }}
                    >
                      <img src={backPage} alt="backPage" />
                    </button>
                    <Pagination
                      totalPosts={noMath.length}
                      postsPerPage={postsPerPage}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                    />
                    <button
                      className="prevAndNext"
                      onClick={() => {
                        if (currentPage < PageNumbers.length) {
                          setCurrentPage(currentPage + 1);
                        }
                      }}
                    >
                      <img src={nextPage} alt="nextPage" />
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {/* End pagination */}
              </div>
            </div>
            {s.length != 0 ? (
              <Button title="CREATE A NEW MATCH" onClick={handleNewMatch} />
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="noAvailableMatches">
            {currentPosts.length === 0 ? (
              <h2>No Matches Found</h2>
            ) : (
              <h2>Available Matches</h2>
            )}

            {/* <p>Unfortunately, there are currently no Available Matches.</p> */}
            <div className="availableWrap">
              <Button title="CREATE A NEW MATCH" onClick={handleNewMatch} />
              <Button
                title="START TOP 10 CHALLENGE"
                onClick={handleSoloMatch}
              />
            </div>
            {/* <button className="soloMatch" onClick={handleSoloMatch}>
              START A SOLO MATCH
            </button> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default AvailableMatches;

//  <div className="matchCardContent">
//    {query === "" ? (
//      <div>
//        {searchedTeams.map((item, index) => {
//          return (
//            <div className="matchCard" key={item.id}>
//              <div className="header">
//                <div className="cardNumber">
//                  {/* <img src={hexagon} /> */}
//                  <h6>{item.id}</h6>
//                </div>
//                <div className="numberOfTeams">
//                  <h6>Match admin info</h6>
//                </div>
//              </div>
//              <div className="content">
//                <div className="roomFranchize">
//                  <span>Escape room franchize</span>
//                  <h5>{item.company}</h5>
//                </div>

//                <div className="location">
//                  <span>Location</span>
//                  {item.location ? <h5>{item.location}</h5> : <h5>-</h5>}
//                </div>

//                <div className="roomName">
//                  <span>Escape Room Name</span>
//                  <h5>{item.room}</h5>
//                </div>

//                <div className="location">
//                  <span>Escape Room Address</span>
//                  {item.location ? <h5>{item.location}</h5> : <h5>-</h5>}
//                </div>
//              </div>
//              <div className="seeMore">
//                <Link
//                  to={`/join-match/${item.id}`}
//                  onClick={() => handleLink(item.teams, item.id)}
//                >
//                  See more
//                  {/* <img src={Icon_SeeMore} alt="Icon_SeeMore" /> */}
//                </Link>
//              </div>
//            </div>
//          );
//        })}
//        {/* Pagination */}
//        <Pagination
//          totalPosts={noMath.length}
//          postsPerPage={postsPerPage}
//          setCurrentPage={setCurrentPage}
//          currentPage={currentPage}
//        />

//        {/* End pagination */}
//      </div>
//    ) : (
//      noMath
//        .filter((noMath) => noMath.company.toLowerCase().includes(query))
//        .map((item, index) => {
//          return (
//            <div className="matchCard" key={item.id}>
//              <div className="header">
//                <div className="cardNumber">
//                  {/* <img src={hexagon} /> */}
//                  <h6>{item.id}</h6>
//                </div>
//                <div className="numberOfTeams">
//                  <h6>Match admin infofdsa</h6>
//                </div>
//              </div>
//              <div className="content">
//                <div className="roomFranchize">
//                  <span>Escape room franchize</span>
//                  <h5>{item.company}</h5>
//                </div>

//                <div className="location">
//                  <span>Location</span>
//                  {item.location ? <h5>{item.location}</h5> : <h5>-</h5>}
//                </div>

//                <div className="roomName">
//                  <span>Escape Room Name</span>
//                  <h5>{item.room}</h5>
//                </div>
//              </div>
//              <div className="seeMore">
//                <Link
//                  to={`/join-match/${item.id}`}
//                  onClick={() => handleLink(item.teams, item.id)}
//                >
//                  See more
//                  {/* <img src={Icon_SeeMore} alt="Icon_SeeMore" /> */}
//                </Link>
//              </div>
//            </div>
//          );
//        })
//    )}
//  </div>;

{
  /* {noMath.map((item, index) => {
                return (
                  <div className="matchCard" key={item.id}>
                    <div className="header">
                      <div className="cardNumber">
                        <h6>{item.id}</h6>
                      </div>
                      <div className="numberOfTeams">
                        <h6>Match admin info</h6>
                      </div>
                    </div>
                    <div className="content">
                      <div className="roomFranchize">
                        <span>Escape room franchize</span>
                        <h5>{item.company}</h5>
                      </div>

                      <div className="location">
                        <span>Location</span>
                        {item.location ? <h5>{item.location}</h5> : <h5>-</h5>}
                      </div>

                      <div className="roomName">
                        <span>Escape Room Name</span>
                        <h5>{item.room}</h5>
                      </div>

                      <div className="location">
                        <span>Escape Room Address</span>
                        {item.location ? <h5>{item.location}</h5> : <h5>-</h5>}
                      </div>
                    </div>
                    <div className="seeMore">
                      <Link
                        to={`/join-match/${item.id}`}
                        onClick={() => handleLink(item.teams, item.id)}
                      >
                        See more
                      </Link>
                    </div>
                  </div>
                );
              })} */
}
