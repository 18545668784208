import React, { useEffect } from "react";
import "../../../style/landing/About.scss";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import about from "../../../images/about.svg";
// import blueLine from "../../../images/blueLine.svg";

const About = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  const isMobile = window.innerWidth < 768;
  let leftIn = {};
  let rightIn = {};

  if (!isMobile) {
    leftIn = {
      visible: { x: 0, transition: { duration: 0.5 } },
      hidden: { x: -100 },
    };
    rightIn = {
      visible: { x: 0, transition: { duration: 0.5 } },
      hidden: { x: 100 },
    };
  }

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <div id="about-the-experience" className="aboutSection">
      <div className="aboutExperience container">
        <motion.div
          ref={ref}
          variants={rightIn}
          initial="hidden"
          animate={control}
          className="aboutImage animation"
        >
          <img src={about} alt="about" />
        </motion.div>

        <motion.div
          ref={ref}
          variants={leftIn}
          initial="hidden"
          animate={control}
          className="aboutContent"
        >
          <h5>About the experience</h5>
          <div className="aboutLine"></div>
          <h4>
            The Escape Revolution takes the best of escape experiences and
            amplifies it. We’re rugged, real, bold, and authentic. Explore
            worlds different from your own, challenge yourself, and be a part of
            the thrilling adventures ahead.
          </h4>
        </motion.div>
      </div>
    </div>
  );
};

export default About;
