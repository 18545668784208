import { useEffect, useState } from "react";

export default function Timer({}) {
  let initState = [
    {
      name: "Team 1",
      seconds: 60,
      minutes: 5,
      timerStarted: false,
    },
    {
      name: "Team 2",
      seconds: 30,
      minutes: 3,
      timerStarted: false,
    },
  ];
  const [teams, setTeams] = useState(initState);

  const [timerChecker, setTimerChecker] = useState(null);

  useEffect(() => {
    if (!timerChecker) {
      let timer = setInterval(() => {
        if (teams) {
          let updatedTimers = teams.map((item, index) => {
            if (item.timerStarted) {
              if (item.seconds > 0) {
                item.seconds = item.seconds - 1;
              }

              if (item.seconds === 0) {
                if (item.minutes === 0) {
                  item.timerStarted = false;
                } else {
                  item.seconds = 59;
                  item.minutes = item.minutes - 1;
                }
              }
            }
            return item;
          });
          setTeams(updatedTimers);
        }
      }, 1000);
      setTimerChecker(timer);
    }
  });

  function startTimer(item) {
    item.timerStarted = true;
  }

  function stopTimer(item) {
    item.timerStarted = false;
  }

  return (
    <>
      <h1>TIMER</h1>
      {teams.map((item, index) => {
        return (
          <div
            style={{ border: "1px solid gray", padding: "10px" }}
            key={index}
          >
            <h2>{item.name}</h2>
            <div>
              Timer: {item.minutes}:{item.seconds}
            </div>
            <button onClick={() => startTimer(item)}>START TIMER</button>
            <button onClick={() => stopTimer(item)}>STOP TIMER</button>
          </div>
        );
      })}
    </>
  );
}
