import React from "react";
import Header from "./Header";
import Footer from "./Footer";

import Baner from "./sections/Baner";

import About from "./sections/About";
import HowItWorks from "./sections/HowItWorks";
import Faq from "./sections/Faq";
import Video from "./sections/Video";
import Contact from "./sections/Contact";

import "../../style/landing/About.scss";

// import axios from "axios";
// import { API } from "../../services/base";

const Lander = () => {
  //   const [name, setName] = useState("");

  //   useEffect(() => {
  //     axios
  //       .get(`${API}/app-data`)
  //       .then((res) => {
  //         setName(res.data.data.tagCategories[0]);
  //         console.log(res.data.data.tagCategories[0]);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }, []);

  return (
    <div>
      <Header />
      <Baner />
      <div className="ss">
        <div className="top-plane"></div>
        <About />
        <HowItWorks />
        <Faq />
        <Video />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default Lander;
