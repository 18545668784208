import React from "react";
import Header from "./Header";

import Baner from "./content/Baner";
import Revolutionizing from "./content/Revolutionizing";
import ArenaDo from "./content/ArenaDo";
import ArenaWork from "./content/ArenaWork";
import Faq from "./content/Faq";
import Contact from "./content/Contact";

import "../../style/newlanding/newLanding.scss";
import Joing from "./content/Joing";
import Footer from "./Footer";

const NewLander = () => {
  return (
    <div>
      <Header />
      <div>
        <Baner />
        <Revolutionizing />
        <ArenaDo />
        <ArenaWork />
        <Joing />
        {/* <Faq /> */}
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default NewLander;
