import React, { useState, useEffect, useMemo } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Echo from "laravel-echo";
import { useTransition, animated } from "react-spring";
import { API } from "../../services/base";
import axios from "../../services/https";

import { useDispatch } from "react-redux";
import {
  availablesTeams,
  saveMyMatchLobby,
  startTime,
  saveTeamsDate,
} from "../../redux/actions/app.js";
import { useSelector } from "react-redux";

import GoBack from "../GoBack";
import Pagination from "../../components/Pagination";

import "../../style/gameMaster/AvailableMatches.scss";

import LogoPosition from "../../components/LogoPosition";
import Button from "../../elements/Button";
import Icon_SeeMore from "../../images/icons/Icon_SeeMore.svg";
import RemoveText from "../../images/close-icon.svg";
import hexagon from "../../images/red.svg";

import moment from "moment";
import "moment-timezone";

const Testing = () => {
  const [notification, setNotification] = useState("");
  const [cards, setCards] = useState(localStorage.getItem("token"));
  const [time, setTime] = useState(localStorage.getItem("time"));
  const app = useSelector((state) => state.app);

  const [noMath, showMatch] = useState([]);
  const [ot, setOt] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Search
  const [deleteSearch, setDeleteSearch] = useState(false);
  const [query, setQuery] = useState("");

  //   useMemo(() => {
  //     window.scrollTo({ top: 0 });
  //   }, [currentPage]);

  var myzone = moment.tz.guess();
  // var localTime = moment.utc(`${time}`, "HH:mm").tz(myzone).format("HH:mm");
  var localTime = moment
    .utc(`${time}`, "HH:mm")
    .utcOffset(myzone)
    .format("HH:mm");

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const matchTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const handleNewMatch = () => {
    const data = {
      teamId: app.team.id,
      startTime: localTime,
    };
    axios
      .post(`${API}/game`, data)
      .then((res) => {
        dispatch(saveMyMatchLobby(res.data.data));
        navigate("/my-match-lobby");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSolo = () => {
    const data = {
      teamId: app.team.id,
      startTime: localTime,
    };
    axios
      .post(`${API}/game`, data)
      .then((res) => {
        localStorage.setItem("soloGameId", res.data.data.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSoloMatch = () => {
    setOpen(!open);
  };

  const handleLink = (teams, id) => {
    dispatch(availablesTeams(teams));
    localStorage.setItem("gameId", id);
  };

  const handleProceed = () => {
    const data = {
      teamId: app.team.id,
      startTime: localTime,
    };
    axios
      .post(`${API}/game`, data)
      .then((res) => {
        dispatch(saveMyMatchLobby(res.data.data));
        navigate("/my-match-lobby");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [search, setSearch] = useState("");

  useEffect(() => {
    axios
      .get(`${API}/game?startTime=${localTime}&search=${search}`)
      .then((res) => {
        showMatch(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(3);

  const IndexOfLastPost = currentPage * postsPerPage;
  const IndexOfFirstPost = IndexOfLastPost - postsPerPage;
  const CurrentPosts = noMath.slice(IndexOfFirstPost, IndexOfLastPost);

  // pagination stuff
  const PageNumbers = [];

  // let NumOfTotalPost = Post.length;
  for (let i = 1; i <= Math.ceil(noMath.length / postsPerPage); i++)
    PageNumbers.push(i);

  return (
    <div className="availableMatches">
      <GoBack />
      {/* Solo match */}
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpen(false)}
            ></animated.div>
          )
      )}

      {matchTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup">
              <div className="matchContent">
                {/* <h2>Are You Sure You Want To Start Top 10 Challenge?</h2> */}
                <h2>Start 10 top challenge?</h2>
                {/* <p>
                  Once you switch to Top 10 Challenge, you will not be able to
                  compete with other Escape rooms.
                </p> */}
                <Button
                  title="Proceed"
                  className="stayBtn"
                  onClick={handleProceed}
                />
                <Link className="goBack" onClick={() => setOpen(false)}>
                  go back
                </Link>
              </div>
            </animated.div>
          )
      )}
      {/* End solo match */}

      <div className="bar">
        <LogoPosition />
        <div className="search-container">
          <div id="search-wrap">
            <form role="search" method="get" action="">
              <input
                type="text"
                id="search-text"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </form>
          </div>
        </div>
      </div>

      {noMath.length > 0 ? (
        <div className="availableMatchesCards">
          <h2>Available Matches</h2>
          <Button title="CREATE A NEW MATCH" onClick={handleNewMatch} />

          <div className="matchCardContent">
            <div>
              {CurrentPosts.filter((item) => {
                if (search === "") {
                  return item;
                } else if (
                  item.company.toLowerCase().includes(search.toLowerCase())
                ) {
                  return item;
                } else if (
                  item.room.toLowerCase().includes(search.toLowerCase())
                ) {
                  return item;
                } else if (
                  item.teams[0].name
                    .toLowerCase()
                    .includes(search.toLowerCase())
                ) {
                  return item;
                }
              }).map((item, index) => {
                return (
                  <div className="matchCard" key={item.id}>
                    <div className="header">
                      <div className="cardNumber">
                        <h6>{item.id}</h6>
                      </div>
                      <div className="numberOfTeams">
                        <h6>Match admin info</h6>
                      </div>
                    </div>
                    <div className="content">
                      <div className="roomFranchize">
                        <span>Escape Room Company</span>
                        <h5>{item.company}</h5>
                      </div>

                      <div className="location">
                        <span>Escape Room Location</span>
                        {item.location ? <h5>{item.location}</h5> : <h5>-</h5>}
                      </div>

                      <div className="roomName">
                        <span>Escape Room Name</span>
                        <h5>{item.room}</h5>
                      </div>

                      <div className="location">
                        <span>Escape Room Address</span>
                        {item.location ? <h5>{item.location}</h5> : <h5>-</h5>}
                      </div>
                    </div>
                    <div className="seeMore">
                      <Link
                        to={`/join-match/${item.id}`}
                        onClick={() => handleLink(item.teams, item.id)}
                      >
                        See more
                      </Link>
                    </div>
                  </div>
                );
              })}

              {/* Pagination */}

              <div className="bg-red-100 flex justify-center space-x-10 mt-10">
                <button
                  className="p-3 bg-indigo-600 text-white focus:ring hover:bg-indigo-800 "
                  onClick={() => {
                    if (currentPage > 1) {
                      setCurrentPage(currentPage - 1);
                    }
                  }}
                >
                  prev
                </button>
                <button
                  className="p-3 bg-indigo-600 text-white focus:ring hover:bg-indigo-800 "
                  onClick={() => {
                    if (currentPage < PageNumbers.length) {
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                >
                  Next
                </button>
              </div>

              {/* End pagination */}
            </div>
          </div>
          {noMath != 0 ? (
            <Button title="CREATE A NEW MATCH" onClick={handleNewMatch} />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="noAvailableMatches">
          <h2>Available Matches</h2>
          <p>Unfortunately, there are currently no Available Matches.</p>
          <Button title="CREATE A NEW MATCH" onClick={handleNewMatch} />
          <button className="soloMatch" onClick={handleSoloMatch}>
            START TOP 10 CHALLENGE
          </button>
        </div>
      )}
    </div>
  );
};

export default Testing;
