import React, { useState, useEffect } from "react";
import "../../../style/landing/Contact.scss";

import axios from "axios";
import { API } from "../../../services/base";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import blueLine from "../../../images/leftLineBlue.svg";
import redLine from "../../../images/rightLineRed.svg";

import Button from "../../../elements/Button.js";

const Contact = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState();
  const [message, setMessage] = useState("");
  const control = useAnimation();
  const [ref, inView] = useInView();

  const submitForm = (e) => {
    e.preventDefault();

    const data = {
      name: username,
      email: email,
      content: message,
    };

    axios
      .post(`${API}/contact`, data)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const isMobile = window.innerWidth < 768;
  let leftIn = {};
  let rightIn = {};

  if (!isMobile) {
    leftIn = {
      visible: { x: 0 },
      hidden: { x: -100, isMobile },
    };

    rightIn = {
      visible: { x: 0 },
      hidden: { x: 100, isMobile },
    };
  }

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <div className="contactSection">
      <div className="contact container">
        <div className="leftSide">
          <h5>Contact us for more</h5>
          <div className="lines">
            <motion.img
              ref={ref}
              variants={leftIn}
              initial="hidden"
              animate={control}
              transition={{ type: "spring" }}
              src={blueLine}
              alt="blueLine"
            />
            <motion.img
              ref={ref}
              variants={rightIn}
              initial="hidden"
              animate={control}
              transition={{ type: "spring" }}
              src={redLine}
              alt="redLine"
            />
          </div>
          <h2>Start the digital, world-wide escape journey with us!</h2>
          <p>
            Want to compete with the players around the world? The Escape
            Revolution takes the best of escape experiences and amplifies it.
            We’re rugged, real, bold, and authentic.
          </p>
        </div>
        <div className="rightSide">
          <form onSubmit={submitForm}>
            <label htmlFor="nameSurname">Name and surname</label>
            <input
              type="text"
              id="nameSurname"
              name="nameSurname"
              placeholder="Type your name and surname"
              onChange={(e) => setUsername(e.target.value)}
            ></input>
            <label htmlFor="email">Email adress</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Type your email"
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <label htmlFor="message">Type out your message</label>
            <textarea
              placeholder="Type out your message...."
              rows="6"
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button title="Send inquiry" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
