import React, { useState } from "react";
import { API } from "../../services/base";
import axios from "../../services/https";

import "../../style/gameMaster/InGame.scss";

import { useDispatch } from "react-redux";
import { saveHint } from "../../redux/actions/app.js";
import { useSelector } from "react-redux";

const Hint = () => {
  const [game, setGame] = useState(1);

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  const handleHintAdd = (e) => {
    e.preventDefault();

    const data = {
      teamId: app.team.id,
      game: game,
    };

    axios
      .post(`${API}/game/${game}/hint`, data)
      .then((res) => {
        dispatch(saveHint(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteHint = (e) => {
    e.preventDefault();

    const data = {
      teamId: app.team.id,
      game: game,
    };
  };

  return (
    <div className="inGame">
      <h1>HINT COUNT: {app.hints.teams[0].hintCount}</h1>
      <button onClick={handleHintAdd}>Add hint</button>
    </div>
  );
};

export default Hint;
