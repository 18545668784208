import React from "react";

// Style
import "../../../style/home/Matches.scss";

// Images
import Baner from "../../../images/Game_Master_Baner.png";

const Matches = () => {
  const handleRoute = () => {
    const contactElement = document.getElementById("contact");
    if (contactElement) {
      contactElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="matchWrapper">
      <div className="line"></div>

      <div className="boxWraper">
        <div className="box">
          <h2>Unleash Epic Head-to-Head Matches with ARENA</h2>
        </div>
      </div>

      <div className="boxList">
        <ul>
          <li>
            <h5>visual impact</h5>
            <p>
              Each room elevates the fun with a cool, live leaderboard! Every
              solved puzzle sets off a cool animation that'll grab your players'
              attention! The example above features a match with four rooms, but
              the number of rooms that can participate in a competition is
              unlimited.
            </p>
          </li>

          <li>
            <h5>BUILT ON ACTUAL DATA AND A BIT OF MAGIC</h5>
            <p>
              For years, we’ve meticulously cataloged data from 36 unique escape
              room missions across 4 continents and 7 countries. We’ve analyzed
              over 53,600 games, recording every score, minute elapsed, group
              size, number of hints given, and puzzles solved. This extensive
              research, combined with the expertise of our brilliant
              mathematicians, has led to the creation of ARENA: the first-ever
              software that enables global escape room competitions with
              unrivaled fairness.
            </p>
          </li>

          <li>
            <h5>community and connection</h5>
            <p>
              ARENA goes beyond being a competition platform; it facilitates
              community building, connecting families and colleagues across
              continents for memorable, bonding experiences.
            </p>
          </li>
        </ul>

        <button
          className="pricingButton letsDiscuss"
          onClick={() => handleRoute()}
        >
          <span>Let’s Discuss Pricing</span>
        </button>
      </div>

      <img className="banerImage" src={Baner} alt={Baner} />
    </div>
  );
};

export default Matches;
