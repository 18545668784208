import React from "react";
import join from "../../../images/in-game-display.webp";
// import joinMobile from "../../../images/in-game-display_mob.svg";
import joinLine from "../../../images/line1.svg";
import "../../../style/newlanding/join.scss";

import { useMediaQuery } from "react-responsive";

export const breakpoints = {
  mobile: `max-width: 1200px`,
  desktop: `min-width: 1200px`,
};

const Joing = () => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });

  return (
    <div className="join">
      {isMobile ? (
        <img className="image" src={join} alt="joinMobile" />
      ) : (
        <img className="image" src={join} alt="join" />
      )}
      <div className="redLine"></div>
      <div className="container">
        <img className="joinLine" src={joinLine} alt="joinLine" />

        <div className="title" id="whyJoin">
          <h1>WHY JOIN ARENA?</h1>
        </div>
        <ul className="list">
          <li>
            <h1>ACCESS TO A REVOLUTIONARY TECHNOLOGY</h1>
            <p>
              Arena’s patented software and accessories will allow escape rooms
              to level the playing field while adding a competitive flare to the
              classic gameplay structure.
            </p>
          </li>
          <li>
            <h1>IMPROVED REVENUE STREAMS</h1>
            <p>
              Boost Your Revenue: Attract more customers by removing traditional
              gameplay limitations.
            </p>
          </li>
          <li>
            <h1>24/7 CUSTOMER SUPPORT</h1>
            <p>
              Benefit from our global AWS Amazon Cloud infrastructure and 24/7
              customer service.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Joing;
