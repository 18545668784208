import React from "react";
import "../../../style/landing/Video.scss";

const Video = () => {
  return (
    <div className="videoSection">
      <div className="video container">
        <div className="frame animation">
          <iframe
            src="https://www.youtube.com/embed/R8NhhHxksOo"
            title="Maya Berovic - Do kostiju (Official Video 2023)"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen
            className="clip"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Video;
