import React, { useState } from "react";
import axios from "axios";
import { API } from "../../../services/base";
import { TailSpin } from "react-loader-spinner";

// Style
import "../../../style/home/Matches.scss";
import "../../../style/home/Contact.scss";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [escapeRoomName, setEscapeRoomName] = useState("");
  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = () => {
    setLoader(true);

    const data = {
      name: name,
      email: email,
      phone: phone,
      escapeRoomName: escapeRoomName,
      content: message,
    };
    axios
      .post(`${API}/contact`, data)
      .then((res) => {
        setPopup(true);
        setLoader(false);
        setTimeout(() => {
          setPopup(false);
        }, 3000);
      })
      .catch((err) => {
        setLoader(false);
        setError(err.response.data.messages);
        console.log(err.response.data.messages);
      });
  };

  const handleName = (e) => {
    setName(e.target.value);
    setError("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
    setError("");
  };

  const escapeRoom = (e) => {
    setEscapeRoomName(e.target.value);
    setError("");
  };

  const handleContent = (e) => {
    setMessage(e.target.value);
    setError("");
  };

  return (
    <div className="matchWrapper" id="contact">
      {popup && (
        <div className="backdrop" onClick={() => setPopup(false)}>
          <div className="popupCard">
            <h3>
              message <br /> Successfully sent
            </h3>
            <p>We will respond to you within 48 hours</p>
          </div>
        </div>
      )}

      <div className="line"></div>

      <div className="boxWraper">
        <div className="box">
          <h2>BECOME the Future of Escape Rooms with ARENA</h2>
        </div>
      </div>

      <div className="boxList contactList">
        <ul>
          <li>
            <p>
              You want to wow your customers, keep them coming back, and stay
              innovative and exciting.
            </p>
          </li>

          <li>
            <p>
              With ARENA, your players will compete against teams at your
              location and against escape room enthusiasts worldwide - in real
              time!
            </p>
          </li>

          <li>
            <p>You will enhance the experience and attract more customers.</p>
          </li>
        </ul>
      </div>

      <div className="contactFormWrapper">
        <h3>
          Fill out the contact form below to discuss pricing, packages, and
          more.
        </h3>
        <div className="contactForm">
          <div className="inputsWrapper">
            <div className="inputWrap">
              <label>Your Name</label>
              <input type="text" placeholder="Enter" onChange={handleName} />
              {error ? <p className="errorMessage">{error.name}</p> : ""}
            </div>
            <div className="inputWrap">
              <label>Email Address</label>
              <input type="email" placeholder="Enter" onChange={handleEmail} />
              {error ? <p className="errorMessage">{error.email}</p> : ""}
            </div>
          </div>

          <div className="inputsWrapper">
            <div className="inputWrap">
              <label>Phone Number</label>
              <input type="number" placeholder="Enter" onChange={handlePhone} />
              {error ? <p className="errorMessage">{error.phone}</p> : ""}
            </div>
            <div className="inputWrap">
              <label>Name of Your Escape Room</label>
              <input type="text" placeholder="Enter" onChange={escapeRoom} />
              {error ? (
                <p className="errorMessage">{error.escapeRoomName}</p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="inputWrap">
            <label>Message</label>
            <textarea
              rows="10"
              placeholder="Enter"
              onChange={handleContent}
            ></textarea>
            {error ? <p className="errorMessage">{error.content}</p> : ""}
          </div>
          {loader ? (
            <button className="submitButton" onClick={handleSubmit}>
              <span>
                <TailSpin
                  height="20"
                  width="20"
                  color="#fff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass="loaderContact"
                  visible={true}
                />
              </span>
            </button>
          ) : (
            <button className="submitButton" onClick={handleSubmit}>
              <span>submit</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
