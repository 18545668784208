import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { API } from "../services/base";
import "../style/Reception.scss";
import logo from "../images/receptionLogo.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getUpdateRooms } from "../redux/actions/app";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const Reception = () => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  var path = window.location.pathname;
  var uuid = path.split("/").pop();

  const [reception, setReception] = useState("");
  const [rooms, setRooms] = useState([]);

  const logoColors = [
    "#FF0000",
    "#74CF00",
    "#00A2E0",
    "#FFF000",
    "#4F56FF",
    "#ED297B",
    "#EF5A28",
    "#0AE9B3",
    "#C3986B",
  ];

  const getData = function () {
    axios.get(`${API}/reception/${uuid}`).then((res) => {
      setReception(res.data.data);
      // setRooms(res.data.data.rooms);
    });
  };

  const getRoomsData = function () {
    axios.get(`${API}/reception/${uuid}`).then((res) => {
      dispatch(getUpdateRooms(res.data.data.rooms));
    });
  };
  useEffect(() => {
    getData();
    getRoomsData();

    // Fetch room data every 2 minutes
    const intervalId = setInterval(() => {
      getRoomsData();
    }, 1 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const roomsFixed = app.rooms?.map((room, roomIndex) => {
    const colorIndex = roomIndex % logoColors.length;
    const logoColor = logoColors[colorIndex];
    return (
      <div
        className="winner"
        style={{ border: `2px solid ${logoColor}` }}
        key={roomIndex}
      >
        <div
          className="winnerLogo"
          style={{ borderBottom: `2px solid ${logoColor}` }}
        >
          <h4 style={{ color: logoColor }}>{room.name}</h4>
        </div>

        <div className="winnerInfo">
          <div className="winnerInfoContent">
            <div className="table">
              {room.bestTimes.map((item, index) => {
                return (
                  <div className="tableInfo" key={index}>
                    <div className="place">{item.id}</div>
                    <div className="teamName">{item.name}</div>
                    <div className="score">
                      {item.points} points / {item.hintCount} hints /{" "}
                      {item.timeLeft.slice(0, 2)}m {item.timeLeft.slice(3, 5)}s
                      left
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div
          key={`dot-${app.keyCounter}-${roomIndex}`}
          className="dot"
          style={{
            backgroundColor: logoColor,
            boxShadow: `0px 0px 10.3846px 4.61538px ${logoColor}`,
          }}
        ></div>
        <div
          key={`dot1-${app.keyCounter}-${roomIndex}`}
          className="dot1"
          style={{
            backgroundColor: logoColor,
            boxShadow: `0px 0px 10.3846px 4.61538px ${logoColor}`,
          }}
        ></div>
        <div
          key={`dot2-${app.keyCounter}-${roomIndex}`}
          className="dot2"
          style={{
            backgroundColor: logoColor,
            boxShadow: `0px 0px 10.3846px 4.61538px ${logoColor}`,
          }}
        ></div>
        <div
          key={`dot3-${app.keyCounter}-${roomIndex}`}
          className="dot3"
          style={{
            backgroundColor: logoColor,
            boxShadow: `0px 0px 10.3846px 4.61538px ${logoColor}`,
          }}
        ></div>
      </div>
    );
  });

  const carousel = useMemo(
    () => (
      <OwlCarousel
        className="owl-theme"
        loop
        margin={8}
        dots={true}
        autoplay={true}
        autoplayTimeout={10000}
        key={`carousel-${app.keyCounter}`}
        slideBy={3}
      >
        {app.rooms?.map((room, roomIndex) => {
          const colorIndex = roomIndex % logoColors.length;
          const logoColor = logoColors[colorIndex];
          return (
            <div className="item" key={roomIndex}>
              <div
                className="winner"
                style={{ border: `2px solid ${logoColor}` }}
              >
                <div
                  className="winnerLogo"
                  style={{ borderBottom: `2px solid ${logoColor}` }}
                >
                  <h4 style={{ color: logoColor }}>{room.name}</h4>
                </div>

                <div className="winnerInfo">
                  <div className="winnerInfoContent">
                    <div className="table">
                      {room.bestTimes.map((item, index) => {
                        return (
                          <div className="tableInfo" key={index}>
                            <div className="place">{item.id}</div>
                            <div className="teamName">{item.name}</div>
                            <div className="score">
                              {item.points} POINTS / {item.hintCount} HINTS /{" "}
                              {item.timeLeft.slice(0, 2)}m{" "}
                              {item.timeLeft.slice(3, 5)}s LEFT
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div
                  key={`dot-${app.keyCounter}-${roomIndex}`}
                  className="dot"
                  style={{
                    backgroundColor: logoColor,
                    boxShadow: `0px 0px 10.3846px 4.61538px ${logoColor}`,
                  }}
                ></div>
                <div
                  key={`dot1-${app.keyCounter}-${roomIndex}`}
                  className="dot1"
                  style={{
                    backgroundColor: logoColor,
                    boxShadow: `0px 0px 10.3846px 4.61538px ${logoColor}`,
                  }}
                ></div>
                <div
                  key={`dot2-${app.keyCounter}-${roomIndex}`}
                  className="dot2"
                  style={{
                    backgroundColor: logoColor,
                    boxShadow: `0px 0px 10.3846px 4.61538px ${logoColor}`,
                  }}
                ></div>
                <div
                  key={`dot3-${app.keyCounter}-${roomIndex}`}
                  className="dot3"
                  style={{
                    backgroundColor: logoColor,
                    boxShadow: `0px 0px 10.3846px 4.61538px ${logoColor}`,
                  }}
                ></div>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    ),
    [app.rooms, app.keyCounter]
  );

  return (
    <div className="receptionWrap">
      <div className="receptionContent">
        <div className="topContent">
          <div className="topCopmanu">
            <h4>Company</h4>
            {/* <h2>{reception.company}</h2> */}
            <div
              className="topCompanyTitle"
              dangerouslySetInnerHTML={{ __html: reception.company }}
            />
          </div>
          <div className="receptionLogo">
            <img src={logo} alt={logo} />
          </div>
          <div className="topCopmanu">
            <h4>City</h4>
            <div
              className="topCompanyTitle"
              dangerouslySetInnerHTML={{ __html: reception.address }}
            />
            {/* <h2>{reception.address}</h2> */}
          </div>
        </div>
        <div className="eliteTop">
          <h1>Elite top 5</h1>
        </div>

        <div className="receptionCarousel">
          {reception &&
            (app.rooms?.length > 3 ? (
              carousel
            ) : (
              <div className="fixedRoomsView">{roomsFixed}</div>
            ))}

          {/* {rooms.length > 3 && (
            <div className="custom-dots">
              {currentPage}/{totalPages}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Reception;
