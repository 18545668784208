import React, { useState, useEffect } from "react";
import Button from "../../elements/Button";
import team from "../../images/team.jpg";
import "../../style/gameMaster/MatchLobby.scss";
import { API } from "../../services/base";
import axios from "../../services/https";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useTransition, animated } from "react-spring";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startTime, memberIsKicked } from "../../redux/actions/app.js";
import { useSelector } from "react-redux";
import GoBack from "../GoBack";

import Echo from "laravel-echo";
import moment from "moment";

import { domen } from "../../services/base";

const MatchLobby = () => {
  const [expanded, setExpanded] = useState("panel0");
  const [game, setGame] = useState(1);

  const [time, setTime] = useState(localStorage.getItem("time"));
  let [checkTime, setCheckTime] = useState("");

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  const [matchId, setMatchiTD] = useState(localStorage.getItem("gameId"));
  const navigate = useNavigate();

  // Subscribe
  const [roomChannel, setRoomChannel] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    axios
      .get(`${API}/room`)
      .then((res) => {
        setRoomChannel(res.data.data.roomScreenChannel);
      })
      .catch((err) => {
        console.log(err);
        switch (err.response.status) {
          case 403:
            navigate("/");
          default:
            break;
        }
      }, []);
  }, []);

  useEffect(() => {
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "2UhByxffTQ7XjcYWgfHbumDj",
      cluster: "mt1",
      authEndpoint: `https://prod.arenacompetition.world/broadcasting/auth`,
      wsHost: "prod.arenacompetition.world",
      auth: {
        headers: {
          accept: "application/json",
          Authorization: "Bearer " + `${token}`,
        },
      },
      wsPort: 6001,
      wssPort: 6001,
      disableStats: true,
      encrypted: true,
      enabledTransports: ["ws", "wss"],
      transports: ["websocket"],
    });

    axios
      .get(`${API}/game/${matchId}`)
      .then((res) => {
        window.Echo.channel(res.data.data.socketChannel).listen(
          ".game.team-kicked",
          (e) => {
            dispatch(memberIsKicked(e.teamId));
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (app.team.id === app.kickedMember) {
      setKicked(!kicked);
      const timer = setTimeout(() => {
        // navigate("/available-matches");
        navigate(`/check/${token}`);
        localStorage.removeItem("persist:root");
        localStorage.removeItem("time");
        localStorage.removeItem("gameId");
        window.location.reload(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [app.kickedMember]);

  // End subscribe

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = useState(false);
  const [startNew, setStartNew] = useState(false);
  const [kicked, setKicked] = useState(false);

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const matchTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const transitionsNew = useTransition(startNew, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const newTransitions = useTransition(startNew, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const transitionsKicked = useTransition(kicked, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const kickedTransitions = useTransition(kicked, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const handleCreateNew = () => {
    navigate(`/check/${token}`);
    localStorage.removeItem("persist:root");
    localStorage.removeItem("time");
    localStorage.removeItem("gameId");
    window.location.reload(false);
  };

  const handelSoloMatch = () => {
    const newTime = moment().format("HH:ss");

    const data = {
      teamId: app.team.id,
      startTime: newTime.toString(),
    };
    axios
      .post(`${API}/game`, data)
      .then((res) => {
        axios
          .post(`${API}/game/${res.data.data.id}/start`)
          .then((res) => {
            navigate("/solo-platform");
            localStorage.setItem("solo", res.data.data.id);

            const found = res.data.data.teams.find((obj) => {
              return obj.id === app.team.id;
            });

            dispatch(startTime(found.gameStartedAt));
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStartMatch = () => {
    const data = {
      game: matchId,
    };
    axios
      .post(`${API}/game/${matchId}/start`, data)
      .then((res) => {
        navigate("/listing-page");
        const found = res.data.data.teams.find((obj) => {
          return obj.id === app.team.id;
        });

        localStorage.setItem("room", matchId);
        localStorage.setItem("checkSolo", false);
        dispatch(startTime(found.gameStartedAt));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLeaveMatch = () => {
    const data = {
      teamId: app.team.id,
      game: matchId,
    };
    axios
      .post(`${API}/game/${matchId}/leave`, data)
      .then((res) => {
        navigate("/available-matches");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Time
  var myzone = moment.tz.guess();
  var myTime = moment().tz(myzone);
  var buttonOpeningTime = moment(localStorage.getItem("startTime")).tz(myzone);
  var buttonClosingTime = moment(buttonOpeningTime).add(5, "minutes");
  var isButtonEnabled =
    myTime.isSameOrAfter(buttonOpeningTime) &&
    myTime.isBefore(buttonClosingTime);

  if (myTime.isSameOrAfter(buttonClosingTime)) {
    const data = {
      game: matchId,
      teamId: app.team.id,
    };
    axios
      .post(`${API}/game/${matchId}/leave`, data)
      .then((res) => {
        setStartNew(!startNew);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  var timerInSecond = null;
  if (myTime.isBefore(buttonOpeningTime)) {
    timerInSecond = buttonOpeningTime.diff(myTime, "seconds");
  } else {
    timerInSecond = buttonClosingTime.diff(myTime, "second");
  }

  var timerJoinLastChance =
    String(Math.floor(timerInSecond / 60)).padStart(2, "0") +
    ":" +
    String(Math.floor(timerInSecond % 60)).padStart(2, "0");

  // console.log(
  //   myTime.format("HH:mm:ss"),
  //   buttonOpeningTime.format("HH:mm:ss"),
  //   buttonClosingTime.format("HH:mm:ss"),
  //   isButtonEnabled,
  //   String(Math.floor(timerInSecond / 60)).padStart(2, "0") +
  //     ":" +
  //     String(Math.floor(timerInSecond % 60)).padStart(2, "0")
  // );

  // var localTime = moment
  //   .utc(`${time}`, "HH:mm:ss")
  //   .tz(myzone)
  //   .format("HH:mm:ss");

  // const gameTime = moment(localStorage.getItem("startTime")).tz(myzone);

  // var extraTime = moment(gameTime, "HH:mm:ss")
  //   .add(300, "seconds")
  //   .format("HH:mm:ss");

  // const currentTime = moment().format("HH:mm:ss");

  // const diffStartCurr = moment().diff(gameTime, "seconds");

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     var myzone = moment.tz.guess();
  //     var start = moment(`${time}`, "HH:mm:ss");
  //     checkTime = moment();
  //     let diff = gameTime.diff(checkTime);

  //     checkTime = moment.utc(diff).format("mm:ss");
  //     setCheckTime(moment.utc(diff).format("mm:ss"));
  //   }, 600);

  //   if (currentTime === extraTime) {
  //     const data = {
  //       game: matchId,
  //       teamId: app.team.id,
  //     };
  //     axios
  //       .post(`${API}/game/${matchId}/leave`, data)
  //       .then((res) => {
  //         setStartNew(!startNew);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }

  //   return () => clearInterval(interval);
  // }, [checkTime]);
  // End time

  // When someone join match
  const [otherTeamsShow, setOtherTeamsShow] = useState([]);

  useEffect(() => {
    if (timerJoinLastChance != 0) {
      const interval = setInterval(() => {
        axios
          .get(`${API}/game/${matchId}`)
          .then((res) => {
            setOtherTeamsShow(res.data.data.teams);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, []);

  // End

  return (
    <div className="matchLobbyScreen">
      <div className="matchLobbyScreenWrapp">
        <GoBack />
        {/* Leave match */}
        {transitions(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="popupBackground"
                onClick={() => setOpen(false)}
              ></animated.div>
            )
        )}

        {matchTransitions(
          (styles, item) =>
            item && (
              <animated.div style={styles} className="matchPopup">
                <div className="matchContent">
                  <h2 className="leaveMatchTitle">
                    Are you sure you want to leave this match?
                  </h2>
                  <Button
                    title="Stay in match"
                    className="stayBtn"
                    onClick={() => setOpen(false)}
                  />
                  <Link className="leaveMatch" onClick={handleLeaveMatch}>
                    Leave match
                  </Link>
                </div>
              </animated.div>
            )
        )}
        {/* End leave match */}

        {/* Start new game */}
        {transitionsNew(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="popupBackground"
              ></animated.div>
            )
        )}

        {newTransitions(
          (styles, item) =>
            item && (
              <animated.div style={styles} className="matchPopup">
                <div className="matchContent">
                  <h2 className="leaveMatchTitle">Time Is Up!</h2>
                  <Button title="START SOLO GAME" onClick={handelSoloMatch} />
                  <br />
                  <Button title="CREATE A NEW TEAM" onClick={handleCreateNew} />
                  {/* <button className="btnLeaveMatch" onClick={handleCreateNew}>
                    CREATE A NEW TEAM
                  </button> */}
                </div>
              </animated.div>
            )
        )}
        {/* End start new game */}

        {/* Kicked */}
        {transitionsKicked(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="popupBackground"
              ></animated.div>
            )
        )}

        {kickedTransitions(
          (styles, item) =>
            item && (
              <animated.div style={styles} className="matchPopup">
                <div className="matchContent">
                  <h2 className="leaveMatchTitle">You have been kicked!</h2>
                </div>
              </animated.div>
            )
        )}
        {/* End kicked */}

        <div className="matchStart">
          <div className="startTime">
            {(() => {
              // if (buttonOpeningTime < 0) {
              //   return (
              //     <div>
              //       Match starts in: <br />
              //       <span>{checkTime}</span>
              //     </div>
              //   );
              // } else if (currentTime <= extraTime) {
              //   return (
              //     <div>
              //       Last chance to join: <br />
              //       <span>{timerJoinLastChance}</span>
              //     </div>
              //   );
              // } else {
              //   return (
              //     <div>
              //       Not available <br />
              //       <span>00:00</span>
              //     </div>
              //   );
              // }

              if (myTime.isBefore(buttonClosingTime)) {
                if (isButtonEnabled) {
                  return (
                    <div>
                      Last chance to join: <br />
                      <span>{timerJoinLastChance}</span>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      Match starts in: <br />
                      <span>{timerJoinLastChance}</span>
                    </div>
                  );
                }
              } else {
                return (
                  <div>
                    Not available <br />
                    <span>00:00</span>
                  </div>
                );
              }
            })()}
          </div>
          {(() => {
            return (
              <Button
                title="START!"
                disabled={!isButtonEnabled}
                onClick={handleStartMatch}
                className={isButtonEnabled ? "" : "myClass"}
              />
            );
            // if (diffStartCurr < 0) {
            //   return (
            //     <Button
            //       title="START!"
            //       disabled={true}
            //       onClick={handleStartMatch}
            //       className="myClass"
            //     />
            //   );
            // } else if (currentTime <= extraTime) {
            //   return <Button title="START!" onClick={handleStartMatch} />;
            // } else {
            //   return (
            //     <Button
            //       title="START!"
            //       disabled={true}
            //       onClick={handleStartMatch}
            //       className="myClass"
            //     />
            //   );
            // }
          })()}
        </div>

        <div className="lobby">
          <h2>Match Lobby</h2>

          <div className="myTeam">
            <h2>My team</h2>
            <Accordion
              expanded={expanded === "panel0"}
              onChange={handleChange("panel0")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="globalDropdown"
              >
                <Typography component={"span"} className="teamDropdown">
                  <span>Team Name</span> {app.team.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordionDetails">
                <Typography component={"span"} className="teamImageAccordion">
                  <div className="teamImage">
                    <div className="image">
                      <img
                        src={`https://prod.arenacompetition.world${app.team.image}`}
                        alt="team"
                      />
                    </div>
                  </div>
                  <div className="teamInfo">
                    <div className="roomFranchize">
                      <span>Escape Room Company</span>
                      <h5>{app.team.companyName}</h5>
                    </div>

                    <div className="location">
                      <span>Escape Room Location</span>
                      {app.team.roomLocation ? (
                        <h5>{app.team.roomLocation}</h5>
                      ) : (
                        <h5>-</h5>
                      )}
                    </div>

                    <div className="roomName">
                      <span>Escape Room Name</span>
                      <h5>{app.team.roomName}</h5>
                    </div>

                    <div className="difficultyLevel">
                      <span>Difficulty Level</span>
                      <h5>{app.team.roomDifficultyLevel}</h5>
                    </div>

                    <div className="solvingFrame">
                      <span>Time Frame For Solving</span>
                      <h5>{app.team.roomDuration} minutes</h5>
                    </div>
                  </div>

                  <div className="leaveMatch">
                    <button onClick={() => setOpen(!open)}>Leave match</button>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="otherTeams">
            <h2>Other teams</h2>
            <div className="teamCards">
              <div className="card">
                {otherTeamsShow
                  .filter((e) => e.id !== app.team.id)
                  .map((item, index) => {
                    return (
                      <Accordion
                        key={item.id}
                        expanded={expanded === item.id}
                        onChange={handleChange(item.id)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="globalDropdown"
                        >
                          <Typography
                            component={"span"}
                            className="teamDropdown"
                          >
                            <span>Team Name</span> {item.name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="accordionDetails">
                          <Typography
                            component={"span"}
                            className="teamImageAccordion"
                          >
                            <div className="teamImage">
                              <div className="image">
                                <img
                                  src={`https://prod.arenacompetition.world${item.image}`}
                                  alt="team"
                                />
                              </div>
                            </div>
                            <div className="teamInfo">
                              <div className="roomFranchize">
                                <span>Escape Room Company</span>
                                <h5>{item.companyName}</h5>
                              </div>

                              <div className="location">
                                <span>Escape Room Location</span>
                                {!item.roomLocation ? (
                                  <h5>-</h5>
                                ) : (
                                  <h5>{item.roomLocation}</h5>
                                )}
                              </div>

                              <div className="roomName">
                                <span>Escape Room Name</span>
                                <h5>{item.roomName}</h5>
                              </div>

                              <div className="difficultyLevel">
                                <span>Difficulty Level</span>
                                <h5>{item.roomDifficultyLevel}</h5>
                              </div>

                              <div className="solvingFrame">
                                <span>Time Frame For Solving</span>
                                <h5>{item.roomDuration} minutes</h5>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchLobby;
