import axios from "axios";
import React, { useState, useEffect } from "react";
import { getRoomDetails } from "../../api";
import { API } from "../../services/base";
import "../../style/gameMaster/TokenErrorPage.scss";

const TokenErrorPage = () => {
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("token");

        const response = await axios.get(`${API}/room`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (error) {
        setError(error.response.data.errorMessage);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="tokenError">
      <h2>{error}</h2>
      {/* <p>Your token is not valid. Contact our support for more information.</p> */}
    </div>
  );
};

export default TokenErrorPage;
