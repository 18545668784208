import React from "react";
import line from "../../../images/line.svg";
import "../../../style/newlanding/revolutionizing.scss";

const Revolutionizing = () => {
  return (
    <div className="revolutionizing">
      <img className="line" src={line} />
      <div className="redLine"></div>
      <div className="containerGrid">
        <div className="title" id="about">
          <h1>
            REVOLUTIONIZING ESCAPE ROOMS, <br /> ONE GAME AT A TIME.
          </h1>
        </div>
        <ul className="list">
          <li>
            <p>
              The global escape room market is booming with more than{" "}
              <strong>50,000 rooms</strong> as the thirst for immersive group
              experiences reaches new heights. Despite their growing popularity,
              escape rooms face challenges such as limited capacity and player
              competition. This is where ARENA steps in, revolutionizing the
              game and taking escape rooms to the next level.
            </p>
          </li>
          <li>
            <p>
              <strong>
                Group size limits, gameplay variables, and geographical distance
                are now just issues of the past.
              </strong>
            </p>
          </li>
          <li>
            <p>
              ARENA introduces groundbreaking proprietary software and
              accessories that allow teams from anywhere in the World to connect
              and compete in real-time. Our visionary team, including{" "}
              <strong>Hall of Fame</strong> mathematicians with{" "}
              <strong>16 Mathematics Olympiad Gold Medals</strong>, has
              developed proprietary software that levels the playing field for
              all teams, regardless of a group size, difficulty level, number of
              puzzles, room steps and an amount of given hints.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Revolutionizing;
