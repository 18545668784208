import React from "react";

// Style
import "../../../style/home/Matches.scss";

// Images
import Baner from "../../../images/Tv_Reception_Baner.png";

const NextLevel = () => {
  const handleRoute = () => {
    const contactElement = document.getElementById("contact");
    if (contactElement) {
      contactElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="matchWrapper">
      <div className="line"></div>

      <div className="boxWraper">
        <div className="box">
          <h2>TAKE IT TO THE NEXT LEVEL</h2>
        </div>
      </div>

      <div className="boxList">
        <ul>
          <li>
            <h5>DUAL GAMEPLAY DYNAMICS</h5>
            <p>
              Customers can compete in live head-to-head battles with other
              rooms. Or, they can take on the Top 10 Challenge and test their
              skills against the best scores of a given room.
            </p>
          </li>

          <li>
            <h5>it’s in our nature </h5>
            <p>
              "Can we make it into the Top 10?" they'll wonder, fueling their
              competitive spirit. Let them compete and find out.
            </p>
          </li>

          <li>
            <h5>unforgettable adventure</h5>
            <p>
              You want your customers to leave your escape room saying: ‘’That
              was awesome, I want to do it again!’’ By joining ARENA, you'll add
              an extra layer of excitement to the already thrilling hour they
              spend at your location.
            </p>
          </li>
        </ul>

        <button className="pricingButton" onClick={() => handleRoute()}>
          <span>talk to us</span>
        </button>
      </div>

      <img className="banerImage" src={Baner} alt={Baner} />
    </div>
  );
};

export default NextLevel;
