import React, { useEffect } from "react";
import { browserName } from "react-device-detect";

const GoBack = () => {
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    if (browserName === "Mobile Safari") {
      const element = document.querySelector("div");

      element.addEventListener("touchstart", (e) => {
        // is not near edge of view, exit
        if (e.pageX > 20 && e.pageX < window.innerWidth - 20) return;

        // prevent swipe to navigate back gesture
        e.preventDefault();
      });
    }
  }, [browserName]);
  return <div></div>;
};

export default GoBack;
