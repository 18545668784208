import React from "react";
import "../../style/landing/Footer.scss";

import logo from "../../images/logo.svg";
import facebook from "../../images/icons/Icon_Facebook.svg";
import instagram from "../../images/icons/Icon_Instagram.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footerContent container">
        <div className="aboutFooter">
          <img src={logo} alt="logo" />
          <p>
            Want to compete with the players around the world? The Escape
            Revolution takes the best of escape experiences and amplifies it.
            We’re rugged, real, bold, and authentic.
          </p>
        </div>
        <div className="menu">
          <ul>
            <li>
              <a href="/#about-the-experience">About the experience</a>
            </li>
            <li>
              <a href="/#how-it-works">How it works</a>
            </li>
            <li>
              <a href="/">Contact us</a>
            </li>
          </ul>
        </div>
        <div className="social">
          <h5>Find us on our social media</h5>
          <div className="icons">
            <a href="/" className="facebook">
              <img src={facebook} alt="facebook" />
            </a>
            <a href="/" className="instagram">
              <img src={instagram} alt="instagram" />
            </a>
          </div>
        </div>
      </div>
      <div className="line"></div>
      <div className="copyright container">
        <div className="poweredRights">
          <p>
            Powered by{" "}
            <a href="www.qwertify.io" target="_blank">
              Qwertify
            </a>
          </p>
          <p>All rights reserved©</p>
        </div>
        <div className="termsPolicy">
          <a href="/">Terms and Conditions</a>
          <a href="/">Privacy Policy</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
