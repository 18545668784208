import React from "react";
import { useMediaQuery } from "react-responsive";

// Style
import "../../../style/home/Footer.scss";

// Images
import logo from "../../../images/footerLogo.svg";

export const breakpoints = {
  mobile: `max-width: 1200px`,
  desktop: `min-width: 1200px`,
};

const Footer = () => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });

  return (
    <div className="footerWrapper">
      {isMobile && <img src={logo} alt={logo} />}
    </div>
  );
};

export default Footer;
