import React, { useEffect, useState } from "react";
import "../../style/gameMaster/Team.scss";

import { useNavigate } from "react-router-dom";
import { API } from "../../services/base";
import axios from "../../services/https";

import { useDispatch } from "react-redux";
import { saveGame } from "../../redux/actions/app.js";

import { useSelector } from "react-redux";

const GameTest = () => {
  const [game, setGame] = useState(7);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const app = useSelector((state) => state.app);

  const handleGame = (e) => {
    e.preventDefault();

    const data = {
      teamId: app.team.id,
      game: game,
    };

    axios
      .post(`${API}/game/${game}/join`, data)
      .then((res) => {
        // setOpen(false);
        navigate("/in-game");
        dispatch(saveGame(res.data.data.teams));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTeams = () => {
    const data = {
      game: game,
    };

    axios
      .post(`${API}/game/${game}/join`, data)
      .then((res) => {
        // setOpen(false);
        navigate("/in-game");
        dispatch(saveGame(res.data.data.teams));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="team">
      <form onSubmit={handleGame}>
        <button>Click</button>

        <button onSubmit={handleTeams}>Teams redux</button>
      </form>
    </div>
  );
};

export default GameTest;
