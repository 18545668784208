import React from "react";
import baner from "../../../images/hero1.png";
import banerMob from "../../../images/hero1Mob.webp";
import { useMediaQuery } from "react-responsive";
import "../../../style/newlanding/baner.scss";

export const breakpoints = {
  mobile: `max-width: 1200px`,
  desktop: `min-width: 1200px`,
};

const Baner = () => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });

  return (
    <div className="baner">
      {isMobile ? (
        <img src={banerMob} alt="banerMob" />
      ) : (
        <img src={baner} alt="baner" />
      )}
    </div>
  );
};

export default Baner;
