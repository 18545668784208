import React from "react";
import "../../../style/newlanding/arenaWork.scss";

import arenaWork from "../../../images/img_gm-screen.webp";
import arenaWorkMobile from "../../../images/img_gm-screen_mob.svg";
import line from "../../../images/workLine.svg";
import { useMediaQuery } from "react-responsive";

export const breakpoints = {
  mobile: `max-width: 1200px`,
  desktop: `min-width: 1200px`,
};

const ArenaWork = () => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });

  return (
    <div className="arenaWork">
      {isMobile ? (
        <img className="image" src={arenaWork} alt="arenaWorkMobile" />
      ) : (
        <img className="image" src={arenaWork} alt="arenaWork" />
      )}
      <div className="redLine"></div>
      <div className="work">
        <img className="line" src={line} alt="line" />

        <div className="title" id="howItWorks">
          <h1>How does arena work?</h1>
        </div>
        <ul className="list">
          <p>
            Once we meticulously craft a unique profile for each of your rooms
            and integrate it into our innovative algorithm, efficiency hits a
            new high. It'll take <strong>just 20 seconds</strong> of your Game
            Masters' time to enroll a team into a live match. But that's not all
            – our state-of-the-art facial recognition technology steps in to
            automatically count the players, breathing life into the algorithm.
            This seamless process not only saves time but also elevates the
            excitement, ensuring a smooth and dynamic gaming experience like
            never before. Welcome to the future of escape rooms, where
            technology and entertainment converge for an unforgettable
            adventure.
          </p>
          <li>
            <h1>AN EASY-TO-USE MOBILE PLATFORM</h1>
            <p>
              Our mobile platform adapts to your gameplay. Your Game Masters
              will love it!
            </p>
          </li>
          <li>
            <h1>AN EYE-CATCHING DISPLAY</h1>
            <p>
              Each room will have a digital screen displaying the leaderboard
              for teams to track their progress against their competition. Each
              hint, puzzle milestone, and level completion will trigger a
              dazzling graphic around the team photo, with the winning team
              reigning supreme on the screen.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ArenaWork;
