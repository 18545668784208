import React from "react";
import "../../../style/newlanding/arenaDo.scss";

import line from "../../../images/line1.svg";
import industry from "../../../images/img_patent.webp";
import industryMob from "../../../images/img_patent_mobile.svg";
import { useMediaQuery } from "react-responsive";

export const breakpoints = {
  mobile: `max-width: 1200px`,
  desktop: `min-width: 1200px`,
};

const ArenaDo = () => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });

  return (
    <div className="arenaDo">
      <div className="containerImages">
        {isMobile ? (
          <img className="image" src={industry} alt="industryMob" />
        ) : (
          <img className="image" src={industry} alt="industry" />
        )}
      </div>
      <div className="redLine"></div>
      <div className="container">
        <img className="line" src={line} alt="line" />

        <div className="title" id="whatItOffers">
          <h1>WHAT ARENA OFFERS</h1>
        </div>
        <ul className="list">
          <li>
            <p>
              <strong>
                Global Real-Time Competition: Connect with teams across the
                globe for an unmatched competitive experience.
              </strong>
            </p>
          </li>
          <li>
            <p>
              <strong>
                Equalized Playing Field: Our patented software ensures fair
                competition, up to 1.6 trillion inputs.
              </strong>
            </p>
          </li>
          <li>
            <p>
              <strong>
                Expanded Group Participation: No more turning away large groups;
                ARENA's online capabilities mean everyone can join in the fun,
                linking multiple locations for a collective gaming experience.
              </strong>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ArenaDo;
