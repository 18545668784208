import React from "react";
import "../style/maintanace.scss";
// import logo from "../../images/landingLogo.svg";
import logo from "../images/landingLogo.svg";

const Maintanace = () => {
  return (
    <div className="welcomeScreen">
      <a className="pageLogo" href="/">
        <img src={logo} alt="logo" />
      </a>
      <h2>
        We’re crafting something special to make your interactions more
        enjoyable. Stay tuned!
      </h2>
    </div>
  );
};

export default Maintanace;
