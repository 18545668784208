import React, { useEffect, useState, useRef } from "react";
import "../../style/gameMaster/Congratulations.scss";

import LogoPosition from "../../components/LogoPosition";
import Button from "../../elements/Button.js";

import { API } from "../../services/base";
import axios from "../../services/https";
import { useNavigate } from "react-router-dom";
import GoBack from "../GoBack";

// Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import moment from "moment";

const Congratulations = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token"));
  // const [matchId, setMatchiTD] = useState(localStorage.getItem("gameId"));
  // const [matchId, setMatchiTD] = useState(localStorage.getItem("gameCheckId"));
  const [matchId, setMatchiTD] = useState(localStorage.getItem("room"));

  //   Redux
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  const handleResetRoom = () => {
    axios
      .post(`${API}/room/reset`)
      .then((res) => {
        navigate(`/check/${token}`);
        localStorage.removeItem("persist:root");
        window.location.reload(false);

        // navigate("/available-matches");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [teamf, setT] = useState([]);

  useEffect(() => {
    axios
      .get(`${API}/game/${matchId}`)
      .then((res) => {
        setT(res.data.data.teams);
        console.log(res.data.data.teams, "res.data.data.teams");

        // navigate("/available-matches");
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const filteredArray = teamf.filter((obj) => obj.gameFinishedAt !== null);

  let [currentTime, setCurrentTime] = useState("");
  const [time, setTime] = useState(localStorage.getItem("time"));
  let [checkTime, setCheckTime] = useState("");

  const initialCounter = 300;
  const [counter, setCounter] = useState(initialCounter);

  const startTimer = () => {
    const timerInterval = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    return timerInterval;
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      clearInterval(timerIntervalRef.current);
    } else {
      timerIntervalRef.current = startTimer();
    }
  };

  const timerIntervalRef = useRef(null);

  useEffect(() => {
    console.log(teamf.length, "teamf.length");
    console.log(filteredArray.length, "filteredArray");
    if (teamf.length === filteredArray.length && counter > 0) {
      timerIntervalRef.current = startTimer();

      return () => clearInterval(timerIntervalRef.current);
    }

    if (counter === 0) {
      axios
        .post(`${API}/room/reset`)
        .then((res) => {
          navigate(`/check/${token}`);
          localStorage.removeItem("persist:root");
          window.location.reload(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(timerIntervalRef.current);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [counter]);

  const minutes = Math.floor(counter / 60);
  const seconds = counter % 60;

  const formattedTimer =
    String(minutes).padStart(2, "0") + ":" + String(seconds).padStart(2, "0");

  console.log(teamf.length, "teamf");

  return (
    <div className="congratulations">
      <GoBack />
      <div className="congratulationsWrap">
        <LogoPosition />
        <div className="content">
          <h2>Congratulations!</h2>
          {localStorage.getItem("checkSolo") === "false" ? (
            <p>
              Your team has successfully completed their escape room! All that’s
              left is to wait for the remaining teams to finish! Thank you for
              being patient!
            </p>
          ) : (
            ""
          )}

          {/* <Button title="Reset your room" onClick={handleResetRoom} /> */}

          {teamf.length === filteredArray.length ? (
            <Button title="Reset your room" onClick={handleResetRoom} />
          ) : (
            <Button
              title="Reset your room"
              onClick={handleResetRoom}
              disabled={true}
              className="myClass"
            />
          )}

          {teamf.length === filteredArray.length ? (
            <div className="roomResetAfter">
              <h3>Automatic room reset in:</h3>
              <h4>{formattedTimer}</h4>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
