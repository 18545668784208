import React, { useState } from "react";
import LogoPosition from "../../components/LogoPosition";
import team from "../../images/team.jpg";
import Button from "../../elements/Button";
import { API } from "../../services/base";
import axios from "../../services/https";

import "../../style/gameMaster/SoloMatch.scss";

import { useNavigate, Link } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import { useSelector } from "react-redux";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useDispatch } from "react-redux";
import { startTime } from "../../redux/actions/app.js";
import moment from "moment";
import { domen } from "../../services/base";

const SoloMatch = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const app = useSelector((state) => state.app);
  const [time, setTime] = useState(localStorage.getItem("time"));
  const [soloGameId, setSoloGameId] = useState(
    localStorage.getItem("soloGameId")
  );
  const dispatch = useDispatch();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const matchTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const handleSoloMatch = () => {
    setOpen(!open);
  };

  const startMatch = () => {
    const data = {
      game: soloGameId,
    };
    axios
      .post(`${API}/game/${soloGameId}/start`, data)
      .then((res) => {
        navigate("/my-platform");
        const found = res.data.data.teams.find((obj) => {
          return obj.id === app.team.id;
        });

        dispatch(startTime(found.gameStartedAt));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const mytime = `${time}`;
  // Split it into hours and minutes as numbers
  const [hours, minutes] = mytime.split(":").map(Number);
  // Get a date for "now"
  const dt = new Date();
  // Set its hours, minutes, seconds, and milliseconds
  dt.setHours(hours, minutes, 0, 0);
  // Get the string

  const formatted = moment(dt, "YYYY-MM-DD hh:mm:ss+ZZ").format("hh:mm");

  const createSoloGame = () => {
    const data = {
      teamId: app.team.id,
      startTime: formatted,
    };
    axios
      .post(`${API}/game`, data)
      .then((res) => {
        axios
          .post(`${API}/game/${res.data.data.id}/start`)
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="soloMatches">
      {/* Solo match */}
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpen(false)}
            ></animated.div>
          )
      )}

      {matchTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup">
              <div className="matchContent">
                {/* <h2>Are You Sure You Want To Start Top 10 Challenge?</h2> */}
                <h2>Start 10 top challenge?</h2>
                {/* <p>
                  Once you switch to Top 10 Challenge, you will not be able to
                  compete with other Escape rooms.
                </p> */}
                <Button title="Proceed" onClick={createSoloGame} />
                <Link className="goBack" onClick={() => setOpen(false)}>
                  go back
                </Link>
              </div>
            </animated.div>
          )
      )}
      {/* End solo match */}

      <LogoPosition />
      <h2 className="matchLobby">Your Match Lobby</h2>
      <Button title="Start match" onClick={startMatch} />
      <button className="switchToSolo" onClick={handleSoloMatch}>
        SWITCH TO SOLO PLAYER
      </button>

      <h2 className="yourTeam">Your Team</h2>

      <div className="teamCards">
        <div className="card">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="globalDropdown"
            >
              <Typography component={"span"} className="teamDropdown">
                <span>Team Name</span> {app.team.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordionDetails">
              <Typography component={"span"} className="teamImageAccordion">
                <div className="teamImage">
                  <div className="image">
                    <img
                      src={`https://prod.arenacompetition.world${app.team.image}`}
                      alt="team"
                    />
                  </div>
                </div>
                <div className="teamInfo">
                  <div className="roomFranchize">
                    <span>Escape Room Company</span>
                    <h5>{app.team.companyName}</h5>
                  </div>

                  <div className="location">
                    <span>Escape Room Location</span>
                    {!app.team.roomLocation ? (
                      <h5>-</h5>
                    ) : (
                      <h5>{app.team.roomLocation}</h5>
                    )}
                  </div>

                  <div className="roomName">
                    <span>Escape Room Name</span>
                    <h5>{app.team.roomName}</h5>
                  </div>

                  <div className="difficultyLevel">
                    <span>Difficulty Level</span>
                    <h5>{app.team.roomDifficultyLevel}</h5>
                  </div>

                  <div className="solvingFrame">
                    <span>Time Frame For Solving</span>
                    <h5>{app.team.roomDuration} minutes</h5>
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <Button title="Start match" onClick={startMatch} />
      <button className="switchToSolo downBtn" onClick={handleSoloMatch}>
        SWITCH TO SOLO PLAYER
      </button>
    </div>
  );
};

export default SoloMatch;
