import React from "react";

const ActiveSvg = (props) => {
  return (
    <div className={`active ${props.className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="44"
        // height="49"
        viewBox="0 0 44 49"
        fill="none"
        className="svgIcon"
      >
        <g clipPath="url(#clip0_1603_1637)">
          <path
            d="M0.900391 15.4498V33.1198C0.900391 35.1598 1.99039 37.0498 3.76039 38.0698L19.0604 46.9098C20.8304 47.9298 23.0004 47.9298 24.7704 46.9098L40.0704 38.0698C41.8404 37.0498 42.9304 35.1598 42.9304 33.1198V15.4498C42.9304 13.4098 41.8404 11.5198 40.0704 10.4998L24.7704 1.66979C23.0004 0.649785 20.8304 0.649785 19.0604 1.66979L3.76039 10.4998C1.99039 11.5198 0.900391 13.4098 0.900391 15.4498Z"
            fill="#EC297B"
            stroke="#EC297B"
            strokeWidth="1.8"
            strokeMiterlimit="10"
            className="change"
          />
          <path
            d="M34.2609 22.55L25.1109 16.9C23.9209 16.16 22.3809 17.02 22.3809 18.42V22.58C22.3809 22.58 22.3609 22.56 22.3409 22.55L13.1909 16.9C12.0009 16.16 10.4609 17.02 10.4609 18.42V29.72C10.4609 31.12 12.0009 31.98 13.1909 31.24L22.3409 25.59C22.3409 25.59 22.3609 25.57 22.3809 25.56V29.72C22.3809 31.12 23.9209 31.98 25.1109 31.24L34.2609 25.59C35.3909 24.89 35.3909 23.24 34.2609 22.55Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_1603_1637">
            <rect width="43.83" height="48.57" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ActiveSvg;
