import React, { useState, useEffect } from "react";
import "../../style/score/WinnerDisplay.scss";

import Echo from "laravel-echo";

import winner from "../../images/winner-photo.png";

import { API } from "../../services/base";
import axios from "../../services/https";
import { useNavigate } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { domen } from "../../services/base";

const WinnerDisplay = () => {
  const [teams, setTeams] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [time, setTime] = useState(localStorage.getItem("time"));

  const [roomChannel, setRoomChannel] = useState("");

  //   Redux
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const navigate = useNavigate();

  // Show teams
  const [showTeams, setShowTeams] = useState([]);
  const [bestTimes, setBestTimes] = useState([]);

  useEffect(() => {
    axios
      .get(`${API}/room`)
      .then((res) => {
        setRoomChannel(res.data.data.roomScreenChannel);
      })
      .catch();
  }, []);

  useEffect(() => {
    axios
      .get(`${API}/game/${app.allFinished.gameId}/winner`)
      .then((res) => {
        setShowTeams(res.data.data.teams);
        setBestTimes(res.data.data.bestTimes);
      })
      .catch();
  }, []);

  window.Echo = new Echo({
    broadcaster: "pusher",
    key: "2UhByxffTQ7XjcYWgfHbumDj",
    cluster: "mt1",
    authEndpoint: `https://prod.arenacompetition.world/broadcasting/auth`,
    wsHost: "prod.arenacompetition.world",
    auth: {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + `${token}`,
      },
    },
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    encrypted: true,
    enabledTransports: ["ws", "wss"],
    transports: ["websocket"],
  });
  // channel.private-game-1 = roomScreenChannel
  window.Echo.channel(`${roomChannel}`).listen(".reset", (e) => {
    navigate(`/new/${token}`);
    localStorage.removeItem("persist:root");
  });

  window.Echo.connector.pusher.connection.bind(
    "state_change",
    function (states) {
      if (states.current === "disconnected") {
        window.Echo.connector.pusher.connect();
        window.Echo.connector.pusher.subscribe();
      }
    }
  );

  return (
    <div className="winnerDisplay">
      {
        showTeams.map((item, index) => (
          <div className="firstPlace">
            <div className="shadow"></div>
            <img
              src={`https://prod.arenacompetition.world${item.image}`}
              alt="winner"
            />
            <div className="teamScoreInfo">
              <div className="score">
                <h5>Final score</h5>
                <h1>{Math.round(item.points)}</h1>
              </div>

              <div className="teamName">
                <h5>Winning team</h5>
                <h1>{item.name}</h1>
              </div>
            </div>
          </div>
        ))[0]
      }

      {showTeams.length === 1 ? (
        <div className="otherPlaces">
          {bestTimes.map((item, index) => {
            return (
              <div className="placeCard" key={item.id}>
                <div className="teamPlace">{index + 1}</div>
                <div className="nameOfTeam">{item.name}</div>
                <div className="teamScore">
                  <h4>Final score</h4>
                  <p>{item.points}</p>
                </div>
              </div>
            );
          })}
          <div aria-hidden="true">
            {" "}
            {bestTimes.map((item, index) => {
              return (
                <div className="placeCard" key={item.id}>
                  <div className="teamPlace">{index + 1}</div>
                  <div className="nameOfTeam">{item.name}</div>
                  <div className="teamScore">
                    <h4>Final score</h4>
                    <p>{item.points}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : showTeams.length < 7 ? (
        <div className="otherPlacesNoAnimation">
          {showTeams.slice(1).map((item, index) => {
            return (
              <div className="placeCard" key={item.id}>
                <div className="teamPlace">{index + 2}</div>
                <div className="nameOfTeam">{item.name}</div>
                <div className="teamScore">
                  <h4>Final score</h4>
                  <p>{item.points}</p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="otherPlaces">
          {showTeams.slice(1).map((item, index) => {
            return (
              <div className="placeCard" key={item.id}>
                <div className="teamPlace">1</div>
                <div className="nameOfTeam">{item.name}</div>
                <div className="teamScore">
                  <h4>Final score</h4>
                  <p>{item.points}</p>
                </div>
              </div>
            );
          })}
          {showTeams.slice(1).map((item, index) => {
            return (
              <div className="placeCard" key={item.id}>
                <div className="teamPlace">1</div>
                <div className="nameOfTeam">{item.name}</div>
                <div className="teamScore">
                  <h4>Final score</h4>
                  <p>{item.points}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default WinnerDisplay;
