import React, { useState, useEffect } from "react";
import "../../style/gameMaster/WinningDisplay.scss";

import logo from "../../images/new-logo.svg";
import team from "../../images/team.jpg";
import separator from "../../images/ic_separator.svg";
import cutLogo from "../../images/ic_cutted_logo.svg";
import Arena from "../../images/ic_arena.svg";

import Echo from "laravel-echo";

import winner from "../../images/winner-photo.png";

import { API } from "../../services/base";
import axios from "../../services/https";
import { useNavigate } from "react-router-dom";
import GoBack from "../GoBack";

// Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { domen } from "../../services/base";

const WinningDisplay = () => {
  const [teams, setTeams] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [time, setTime] = useState(localStorage.getItem("time"));
  const [teamScore, setTeamScore] = useState("");

  const [roomChannel, setRoomChannel] = useState("");

  //   Redux
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const navigate = useNavigate();

  // Show teams
  const [showTeams, setShowTeams] = useState([]);
  const [bestTimes, setBestTimes] = useState([]);

  useEffect(() => {
    axios
      .get(`${API}/room`)
      .then((res) => {
        setRoomChannel(res.data.data.roomScreenChannel);
      })
      .catch();
  }, []);

  useEffect(() => {
    axios
      .get(`${API}/game/${app.allFinished.gameId}/winner`)
      .then((res) => {
        setTeamScore(res.data.data);
        console.log(res.data.data);
        setShowTeams(res.data.data.teams);
        setBestTimes(res.data.data.bestTimes);
      })
      .catch();
  }, []);

  window.Echo = new Echo({
    broadcaster: "pusher",
    key: "2UhByxffTQ7XjcYWgfHbumDj",
    cluster: "mt1",
    authEndpoint: `https://prod.arenacompetition.world/broadcasting/auth`,
    wsHost: "prod.arenacompetition.world",
    auth: {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + `${token}`,
      },
    },
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    encrypted: true,
    enabledTransports: ["ws", "wss"],
    transports: ["websocket"],
  });
  // channel.private-game-1 = roomScreenChannel

  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    const channel = window.Echo.channel(`${roomChannel}`).listen(
      ".reset",
      (e) => {
        navigate(`/in-game/${token}`);
        localStorage.removeItem("persist:root");
        localStorage.removeItem("roomChannel");
        localStorage.removeItem("gameId");
        localStorage.removeItem("socketChannel");

        window.location.reload(false);
      }
    );

    channel.on("reconnecting", () => {
      setIsConnected(false);
    });

    channel.on("reconnected", () => {
      setIsConnected(true);
    });

    return () => {
      channel.unsubscribe();
    };
  });

  function getOrdinalIndicator(number) {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return "th";
    }

    switch (lastDigit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  return (
    <div className="winningDisplay">
      {/* <GoBack /> */}
      <img src={cutLogo} className="cutLogo" />

      <img src={Arena} className="arenaTitle" />
      {
        showTeams.map((item, index) => (
          <div className="winner">
            <div className="winnerLogo">
              <div className="placement">
                {showTeams.length === 1 ? (
                  <>
                    <h5>
                      {teamScore.positionInSolo}
                      <span>
                        {getOrdinalIndicator(teamScore.positionInSolo)}
                      </span>
                    </h5>
                    &nbsp;
                    <h5>place</h5>
                  </>
                ) : (
                  <>
                    <h5>
                      1<span>st</span>
                    </h5>
                    &nbsp;
                    <h5>place</h5>
                  </>
                )}
                {/* {teamScore.positionInGame && (
                  <div>
                    <h5>
                      {teamScore.positionInGame}
                      <span>st</span>
                    </h5>
                    &nbsp;
                    <h5>place</h5>
                  </div>
                )}

                {item.positionInSolo && (
                  <div>
                    <h5>
                      {item.positionInSolo}
                      <span>st</span>
                    </h5>
                    &nbsp;
                    <h5>place</h5>
                  </div>
                )} */}
              </div>{" "}
              <img src={separator} /> <h4>{item.name}</h4>
            </div>
            <div className="winnerNameId">
              <div className="winnerPoints">
                {Math.round(item.points)}{" "}
                <span>
                  {Math.round(item.points) === 1 ? "POINT" : "POINTS"}
                </span>
              </div>
              <div className="winnerHints">
                {item.hintCount}{" "}
                <span>{item.hintCount === 1 ? "HINT" : "HINTS"}</span>
              </div>
              <div className="winnerLeft">
                {item.timeLeft.slice(0, 2)}m {item.timeLeft.slice(3, 5)}s{" "}
                <span>left</span>
              </div>
            </div>
            <div className="winnerInfo">
              <img
                src={`https://prod.arenacompetition.world${item.image}`}
                alt={`https://prod.arenacompetition.world${item.image}`}
              />
            </div>

            <div className="dot"></div>
            <div className="dot1"></div>
            <div className="dot2"></div>
            <div className="dot3"></div>
          </div>
        ))[0]
      }
      {showTeams.length === 1 ? (
        <div className="otherTeamsScore">
          <h1 className="otherTeamsTitle">Top 10 challenge</h1>
          <div className="anim primary">
            {bestTimes.map((item, index) => {
              return (
                <div className="topPlayerCard" key={item.id}>
                  <div className="teamRate">{index + 1}</div>
                  <div className="teamName">{item.name}</div>
                  <div className="teamScore">
                    <span>
                      {item.points} {item.points === 1 ? "POINT" : "POINTS"}
                    </span>{" "}
                    &nbsp; / {item.hintCount}{" "}
                    {item.hintCount === 1 ? "HINT" : "HINTS"} /{" "}
                    {item.timeLeft.slice(0, 2)}m {item.timeLeft.slice(3, 5)}s
                    LEFT
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : showTeams.length < 9 ? (
        <div className="otherTeamsScore">
          <div className="anim">
            {showTeams.slice(1).map((item, index) => {
              return (
                <div className="topPlayerCard" key={item.id}>
                  <div className="teamRate">{index + 2}</div>
                  <div className="teamName">{item.name}</div>
                  <div className="teamScore">
                    <span>
                      {" "}
                      {Math.round(`${item.points}`)}&nbsp;{" "}
                      {item.points === 1 ? "POINT" : "POINTS"}
                    </span>{" "}
                    &nbsp; / {item.hintCount}{" "}
                    {item.hintCount === 1 ? "HINT" : "HINTS"} /{" "}
                    {item.timeLeft.slice(0, 2)}m {item.timeLeft.slice(3, 5)}s
                    LEFT
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="otherTeamsScore">
          <div className="anim primary">
            {showTeams.slice(1).map((item, index) => {
              return (
                <div className="topPlayerCard" key={item.id}>
                  <div className="teamRate">{index + 1}</div>
                  <div className="teamName">{item.name}</div>
                  <div className="teamScore">
                    <span>
                      {item.points} {item.points === 1 ? "POINT" : "POINTS"}
                    </span>{" "}
                    &nbsp; / {item.hintCount}{" "}
                    {item.hintCount === 1 ? "HINT" : "HINTS"} /{" "}
                    {item.timeLeft.slice(0, 2)}m {item.timeLeft.slice(3, 5)}s
                    LEFT
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default WinningDisplay;
