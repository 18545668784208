import React, { useState, useEffect } from "react";
import "../../style/gameMaster/Team.scss";

import LogoPosition from "../../components/LogoPosition";
import Button from "../../elements/Button";
import rotateImage from "../../images/rotate-smartphone.svg";

import { useTransition, animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/base";
import axios from "../../services/https";

import { useDispatch } from "react-redux";
import { saveTeam } from "../../redux/actions/app.js";

import GoBack from "../GoBack";
import { TailSpin } from "react-loader-spinner";

import moment from "moment";
import "moment-timezone";

import Resizer from "react-image-file-resizer";
import TimePicker from "react-time-picker";
import { domen } from "../../services/base";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      400,
      "JPEG",
      40,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const Team = () => {
  const [name, setName] = useState("");
  // const [startTime, setStartTime] = useState("");
  const [startTime, setStartTime] = useState("");
  useEffect(() => {
    // Function to get the current time in "hh:mm AM/PM" format
    const getCurrentTime = () => {
      const currentDate = new Date();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();

      // Convert to 12-hour time format
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

      // Ensure the minutes are displayed as two digits (e.g., 07, 09)
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      // Create the formatted time string
      return `${formattedHours}:${formattedMinutes}`;
    };

    // Set the state with the current time
    setStartTime(getCurrentTime());
  }, []);
  const [source, setSource] = useState("");
  const [imagePreview, setImage] = useState(null);

  const [preparingSubmit, setPreparingSubmit] = useState(false);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [imageError, setImageError] = useState("");
  const [selectedAmPm, setSelectedAmPm] = useState("pm"); // AM or PM selection

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // useEffect(() => {
  //   localStorage.setItem("time", localTime);
  // }, [localTime]);

  var myzone = moment.tz.guess();
  // var localTime = moment
  //   .utc(`${startTime}`, "HH:mm")
  //   // .tz(myzone)
  //   .format("HH:mm");

  const strDate = startTime;
  const arr = strDate.split(":");
  const splitHour = parseInt(arr[0]);
  const splitMin = parseInt(arr[1]);

  var zoneTime = moment();
  var parsedHourFromUser = splitHour;
  var parsedMinutesFromUser = splitMin;
  zoneTime.hour(parsedHourFromUser);
  zoneTime.minute(parsedMinutesFromUser);

  const myTime = zoneTime.utc().format("HH:mm");

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const popupTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const [timeCheck, setTimeCheck] = useState();

  useEffect(() => {
    setTimeCheck(moment().utc().tz(myzone).format("HH:mm"));
  }, [timeCheck]);

  useEffect(() => {
    const zoneTime = moment();
    zoneTime.hour(parsedHourFromUser);
    zoneTime.minute(parsedMinutesFromUser);

    const myTime = zoneTime.format("HH:mm");
    setSelectedAmPm(parsedHourFromUser >= 12 ? "pm" : "am");
  }, [startTime]);

  // Postavite vreme na trenutno vreme pri prvom učitavanju komponente
  useEffect(() => {
    const getCurrentTime = () => {
      const currentDate = new Date();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      return `${hours}:${minutes}`;
    };
    setStartTime(getCurrentTime());
  }, []);

  // Funkcija koja se poziva kada se promeni vreme u TimePicker komponenti
  const handleTimeChange = (newTime) => {
    setStartTime(newTime);

    // Postavite AM ili PM na osnovu vrednosti novog vremena
    const hours = parseInt(newTime.split(":")[0]);
    setSelectedAmPm(hours >= 12 ? "pm" : "am");
  };

  const handleAmPmChange = (e) => {
    setSelectedAmPm(e.target.value);
  };

  const handleImage = async (e) => {
    setSource(e.target.files[0]);

    // if (e.target.files && e.target.files[0]) {
    //   setImage(URL.createObjectURL(e.target.files[0]), {
    //     meta: true,
    //     orientation: true,
    //   });
    // }

    const file = e.target.files[0];
    const image = await resizeFile(file);
    setImage(image);
  };

  const removeImage = () => {
    setImage(null);
  };

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: name,
      image: source,
    };

    axios
      .post(`${API}/team`, data, config)
      .then((res) => {
        // setOpen(false);
        navigate("/available-matches", { replace: true });
        localStorage.setItem("time", myTime);
        // navigate("/available-matches");

        // localStorage.setItem("user", JSON.stringify(res.data.data));
        dispatch(saveTeam(res.data.data));
        console.log(res.data.data, "TEAM");
      })
      .catch((err) => {
        if (open === err) {
          setOpen(false);
        }
        setError(err.response.data.errors);
        setImageError(err.response.data.messages.game);
        console.log(err.response.data.messages.game, "ERROR");
        setPreparingSubmit(false);
        window.scrollTo(0, 20);
      });
  };

  const handleOpenPopup = (e) => {
    e.preventDefault();

    setOpen(!open);
    setError("");
    window.scrollTo(0, 20);
  };

  function format12HourTime(time) {
    const parts = time.split(":");
    const hour = parseInt(parts[0], 10);
    const minute = parts[1];
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
    return `${formattedHour}:${minute} ${period}`;
  }

  const handleTeamPopup = () => {
    setOpen(false);
    setImageError("");
  };

  return (
    <div className="team">
      <div className="teamWrapp">
        <GoBack />
        <LogoPosition />

        {/* Team info popup */}
        {transitions(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="popupBackground"
                onClick={() => setOpen(false)}
              ></animated.div>
            )
        )}

        {popupTransitions(
          (styles, item) =>
            item && (
              <animated.div style={styles} className="popUp">
                <form className="popupHeader" onSubmit={handleSubmit}>
                  <h2>Save Team Information?</h2>
                  <p>Once you save this information, it can’t be edited.</p>

                  <div className="info">
                    <label>Team Name</label>
                    <h2 className="popupInfo">{name}</h2>
                    {error ? <p className="errorMessage">{error.name}</p> : ""}
                  </div>

                  <div className="info">
                    <label>Start Time</label>
                    <h2 className="popupInfo">
                      {/* {startTime} */}
                      {format12HourTime(startTime)}
                    </h2>
                  </div>

                  <div className="info">
                    <label>Team Photo</label>
                    <img src={imagePreview} alt="preview image" />
                    {error ? <p className="errorMessage">{error.image}</p> : ""}
                    {error ? <p className="errorMessage">{error.team}</p> : ""}
                    {imageError ? (
                      <p className="errorMessage">{imageError}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <Button
                    title="Save The Info"
                    type="submit"
                    onClick={() => setPreparingSubmit(true)}
                  />

                  {preparingSubmit ? (
                    <TailSpin
                      height="80"
                      width="80"
                      color="#808080"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass="teamSpiner"
                      visible={true}
                    />
                  ) : (
                    ""
                  )}

                  <a className="goBack" onClick={handleTeamPopup}>
                    go back
                  </a>
                </form>
              </animated.div>
            )
        )}
        {/* End team info popup */}

        <h2>CREATE A NEW TEAM</h2>

        <form onSubmit={handleOpenPopup}>
          <div className="element">
            <label>Team Name</label>
            <input
              type="text"
              placeholder="Enter Team Name Here..."
              maxLength="21"
              onChange={(e) => setName(e.target.value)}
            />
            {error ? <p className="errorMessage">{error.name}</p> : ""}
          </div>
          <div className="element">
            <label>Start Time</label>
            {/* <input
              className="time"
              type="time"
              onChange={(e) => setStartTime(e.target.value)}
              placeholder="19:00"
            /> */}
            <div className="timePickerWrapper">
              <TimePicker
                onChange={handleTimeChange}
                className="timePickerInput"
                value={startTime}
                format="h:mm a"
                clearIcon={null}
                clockIcon={null}
                disableClock
              />
            </div>

            {(() => {
              if (startTime < timeCheck && startTime != 0) {
                return (
                  <p className="errorMessage">Please choose a valid time.</p>
                );
              }
            })()}
          </div>
          <div className="element">
            <label>Team Photo</label>
            {imagePreview ? (
              <div className="previewImage">
                <img src={imagePreview} alt="preview image" />
                <button onClick={removeImage}>Remove Image</button>
              </div>
            ) : (
              <div className="form-group">
                <label htmlFor="fileField" className="attachment">
                  <div className="row btn-file">
                    <div className="btn-file__preview"></div>
                    <div className="btn-file__actions">
                      <div className="btn-file__actions__item col-xs-12 text-center">
                        <div className="btn-file__actions__item--shadow">
                          <img
                            className="rotatedImage"
                            src={rotateImage}
                            alt="rotateImage"
                          />
                          <br />
                          <br />
                          Click on 'TAKE A PHOTO' button, rotate your phone 90
                          degrees and take a picture of your team in a
                          horizontal orientation.
                          <p>Take a photo</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    name="myImage"
                    type="file"
                    id="fileField"
                    accept="image/*"
                    capture="camera"
                    onChange={handleImage}
                  />
                </label>
                {/* {error ? <p className="errorMessage">{error.image}</p> : ""} */}
              </div>
            )}
          </div>

          {(() => {
            if (!name) {
              return (
                <Button
                  title="Save The Info"
                  className="teamInfoBtn"
                  disabled={true}
                />
              );
            }
            if (!source) {
              return (
                <Button
                  title="Save The Info"
                  className="teamInfoBtn"
                  disabled={true}
                />
              );
            }
            if (!startTime) {
              return (
                <Button
                  title="Save The Info"
                  className="teamInfoBtn"
                  disabled={true}
                />
              );
            }
            if (startTime < timeCheck && startTime != 0) {
              return (
                <Button
                  title="Save The Info"
                  className="teamInfoBtn"
                  disabled={true}
                />
              );
            } else {
              return <Button title="Save The Info" />;
            }
          })()}
          {/* <Button title="Save team information" /> */}
        </form>
      </div>
    </div>
  );
};

export default Team;
