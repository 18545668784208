import React, { useState } from "react";
import { useTransition, animated } from "react-spring";
import { Link, useNavigate } from "react-router-dom";

import "../../style/newlanding/header.scss";

import logo from "../../images/landingLogo.svg";
import desktopLogo from "../../images/ic_logo-color.svg";
import hamburger from "../../images/hamburgerWhite.svg";
import close from "../../images/closeWhite.svg";
import Button from "../../elements/Button";

const Header = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const headerTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const handleLoginRoute = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="mobileHeader">
        <div className="mobileHeaderContent">
          {" "}
          {headerTransitions(
            (styles, item) =>
              item && (
                <animated.div style={styles} className="headerPopup">
                  <div className="headerMain">
                    {/* <img src={logo} alt="logo" /> */}
                    <img
                      src={close}
                      alt="close"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                  <div className="headerContent">
                    <img className="logoHeader" src={desktopLogo} alt="logo" />
                    <ul>
                      <li>
                        <a href="/#about" onClick={() => setOpen(false)}>
                          About
                        </a>
                      </li>
                      <li>
                        <a href="/#whatItOffers" onClick={() => setOpen(false)}>
                          What it offers
                        </a>
                      </li>
                      <li>
                        <a href="/#howItWorks" onClick={() => setOpen(false)}>
                          How it works
                        </a>
                      </li>
                      <li>
                        <a href="/#whyJoin" onClick={() => setOpen(false)}>
                          Why join
                        </a>
                      </li>
                      <li>
                        <a href="/#contactUs" onClick={() => setOpen(false)}>
                          Contact us
                        </a>
                      </li>
                    </ul>
                    <div className="loginHeaderButton">
                      <button className="button" onClick={handleLoginRoute}>
                        Log in
                      </button>
                    </div>
                  </div>
                </animated.div>
              )
          )}
          <img src={desktopLogo} alt={desktopLogo} />
          <img src={hamburger} onClick={() => setOpen(true)} />
        </div>
      </div>

      <div className="desktopHeader">
        <div className="headerContent">
          <div className="menuLeft">
            <img className="logo" src={desktopLogo} alt="desktopLogo" />
            <ul className="menu">
              <li>
                <a href="/#about">About</a>
              </li>
              <li>
                <a href="/#whatItOffers">What it offers</a>
              </li>
              <li>
                <a href="/#howItWorks">How it works</a>
              </li>
              <li>
                <a href="/#whyJoin">Why join</a>
              </li>
              <li>
                <a href="/#contactUs">Contact us</a>
              </li>
            </ul>
          </div>
          <div className="loginHeaderButton">
            <button className="button" onClick={handleLoginRoute}>
              Log in
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
