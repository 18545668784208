import React from "react";
import { useMediaQuery } from "react-responsive";
import footerLogo from "../../images/img_world.png";
import footerLogoMob from "../../images/img_world_footer_mobile.svg";

import "../../style/newlanding/footer.scss";

export const breakpoints = {
  mobile: `max-width: 1200px`,
  desktop: `min-width: 1200px`,
};

const Footer = () => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });

  return (
    <div className="footerWrap">
      {isMobile ? (
        <img className="image" src={footerLogoMob} alt={footerLogoMob} />
      ) : (
        <img className="logo" src={footerLogo} alt={footerLogo} />
      )}
    </div>
  );
};

export default Footer;
