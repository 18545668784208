const get_api = () => {
  // let API = "https://arena-api.qwertify.io/api";
  let API = "https://prod.arenacompetition.world/api";

  let domen = "prod.arenacompetition.world";

  // src={`https://arena-api.qwertify.io${item.image}`}

  https: return {
    API,
    domen,
  };
};

export const { API } = get_api();
export const { domen } = get_api();
