import React from "react";

// Style
import "../../../style/home/Rooms.scss";

// Images
import Baner from "../../../images/In_Game_Banner.png";

const Rooms = () => {
  const handleRoute = () => {
    const contactElement = document.getElementById("contact");
    if (contactElement) {
      contactElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="roomWrapper">
      <div className="line"></div>
      <div className="box">
        <h2>
          finally you can say ‘yes’ to competition between your rooms. but
          that’s not all...
        </h2>
      </div>
      <div className="boxList">
        <ul>
          <li>
            <h5>expert-designed software</h5>
            <p>
              ARENA software is developed by a team of expert escape room owners
              and <strong>Hall of Fame mathematicians</strong>, boasting{" "}
              <strong>16 Mathematics Olympiad Gold Medals</strong>, ensuring a
              foundation built on deep expertise and precision.{" "}
            </p>
          </li>
          <li>
            <h5>finally say ‘yes’ </h5>
            <p>
              How often have you had to turn down customers' requests to compete
              in different rooms due to varying difficulty levels, group sizes,
              and numbers of puzzles and steps? At last, escape rooms across the
              globe can clash in epic battles! Whether it's the same location or
              across different cities and continents, nothing stands in the way.
              <br />
              <br />
              Our proprietary technology sets ARENA apart from any other product
              on the market, removing traditional barriers and limitations.{" "}
              <br />
              <strong>
                Group size, gameplay variables, and geographical distance are
                now just issues of the past.
              </strong>
            </p>
          </li>
          <li>
            <h5>fair play guaranteed</h5>
            <p>
              Our algorithm handles up to{" "}
              <span>
                <strong>1.6 trillion inputs</strong>
              </span>
              , guaranteeing fair competition.
            </p>
          </li>
        </ul>

        <button className="contactUs" onClick={() => handleRoute()}>
          <span>contact us</span>
        </button>
      </div>

      <img className="banerImage" src={Baner} alt={Baner} />
    </div>
  );
};

export default Rooms;
