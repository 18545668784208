import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import LogoPosition from "../components/LogoPosition";
import Button from "../elements/Button";
import "../style/ProfileScreen.scss";
import { getDetails, getPrice } from "../api";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../stripe/CheckoutForm";
import axios from "axios";
import { API } from "../services/base";
import arrow from "../images/icons/Icon_Arrow.svg";
import close from "../images/icClose.svg";
// import green from "../images/ribbon_green@2x.webp";
import green from "../images/ribbon_white@2x.webp";
import yellow from "../images/ribbon_white_yellow.webp";
import red from "../images/ribbon_white_red@2x.webp";
import correct from "../images/ic_yes.svg";
import incorrect from "../images/ic_no.svg";

const stripePromise = loadStripe(
  "pk_test_51O5o2XKwjAAUpmGvxLfGV9wumYDDW1llMDf5W9xZ3g0btK3Ir4KolXAf5MmD4AyW2dlO5ZCUQa6GXssXdAXvDiO000uKFFgi9k"
);

const Profile = () => {
  const [open, setOpen] = useState(false);
  const [openRooms, setOpenRooms] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFalse, setOpenFalse] = useState(false);
  const [details, setDetails] = useState("");
  const [priceDetails, setPriceDetails] = useState([]);
  const [secretKeyValue, setSecretKeyValue] = useState("");
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [erros, setError] = useState("");

  const options = {
    mode: "payment",
    amount: 1099,
    currency: "usd",
    appearance: {
      theme: "night",
    },
  };

  const fetchDetails = async () => {
    try {
      const response = await getDetails();
      console.log(response.data.data);
      setDetails(response.data.data);
      localStorage.setItem("customerId", response.data.data.id);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const fetchPrice = async () => {
    try {
      const response = await getPrice();
      setPriceDetails(response.data.data);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  useEffect(() => {
    fetchDetails();
    fetchPrice();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("accessTokenLogin");
    navigate("/login");
  };

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const popupTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const transitionsRoom = useTransition(openRooms, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const popupRoomTransitions = useTransition(openRooms, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const transitionsSuccess = useTransition(openSuccess, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const popupTransitionsSuccess = useTransition(openSuccess, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const transitionsFalse = useTransition(openFalse, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const popupTransitionsFalse = useTransition(openFalse, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  /* Popup */
  {
    transitions(
      (styles, item) =>
        item && (
          <animated.div
            style={styles}
            className="popupBackground"
            onClick={() => setOpen(false)}
          ></animated.div>
        )
    );
  }

  {
    popupTransitions(
      (styles, item) =>
        item && (
          <animated.div style={styles} className="popUp">
            <div className="popupHeader">
              <h2>Save Team Information?</h2>
            </div>
          </animated.div>
        )
    );
  }
  /* End popup */

  /* Popup rooms */
  {
    transitionsRoom(
      (styles, item) =>
        item && (
          <animated.div
            style={styles}
            className="popupBackground"
            onClick={() => setOpenRooms(false)}
          ></animated.div>
        )
    );
  }

  {
    popupRoomTransitions(
      (styles, item) =>
        item && (
          <animated.div style={styles} className="popUp">
            <div className="popupHeader">
              <h2>Save Team Information?</h2>
            </div>
          </animated.div>
        )
    );
  }

  /* End popup rooms*/

  /* Popup success */
  {
    transitionsSuccess(
      (styles, item) =>
        item && (
          <animated.div
            style={styles}
            className="popupBackground"
            onClick={() => setOpenSuccess(false)}
          ></animated.div>
        )
    );
  }

  {
    popupTransitionsSuccess(
      (styles, item) =>
        item && (
          <animated.div style={styles} className="popUp">
            <div className="popupHeader">
              <h2>Save Team Information?</h2>
            </div>
          </animated.div>
        )
    );
  }

  /* End popup success*/

  /* Popup false */
  {
    transitionsFalse(
      (styles, item) =>
        item && (
          <animated.div
            style={styles}
            className="popupBackground"
            onClick={() => setOpenFalse(false)}
          ></animated.div>
        )
    );
  }

  {
    popupTransitionsFalse(
      (styles, item) =>
        item && (
          <animated.div style={styles} className="popUp">
            <div className="popupHeader">
              <h2>Save Team Information?</h2>
            </div>
          </animated.div>
        )
    );
  }

  /* End popup false*/

  const cardInfo = [
    {
      id: "price_1OKKFCKwjAAUpmGvHN1CAwI4",
      title: "Basic",
      price: "$99",
      valuePrice: 99,
      value: "basic",
      optionsTitle: "Up to 20 matches",
      optionsTitleSecond: `Each additional match is`,
      options: [
        {
          status: correct,
          name: "Local Matches",
        },
        {
          status: correct,
          name: "Worldwide Matches ",
        },
        {
          status: correct,
          name: "Head-to-Head Mode ",
        },
        {
          status: correct,
          name: "Top 10 Challenge",
        },
        {
          status: incorrect,
          name: "Elite Top 5 ",
        },
        {
          status: incorrect,
          name: "Rapid Updates & Services ",
        },
        {
          status: incorrect,
          name: "24/7 Live Support",
        },
      ],
    },
    {
      id: "price_1OWyXiKwjAAUpmGvjkbxmAe5",
      title: "Basic+",
      price: "$129",
      valuePrice: 129,
      value: "premium",
      optionsTitle: "Up to 30 matches",
      optionsTitleSecond: "Each additional match is",
      options: [
        {
          status: correct,
          name: "Local Matches",
        },
        {
          status: correct,
          name: "Worldwide Matches ",
        },
        {
          status: correct,
          name: "Head-to-Head Mode ",
        },
        {
          status: correct,
          name: "Top 10 Challenge",
        },
        {
          status: correct,
          name: "Elite Top 5 ",
        },
        {
          status: correct,
          name: "Rapid Updates and Services ",
        },
        {
          status: incorrect,
          name: "24/7 Live Support   ",
        },
      ],
    },
    {
      id: "price_1OWyYHKwjAAUpmGvXuMS46k3",
      title: "Arena Pro Package",
      price: "$159",
      valuePrice: 159,
      value: "ultra",
      optionsTitle: "Unlimited Matches",
      options: [
        {
          status: correct,
          name: "Local Matches",
        },
        {
          status: correct,
          name: "Worldwide Matches ",
        },
        {
          status: correct,
          name: "Head-to-Head Mode ",
        },
        {
          status: correct,
          name: "Top 10 Challenge",
        },
        {
          status: correct,
          name: "Elite Top 5 ",
        },
        {
          status: correct,
          name: "Rapid Updates & Services ",
        },
      ],
    },
  ];

  const [radioInfo, setRadioInfo] = useState("");

  const handleSubscription = () => {
    setOpen(true);
  };

  const [sumTotal, setSumTotal] = useState(0);
  const [oneTimeFee, setOneTimeFee] = useState(0);
  const [roomLength, setRoomLength] = useState(0);

  const handleSubscriptionChange = (item) => {
    setRadioInfo(item);

    handleSubscription();

    // const newSumTotal =
    //   details.rooms.length * item.price +
    //   (!details.paymentMethodId ? details.rooms.length * 149 : 0);

    const newSumTotal = details.rooms.length * item.price;

    console.log(item.price, "item.price");

    const setupFee = priceDetails[0]?.currency === "A$" ? 655 : 399;

    const timeFee = !details.paymentMethodId
      ? details.rooms.length * setupFee
      : 0;

    setOneTimeFee(timeFee);

    setSumTotal(newSumTotal);

    setRoomLength(details.rooms.length);
  };

  console.log(details.paymentMethodId);

  const lengtValue = 2;

  const handelResetRedirect = () => {
    navigate("/restart");
  };

  const handleRedirect = () => {
    navigate("/");
  };

  const handleBillingOptions = () => {
    fetchDetails();
    window.open(details?.billingPortalUrl);
  };

  return (
    <div className="profileScreen">
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpen(false)}
            ></animated.div>
          )
      )}

      {popupTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup">
              <div className="matchContent cardInfoPopup">
                <img
                  className="cardInfoImage"
                  src={close}
                  onClick={() => setOpen(false)}
                />
                <h3
                  style={{ marginBottom: "10px", textTransform: "uppercase" }}
                >
                  Subscribe
                </h3>
                <p className="totalPayExplane">
                  This is the total payment covering the cost of all your rooms
                  per month.
                </p>
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    cardId={radioInfo.stripe_id}
                    price={radioInfo.price}
                    email={details.email}
                    priceid={radioInfo.stripe_id}
                    cardMethod={details.paymentMethodId}
                    setOpen={setOpen}
                    setOpenSuccess={setOpenSuccess}
                    setOpenFalse={setOpenFalse}
                    fetchDetails={fetchDetails}
                    totalPrice={sumTotal}
                    oneFee={oneTimeFee}
                    fullInfo={roomLength}
                    currencySymbol={priceDetails[0]?.currency}
                  />
                </Elements>
              </div>
            </animated.div>
          )
      )}

      {/* Open rooms */}
      {transitionsRoom(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpenRooms(false)}
            ></animated.div>
          )
      )}

      {popupRoomTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup roomPopup">
              <div className="matchContent">
                <img
                  className="closePopup"
                  src={close}
                  onClick={() => setOpenRooms(false)}
                />
                <div className="roomsSections">
                  {details.rooms.map((item, index) => {
                    return (
                      <div className="roomContent" key={index}>
                        <h4>
                          Name: <span>{item.name}</span>
                        </h4>
                        <h4>
                          Difficulty: <span>{item.difficultyLevel}</span>
                        </h4>
                        <h4>
                          Room duration: <span>{item.duration}</span>
                        </h4>
                      </div>
                    );
                  })}
                </div>
              </div>
            </animated.div>
          )
      )}
      {/* Set open rooms */}

      {/* Open success */}
      {transitionsSuccess(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpenSuccess(false)}
            ></animated.div>
          )
      )}

      {popupTransitionsSuccess(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup">
              <div className="matchContent cardInfoPopup">
                <h3 style={{ textTransform: "uppercase" }}>success</h3>
              </div>
            </animated.div>
          )
      )}
      {/* End open success */}

      {/* Open false */}
      {transitionsFalse(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpenFalse(false)}
            ></animated.div>
          )
      )}

      {popupTransitionsFalse(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup">
              <div className="matchContent cardInfoPopup">
                <h3>{localStorage.getItem("error")}</h3>
              </div>
            </animated.div>
          )
      )}
      {/* End open false */}

      <div className="topSection">
        <a onClick={handleRedirect} style={{ cursor: "pointer" }}>
          <LogoPosition />
        </a>

        <h3>My Escape Room</h3>
      </div>
      <div className="middleSection">
        <div className="infoSection">
          <div className="infoName">
            <h5>COMPANY NAME</h5>
            <h3>
              {details?.name?.length > 20
                ? `${details?.name.substring(0, 20).trim()}...`
                : details?.name}
            </h3>
          </div>
          <div className="infoCompanyName">
            <h5>COMPANY ADDRESS</h5>
            <h3>
              {details?.address?.length > 25
                ? `${details?.address.substring(0, 25).trim()}...`
                : details?.address}
            </h3>
          </div>
          <div className="infoTimeZone">
            <h5>TIME ZONE</h5>
            <h3>{details?.timezone}</h3>
          </div>
          <div className="infoAddress">
            <h5>Number of rooms</h5>
            {}
            <h3>
              {details?.rooms?.length > 0 ? (
                <div
                  onClick={() => setOpenRooms(true)}
                  className="infoAddressContent"
                >
                  {details?.rooms?.length}
                  <img src={arrow} alt={arrow} />
                </div>
              ) : (
                0
              )}
            </h3>
          </div>
        </div>
        <div className="subscriptionSection">
          {details?.rooms?.length > 0 ? (
            <>
              <h2>SUBSCRIPTION</h2>
              <p className="bodyText">
                The subscription models are billed on a monthly basis per room.
                In addition to the monthly fee, there is an initial setup fee of
                :
                <span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: priceDetails[0]?.currency,
                    }}
                  />
                  {priceDetails[0]?.currency === "A$"
                    ? " 655 per room"
                    : " 399 per room"}
                </span>
              </p>
              <div className="subscribeCards">
                {priceDetails.map((item, index) => {
                  return (
                    <label
                      className={
                        item.name === details.subscriptionPackageName
                          ? "payed"
                          : "subscribeCardWrap"
                      }
                      key={index}
                    >
                      {/* {item.name === "Arena Pro Packag" ? (
                        <p className="proStyle">BEST VALUE</p>
                      ) : (
                        ""
                      )} */}

                      {/* {item.name === details.subscriptionPackageName ? (
                        <img
                          className="ribbon"
                          src={
                            details?.subscriptionPackageName === "Basic"
                              ? red
                              : details?.subscriptionPackageName === "Basic+"
                              ? yellow
                              : details?.subscriptionPackageName ===
                                "Arena Pro Package"
                              ? red
                              : null
                          }
                          alt=""
                        />
                      ) : (
                        ""
                      )} */}

                      <div className="cardContent">
                        <div className="cardTitle">
                          <h3>{item.name}</h3>
                        </div>
                        <h3
                          style={{
                            fontWeight: "bold",

                            textAlign: "center",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                          className="priceCard"
                        >
                          <div
                            style={{
                              display: "flex",

                              alignItem: "center",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <div
                              className="topCompanyTitle"
                              dangerouslySetInnerHTML={{
                                __html: item?.currency,
                              }}
                            />
                          </div>
                          {item.price}
                        </h3>

                        {item.name === "Basic" && (
                          <div
                            className={
                              details?.subscriptionPackageName ===
                              "Arena Pro Package"
                                ? "matchNumberBorder justGreenPack"
                                : "matchNumberBorder"
                            }
                          >
                            {" "}
                            <p>{cardInfo[0].optionsTitle}</p>
                            <p
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {cardInfo[0].optionsTitleSecond}{" "}
                              <div
                                style={{ marginLeft: "5px" }}
                                dangerouslySetInnerHTML={{
                                  __html: priceDetails[0]?.currency,
                                }}
                              />
                              5
                            </p>
                          </div>
                        )}

                        {item.name === "Basic+" && (
                          <div
                            className={
                              details?.subscriptionPackageName ===
                              "Arena Pro Package"
                                ? "matchNumberBorder justGreenPack"
                                : "matchNumberBorder"
                            }
                          >
                            {" "}
                            <p>{cardInfo[1].optionsTitle}</p>
                            <p
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {cardInfo[1].optionsTitleSecond}{" "}
                              <div
                                style={{ marginLeft: "5px" }}
                                dangerouslySetInnerHTML={{
                                  __html: priceDetails[0]?.currency,
                                }}
                              />
                              5
                            </p>
                          </div>
                        )}

                        {/* {item.name === "Arena Pro Package" && (
                          <div
                            className={
                              details?.subscriptionPackageName ===
                              "Arena Pro Package"
                                ? "matchNumberBorder justGreenPack"
                                : "matchNumberBorder"
                            }
                          >
                            {" "}
                            <p>{cardInfo[2].optionsTitle}</p>
                            <p>{cardInfo[2].optionsTitleSecond}</p>
                          </div>
                        )} */}

                        {item.name === "Basic" && (
                          <ul>
                            {cardInfo[0].options.map((option, index) => {
                              return (
                                <li key={index}>
                                  <img
                                    src={option.status}
                                    alt={option.status}
                                  />
                                  {option.name}
                                </li>
                              );
                            })}
                          </ul>
                        )}

                        {item.name === "Basic+" && (
                          <ul>
                            {cardInfo[1].options.map((option, index) => {
                              return (
                                <li key={index}>
                                  <img
                                    src={option.status}
                                    alt={option.status}
                                  />
                                  {option.name}
                                </li>
                              );
                            })}
                          </ul>
                        )}

                        {item.name === "Arena Pro Package" && (
                          <div className="listSection">
                            <ul>
                              {cardInfo[2].options
                                .splice(0, 3)
                                .map((option, index) => {
                                  return (
                                    <li key={index}>
                                      <img
                                        src={option.status}
                                        alt={option.status}
                                      />
                                      {option.name}
                                    </li>
                                  );
                                })}
                            </ul>
                            <ul>
                              {cardInfo[2].options
                                .splice(0, 3)
                                .map((option, index) => {
                                  return (
                                    <li key={index}>
                                      <img
                                        src={option.status}
                                        alt={option.status}
                                      />
                                      {option.name}
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        )}
                      </div>
                      <Button
                        title="Select"
                        onClick={() => handleSubscriptionChange(item)}
                        className={
                          item.name === details.subscriptionPackageName
                            ? "payedButton"
                            : ""
                        }
                      />
                    </label>
                  );
                })}
              </div>
              {details?.paymentMethodId != null && (
                <span style={{ color: "#fff", fontSize: "16px" }}>
                  Want to check billing options?{" "}
                  <a
                    onClick={handleBillingOptions}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Click here
                  </a>
                </span>
              )}

              <br />
            </>
          ) : (
            <>
              <h2>Create room</h2>
              <p className="bodyText">
                The subscription models are billed on a monthly basis per room.
                In addition to the monthly fee, there is an initial setup fee of
                $149 per room.
              </p>
            </>
          )}
        </div>
      </div>

      <div className="footerProfile">
        <a
          className="logoutLink"
          onClick={handelResetRedirect}
          style={{ cursor: "pointer" }}
        >
          Reset password
        </a>
        <a className="contSupport" href="mailto:support@arenacompetition.world">
          Contact Support
        </a>
        <a className="logoutLink" onClick={handleLogout}>
          Log out
        </a>
      </div>

      <div className="footerProfileMobile">
        <a className="contSupport" href="mailto:support@arenacompetition.world">
          Contact Support
        </a>
        <a
          className="logoutLink"
          onClick={handelResetRedirect}
          style={{ cursor: "pointer" }}
        >
          Reset password
        </a>
        <a className="logoutLink" onClick={handleLogout}>
          Log out
        </a>
      </div>
    </div>
  );
};

export default Profile;
