import React, { useEffect } from "react";
import "../../../style/landing/BanerStyle.scss";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import leftLine from "../../../images/leftLineBlue.svg";
import rightLine from "../../../images/rightLineRed.svg";
// import gif from "../../../images/gif.gif";
// import arrowDown from "../../../images/icons/Icon_Arrow.svg";

const Baner = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  const handleClickScroll = () => {
    const element = document.getElementById("about-the-experience");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isMobile = window.innerWidth < 768;
  let leftIn = {};
  let rightIn = {};

  if (!isMobile) {
    leftIn = {
      visible: { x: 0 },
      hidden: { x: -100, isMobile },
    };

    rightIn = {
      visible: { x: 0 },
      hidden: { x: 100, isMobile },
    };
  }

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <div className="banerSection">
      <div className="imagesGif">
        {/* <img className="gif" src={gif} alt="gif" /> */}
        <h5>the escape revolution</h5>
        <div className="lines">
          <div className="leftLine">
            <motion.img
              ref={ref}
              variants={leftIn}
              initial="hidden"
              animate={control}
              transition={{ type: "spring" }}
              src={leftLine}
              alt="leftLine"
            />
          </div>
          <div className="rightLine">
            <motion.img
              ref={ref}
              variants={rightIn}
              initial="hidden"
              animate={control}
              transition={{ type: "spring" }}
              src={rightLine}
              alt="rightLineRed"
            />
          </div>
        </div>
        {/* <img className="gifSecond" src={gif} alt="gif" /> */}
      </div>
      <h1>A mind blowing, escape -room experience.</h1>
      {/* <button className="btn-scroll" onClick={handleClickScroll}>
        <img src={arrowDown} alt="arrowDown" />
      </button> */}
      <div className="center-con">
        <button className="round" onClick={handleClickScroll}>
          <div id="cta">
            <span className="arrow primera next "></span>
            {/* <span className="arrow segunda next "></span> */}
          </div>
        </button>
      </div>
    </div>
  );
};

export default Baner;
