import React, { useState } from "react";
import LogoPosition from "../../components/LogoPosition";
import show from "../../../src/images/view.png";
import hide from "../../../src/images/hide.png";
import "../../style/RestartPassword.scss";
import Button from "../../elements/Button";
import axios from "axios";
import { API } from "../../services/base";
import { useNavigate } from "react-router-dom";
import { useTransition, animated } from "react-spring";

const Restart = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [erros, setError] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const navigate = useNavigate();

  const transitions = useTransition(openPopup, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const popupTransitions = useTransition(openPopup, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const handlePasswordChange = () => {
    const data = {
      token: localStorage.getItem("accessTokenLogin"),
      password: password,
      passwordConfirm: repeatPassword,
    };
    axios
      .post(`${API}/company`, data)
      .then((res) => {
        setOpenPopup(true);
        setTimeout(() => {
          navigate("/profile");
        }, 2000);
      })
      .catch((err) => {
        setError(err?.response?.data?.messages);
      });
  };

  const handleRedirect = () => {
    navigate("/");
  };

  const handlePasswordChangeState = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
    setError("");
  };

  return (
    <div className="restartPasswordScreen">
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpenPopup(false)}
            ></animated.div>
          )
      )}

      {popupTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup">
              <div className="matchContent">
                <h3>Password restart successfully!</h3>
              </div>
            </animated.div>
          )
      )}
      <div className="content">
        <a onClick={handleRedirect}>
          {" "}
          <LogoPosition />
        </a>
        <div className="formContent">
          <div className="innerContent">
            <h3>CHANGE PASSWORD</h3>
            <div className="form">
              <div style={{ position: "relative" }}>
                <label>Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password..."
                  onChange={handlePasswordChangeState}
                />
                {erros ? <p className="errorMessage">{erros.password}</p> : ""}
                {!showPassword && (
                  <img
                    src={show}
                    className="passwordIcon"
                    onClick={() => {
                      setShowPassword(true);
                    }}
                  />
                )}
                {showPassword && (
                  <img
                    src={hide}
                    className="passwordIcon"
                    onClick={() => {
                      setShowPassword(false);
                    }}
                  />
                )}
              </div>
              <div style={{ position: "relative" }}>
                <label>Repeat Password</label>
                <input
                  type={showRepeatPassword ? "text" : "password"}
                  placeholder="Enter Password..."
                  onChange={handleRepeatPasswordChange}
                />
                {erros ? (
                  <p className="errorMessage">{erros.passwordConfirm}</p>
                ) : (
                  ""
                )}
                {!showRepeatPassword && (
                  <img
                    src={show}
                    className="passwordIcon"
                    onClick={() => {
                      setShowRepeatPassword(true);
                    }}
                  />
                )}
                {showRepeatPassword && (
                  <img
                    src={hide}
                    className="passwordIcon"
                    onClick={() => {
                      setShowRepeatPassword(false);
                    }}
                  />
                )}
              </div>
              {password && repeatPassword ? (
                <Button title="Password reset" onClick={handlePasswordChange} />
              ) : (
                <Button
                  title="Password reset"
                  disable={true}
                  className="disableButton"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Restart;
