import axios from "axios";
import { getToken } from "../utils/Common";
import { API } from "./base";
import { Navigate } from "react-router-dom";

const instance = axios.create({
  baseURL: API,
});

instance.interceptors.request.use(async (config) => {
  const token = await getToken("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403) {
      // Redirect using Navigate
      Navigate("/"); // Assuming you're using this in a React component
    }
    return Promise.reject(error);
  }
);

export default instance;
