import React, { useState } from "react";
import "../../style/LoginScreen.scss";
import LogoPosition from "../../components/LogoPosition";
import Button from "../../elements/Button";
import show from "../../../src/images/view.png";
import hide from "../../../src/images/hide.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/base";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleFormSubmit = () => {
    const data = {
      email: username,
      password: password,
    };
    axios
      .post(`${API}/auth/login`, data)
      .then((res) => {
        localStorage.setItem("accessTokenLogin", res.data.data.accessToken);
        if (localStorage.getItem("accessTokenLogin")) {
          navigate("/profile");
        }
      })
      .catch((err) => {
        console.log(err?.response?.data?.errors);
        setError(err?.response?.data?.messages);
      });
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleRedirect = () => {
    navigate("/");
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setError(""); // Reset the error when username changes
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError(""); // Reset the error when password changes
  };

  return (
    <div className="loginScreen">
      <div className="content">
        <a onClick={handleRedirect} style={{ cursor: "pointer" }}>
          {" "}
          <LogoPosition />
        </a>
        <div className="formContent">
          <div className="innerContent">
            <h3>LOGIN TO ARENA!</h3>
            <div className="form">
              <div>
                <label>Email</label>
                <input
                  type="text"
                  placeholder="Enter Email..."
                  onChange={handleUsernameChange}
                />
                {error ? <p className="errorMessage">{error.email}</p> : ""}
              </div>
              <div style={{ position: "relative" }}>
                <label>Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password..."
                  onChange={handlePasswordChange}
                />
                {!showPassword && (
                  <img
                    src={show}
                    className="passwordIcon"
                    onClick={() => {
                      setShowPassword(true);
                    }}
                  />
                )}
                {showPassword && (
                  <img
                    src={hide}
                    className="passwordIcon"
                    onClick={() => {
                      setShowPassword(false);
                    }}
                  />
                )}
                <span className="forgotPassword" onClick={handleForgotPassword}>
                  Forgot password
                </span>
                {error ? <p className="errorMessage">{error.password}</p> : ""}
                {error ? <p className="errorMessage">{error.message}</p> : ""}
              </div>

              {username && password ? (
                <Button
                  className="welcomeScreenBtn"
                  title="LOG IN"
                  onClick={handleFormSubmit}
                />
              ) : (
                <Button
                  className="welcomeScreenBtnDisabled"
                  title="LOG IN"
                  disable={true}
                />
              )}
            </div>
          </div>
        </div>
        <div className="support">
          <a href="mailto:support@arenacompetition.world">Contact Support</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
