import React from "react";

// Style
import "../../../style/home/Rooms.scss";

// Images
import Baner from "../../../images/Top_10_Challange_Baner.png";

const GameMaster = () => {
  const handleRoute = () => {
    const contactElement = document.getElementById("contact");
    if (contactElement) {
      contactElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="roomWrapper">
      <div className="line"></div>
      <div className="box">
        <h2>your game masters will love arena’s effortless setup!</h2>
      </div>
      <div className="boxList">
        <ul>
          <li>
            <h5>Lightning-Fast Enrollment</h5>
            <p>
              Thanks to our intuitive interface, profiles can be easily set up
              for your escape rooms, and your Game Masters can enroll a team in
              a live match in 15 seconds.
            </p>
          </li>

          <li>
            <h5>Automatic Player COUNT</h5>
            <p>
              ARENA's facial recognition feature automatically counts players,
              and adds them to our algorithm before each match.
            </p>
          </li>

          <li>
            <h5>worldwide servers</h5>
            <p style={{ width: "100%" }}>
              ARENA operates on 7 Amazon AWS servers across 6 continents to
              ensure super-fast response times, essential for intercontinental
              matches and face-offs.
            </p>
          </li>
        </ul>

        <button className="contactUs wordlWide" onClick={() => handleRoute()}>
          <span>Get Started Here</span>
        </button>
      </div>

      <img className="banerImage" src={Baner} alt={Baner} />
    </div>
  );
};

export default GameMaster;
