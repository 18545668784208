import React, { useState } from "react";
import LogoPosition from "../../components/LogoPosition";
import Button from "../../elements/Button";
import { useTransition, animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import "../../style/ForgotPassword.scss";
import axios from "axios";
import { API } from "../../services/base";
import { TailSpin } from "react-loader-spinner";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const transitions = useTransition(openPopup, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 300,
  });

  const popupTransitions = useTransition(openPopup, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(100%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    delay: 300,
  });

  const handleSubmit = () => {
    setLoader(true);
    const data = {
      email: email,
    };
    axios
      .post(`${API}/auth/forgot-password`, data)
      .then((res) => {
        localStorage.setItem("forgotEmail", email);
        setLoader(false);
        setOpen(true);
      })
      .catch((err) => {
        setLoader(false);
        setError(err?.response?.data?.messages);
      });
  };

  const resendEmail = () => {
    const data = {
      email: localStorage.getItem("forgotEmail"),
    };
    axios
      .post(`${API}/auth/forgot-password`, data)
      .then((res) => {
        setOpen(true);
        setOpenPopup(true);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGoBack = () => {
    navigate("/login");
  };

  const handleRedirect = () => {
    navigate("/");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(""); // Reset the error when username changes
  };

  return (
    <div className="forgotPasswordScreen">
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="popupBackground"
              onClick={() => setOpenPopup(false)}
            ></animated.div>
          )
      )}

      {popupTransitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="matchPopup roomPopup">
              <div className="matchContent">
                <h3>SUCCESSFULLY RESENT!</h3>
              </div>
            </animated.div>
          )
      )}
      <div className="content">
        <a onClick={handleRedirect} style={{ cursor: "pointer" }}>
          {" "}
          <LogoPosition />
        </a>
        <div className={loader ? "loadFormContent" : "formContent"}>
          <div className="innerContent">
            {!open ? (
              <>
                {!loader ? (
                  <>
                    {" "}
                    <h3>FORGOT PASSWORD</h3>
                    <div className="form">
                      <div>
                        <label>Email</label>
                        <input
                          type="text"
                          placeholder="Enter Email..."
                          onChange={handleEmailChange}
                        />
                        {error ? (
                          <p className="errorMessage">{error.email}</p>
                        ) : (
                          ""
                        )}
                      </div>

                      {email ? (
                        <Button
                          className="welcomeScreenBtn"
                          title="Submit"
                          onClick={handleSubmit}
                        />
                      ) : (
                        <Button
                          className="welcomeScreenBtnDisable"
                          title="Submit"
                          disable={true}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <div className="loaderState">
                    <h3>
                      <TailSpin
                        height="40"
                        width="40"
                        color="#808080"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                      sending...{" "}
                    </h3>
                  </div>
                )}
              </>
            ) : (
              <>
                <h3>EMAIL WAS SENT!</h3>
                <p>
                  Didn't get email? <span onClick={resendEmail}>Resend!</span>
                </p>
                <Button
                  className="welcomeScreenBtn"
                  title="Go Back"
                  onClick={handleGoBack}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
