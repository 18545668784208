import React, { useState, useEffect } from "react";
import "../../../style/landing/Faq.scss";

import faqImage from "../../../images/faqImage.svg";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Faq = () => {
  const [expanded, setExpanded] = useState(false);
  const control = useAnimation();
  const [ref, inView] = useInView();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const isMobile = window.innerWidth < 768;
  let leftIn = {};
  let rightIn = {};

  if (!isMobile) {
    leftIn = {
      visible: { x: 0 },
      hidden: { x: -100 },
    };
    rightIn = {
      visible: { x: 0 },
      hidden: { x: 100 },
    };
  }

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <div id="faq" className="faqSection">
      <div className="faq container">
        <motion.div
          ref={ref}
          variants={leftIn}
          initial="hidden"
          animate={control}
          transition={{ type: "spring" }}
          className="accordions"
        >
          <h3>Frequently asked questions</h3>
          <div className="accordions">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="globalDropdown"
              >
                <Typography component={"span"} className="teamDropdown">
                  How do you participate in The Revolution?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component={"span"}>
                  Want to compete with the players around the world? The Escape
                  Revolution takes the best of escape experiences and amplifies
                  it. We’re rugged, real, bold, and authentic. Want to compete
                  with the players around the world? The Escape Revolution takes
                  the best of escape experiences and amplifies it. We’re rugged,
                  real, bold, and authentic. Want to compete with the players
                  around the world? The Escape Revolution takes the best of
                  escape experiences and amplifies it. We’re rugged, real, bold,
                  and authentic. Want to compete yith the players around the
                  world? The Escape Revolution takes the best of escape
                  experiences and amplifies it. We’re rugged, real, bold, and
                  authentic.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className="globalDropdown"
              >
                <Typography component={"span"} className="teamDropdown">
                  Is there a time limit while playing?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component={"span"}>
                  Want to compete with the players around the world? The Escape
                  Revolution takes the best of escape experiences and amplifies
                  it. We’re rugged, real, bold, and authentic. Want to compete
                  with the players around the world? The Escape Revolution takes
                  the best of escape experiences and amplifies it. We’re rugged,
                  real, bold, and authentic. Want to compete with the players
                  around the world? The Escape Revolution takes the best of
                  escape experiences and amplifies it. We’re rugged, real, bold,
                  and authentic. Want to compete yith the players around the
                  world? The Escape Revolution takes the best of escape
                  experiences and amplifies it. We’re rugged, real, bold, and
                  authentic.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="globalDropdown"
              >
                <Typography component={"span"} className="teamDropdown">
                  How do you participate in The Revolution?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component={"span"}>
                  Want to compete with the players around the world? The Escape
                  Revolution takes the best of escape experiences and amplifies
                  it. We’re rugged, real, bold, and authentic. Want to compete
                  with the players around the world? The Escape Revolution takes
                  the best of escape experiences and amplifies it. We’re rugged,
                  real, bold, and authentic. Want to compete with the players
                  around the world? The Escape Revolution takes the best of
                  escape experiences and amplifies it. We’re rugged, real, bold,
                  and authentic. Want to compete yith the players around the
                  world? The Escape Revolution takes the best of escape
                  experiences and amplifies it. We’re rugged, real, bold, and
                  authentic.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="globalDropdown"
              >
                <Typography>
                  How do you participate in The Revolution?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component={"span"} className="teamDropdown">
                  Want to compete with the players around the world? The Escape
                  Revolution takes the best of escape experiences and amplifies
                  it. We’re rugged, real, bold, and authentic. Want to compete
                  with the players around the world? The Escape Revolution takes
                  the best of escape experiences and amplifies it. We’re rugged,
                  real, bold, and authentic. Want to compete with the players
                  around the world? The Escape Revolution takes the best of
                  escape experiences and amplifies it. We’re rugged, real, bold,
                  and authentic. Want to compete yith the players around the
                  world? The Escape Revolution takes the best of escape
                  experiences and amplifies it. We’re rugged, real, bold, and
                  authentic.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </motion.div>
        <motion.div
          ref={ref}
          variants={rightIn}
          initial="hidden"
          animate={control}
          transition={{ type: "spring" }}
          className="faqImage animation"
        >
          <img src={faqImage} alt="faqImage" />
        </motion.div>
      </div>
    </div>
  );
};

export default Faq;
