import React, { useState, useEffect } from "react";
import { API } from "../../services/base";
import axios from "../../services/https";
import { useNavigate, useParams } from "react-router-dom";

import "../../style/gameMaster/JoinMatch.scss";
import LogoPosition from "../../components/LogoPosition";
import { Link } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import arrow from "../../images/icons/Icon_Arrow.svg";
import team from "../../images/team.jpg";
import Button from "../../elements/Button";

import { useDispatch } from "react-redux";
import { saveTeam } from "../../redux/actions/app.js";
import { useSelector } from "react-redux";
import AvailableMatches from "./AvailableMatches";
import GoBack from "../GoBack";

import Echo from "laravel-echo";
import { domen } from "../../services/base";

const JoinMatch = () => {
  const [expanded, setExpanded] = useState(false);
  const [game, setGame] = useState(1);
  const { id } = useParams();
  const [matchId, setMatchiTD] = useState(localStorage.getItem("gameId"));

  // const [time, setTime] = useState(localStorage.getItem("time"));

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleJoinMatch = () => {
    // navigate("/match-lobby");

    const data = {
      teamId: app.team.id,
      game: matchId,
    };

    axios
      .post(`${API}/game/${matchId}/join`, data)
      .then((res) => {
        navigate("/match-lobby");
        window.scrollTo(0, 0);
        // dispatch(saveTeam(res.data.data));
        localStorage.setItem("startTime", res.data.data.estimatedStartTime);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="joinMatch">
      <GoBack />
      <div className="joinMathWrap">
        <LogoPosition />
        <Link className="goBack" to="/available-matches">
          <img src={arrow} /> Go back
        </Link>
        <h2>Match Lobby </h2>
        {(() => {
          if (app.team.id === app.kickedMember) {
            return (
              <Button
                title="Join this match"
                className="joinMatchBtnDisabled"
                onClick={handleJoinMatch}
                disabled={true}
              />
            );
          } else {
            return (
              <Button
                title="Join this match"
                className="joinMatchBtn"
                onClick={handleJoinMatch}
              />
            );
          }
        })()}

        <div className="teamCards">
          <div className="card">
            {app.availableteams.map((item, index) => {
              return (
                <Accordion
                  key={item.id}
                  expanded={expanded === item.id}
                  onChange={handleChange(item.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="globalDropdown"
                  >
                    <Typography component={"span"} className="teamDropdown">
                      <span>Team Name</span> {item.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordionDetails">
                    <Typography
                      component={"span"}
                      className="teamImageAccordion"
                    >
                      <div className="teamImage">
                        <div className="image">
                          <img
                            src={`https://prod.arenacompetition.world${item.image}`}
                            alt="team"
                          />
                        </div>
                      </div>
                      <div className="teamInfo">
                        <div className="roomFranchize">
                          <span>Escape Room Company</span>
                          <h5>{item.companyName}</h5>
                        </div>

                        <div className="location">
                          <span>Escape Room Location</span>
                          {!item.roomLocation ? (
                            <h5>-</h5>
                          ) : (
                            <h5>{item.roomLocation}</h5>
                          )}
                        </div>

                        <div className="roomName">
                          <span>Escape Room Name</span>
                          <h5>{item.roomName}</h5>
                        </div>

                        <div className="difficultyLevel">
                          <span>Difficulty Level</span>
                          <h5>{item.roomDifficultyLevel}</h5>
                        </div>

                        <div className="solvingFrame">
                          <span>Time Frame For Solving</span>
                          <h5>{item.roomDuration} minutes</h5>
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>

        {app.availableteams.length > 3
          ? (() => {
              if (app.team.id === app.kickedMember) {
                return (
                  <Button
                    title="Join this match"
                    className="joinMatchBtnDisabled"
                    onClick={handleJoinMatch}
                    disabled={true}
                  />
                );
              } else {
                return (
                  <Button
                    title="Join this match"
                    className="joinMatchBtn"
                    onClick={handleJoinMatch}
                  />
                );
              }
            })()
          : ""}

        {app.availableteams.length > 3 ? (
          <Link className="goBackDownPage" to="/available-matches">
            <img src={arrow} /> Go back
          </Link>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default JoinMatch;
