import Lander from "./pages/landing/Lander";
import CheckScreen from "./pages/gameMaster/CheckScreen";
import WelcomeScreen from "./pages/gameMaster/WelcomeScreen";
import Team from "./pages/gameMaster/Team";
import AvailableMatches from "./pages/gameMaster/AvailableMatches";
import InGame from "./pages/gameMaster/InGame";
import JoinMatch from "./pages/gameMaster/JoinMatch";
import CreateMatch from "./pages/gameMaster/CreateMatch";
import TokenErrorPage from "./pages/gameMaster/TokenErrorPage";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./style/global.scss";

import GameTest from "./pages/gameMaster/GameTest";

import reduxStore from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Hint from "./pages/gameMaster/Hint";
import SoloMatch from "./pages/gameMaster/SoloMatch";
import MatchLobby from "./pages/gameMaster/MatchLobby";
import MyMatchLobby from "./pages/gameMaster/MyMatchLobby";
import ListingPage from "./pages/gameMaster/ListingPage";
import Congratulations from "./pages/gameMaster/Congratulations";
import New from "./pages/gameMaster/New";
import WinnerDisplay from "./pages/gameMaster/WinnerDisplay";
import MyPlatform from "./pages/gameMaster/MyPlatform";
import SoloPlatform from "./pages/gameMaster/SoloPlatform";
import Timer from "./pages/gameMaster/Timer";
import { useEffect } from "react";
import WinningDisplay from "./pages/gameMaster/WinningDisplay";
import Testing from "./pages/gameMaster/Testing";
import NewLander from "./pages/newlanding/NewLander";
import Maintanace from "./pages/Maintanace";
import Login from "./pages/Auth/Login";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import Restart from "./pages/Auth/Restart";
import Profile from "./pages/Profile";
import Reception from "./pages/Reception";

// New Landing
import NewLanding from "./pages/home/Index";

function App() {
  const { store, persistor } = reduxStore();

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              <Route path="/new/:token" element={<New />}></Route>
              <Route path="/winner-display" element={<WinnerDisplay />}></Route>
              <Route
                path="/winning-display"
                element={<WinningDisplay />}
              ></Route>
              <Route path="/lander" element={<Lander />}></Route>

              {/* New Landing */}
              <Route path="/" element={<NewLanding />}></Route>

              <Route path="/home" element={<NewLander />}></Route>
              <Route path="/game-test" element={<GameTest />}></Route>
              <Route path="/hints" element={<Hint />}></Route>
              <Route path={`/check/:token`} element={<CheckScreen />}></Route>
              <Route
                path={`/welcome-screen`}
                element={<WelcomeScreen />}
              ></Route>
              <Route path="team" element={<Team />}></Route>
              <Route
                path="available-matches"
                element={<AvailableMatches />}
              ></Route>
              <Route path="testing" element={<Testing />}></Route>
              <Route path="in-game/:token" element={<InGame />}></Route>
              <Route path="join-match/:id" element={<JoinMatch />}></Route>
              <Route path="solo-match" element={<SoloMatch />}></Route>
              <Route path="create-match" element={<CreateMatch />}></Route>
              <Route path="match-lobby" element={<MatchLobby />}></Route>
              <Route path="my-match-lobby" element={<MyMatchLobby />}></Route>
              <Route path="listing-page" element={<ListingPage />}></Route>
              <Route path="my-platform" element={<MyPlatform />}></Route>
              <Route path="solo-platform" element={<SoloPlatform />}></Route>
              <Route path="timer" element={<Timer />}></Route>
              <Route
                path="congratulations"
                element={<Congratulations />}
              ></Route>
              <Route path="token-error" element={<TokenErrorPage />}></Route>

              <Route path="/maintanance" element={<Maintanace />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route
                path="/forgot-password"
                element={<ForgotPassword />}
              ></Route>
              <Route path="/password/reset" element={<ResetPassword />} />
              <Route path="/restart" element={<Restart />} />

              <Route path="/profile" element={<Profile />} />

              <Route path="/reception/:token" element={<Reception />} />

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
