import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// Style
import "../../../style/home/Header.scss";

// Images
import logo from "../../../images/ic_logo_home.svg";

export const breakpoints = {
  mobile: `max-width: 1200px`,
  desktop: `min-width: 1200px`,
};

const Header = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });

  const handleLoginRoute = () => {
    navigate("/login");
  };

  return (
    <div className="headerWrapper">
      <div className="headerContent">
        <img src={logo} alt={logo} />

        {!isMobile && (
          <div className="loginHeaderButton">
            <button className="button" onClick={handleLoginRoute}>
              Log in
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
