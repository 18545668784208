import React from "react";

import Header from "./components/Header";
import Baner from "./components/Baner";
import Rooms from "./components/Rooms";
import Matches from "./components/Matches";
import GameMaster from "./components/GameMaster";
import NextLevel from "./components/NextLevel";
import TopTen from "./components/TopTen";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

import "../../style/home/Main.scss";

const Index = () => {
  return (
    <>
      <Header />
      <Baner />
      <Rooms />
      <Matches />
      <GameMaster />
      <NextLevel />
      <TopTen />
      <Contact />
      <Footer />
    </>
  );
};

export default Index;
