export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getToken = () => {
  const token = localStorage.getItem("token") || null;

  if (token == null) {
    //try from url
    token = window.location.href.split("/").pop() || null;
  }

  return token;
};

export const setUserSession = (accessToken, user) => {
  localStorage.setItem("token", accessToken);
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeUserSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};
