import axios from "axios";

const api = axios.create({
  baseURL: "https://prod.arenacompetition.world/api", // your API base URL
});

api.interceptors.request.use(
  (config) => {
    const tokenLogin = localStorage.getItem("accessTokenLogin");
    if (tokenLogin) {
      config.headers.Authorization = `Bearer ${tokenLogin}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getDetails = () => {
  return api.get("/company");
};

export const getPrice = () => {
  return api.get("/company/packages");
};

export default api;
