import React, { useState } from "react";
import Slider from "react-slick";
import "../../../style/landing/HowItWorks.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../../../images/1.jpg";
import img2 from "../../../images/2.jpg";
import img3 from "../../../images/3.jpg";
import img4 from "../../../images/4.jpg";

const images = [img1, img2, img3, img4];

const HowItWorks = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    centerMode: true,
    beforeChange: (current, next) => setSlideIndex(next),
  };

  var mob = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
  };

  return (
    <div className="section">
      <div className="slider">
        <Slider {...settings}>
          {images.map((img, index) => (
            <div
              className={index === slideIndex ? "slide slide-active" : "slide"}
              key={index}
            >
              <img src={img} alt="slike" />
              <div>tejstjjsjjs</div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="mobileSlider">
        <Slider {...mob}>
          <div>
            <img src={img1} alt="slike" />
            <h5>How it works</h5>
            <div className="line"></div>
            <h2>Gather your team</h2>
            <p>
              Want to compete with the players around the world? The Escape
              Revolution takes the best of escape experiences and amplifies it.
              We’re rugged, real, bold, and authentic. Want to compete with the
              players around the world? The Escape Revolution takes the best of
              escape experiences and amplifies it. We’re rugged, real, bold, and
              authentic.
            </p>
          </div>
          <div>
            <img src={img1} alt="slike" />
            <h5>How it works</h5>
            <div className="line"></div>
            <h2>Gather</h2>
            <p>
              Want to compete with the players around the world? The Escape
              Revolution takes the best of escape experiences and amplifies it.
              We’re rugged, real, bold, and authentic. Want to compete with the
              players around the world? The Escape Revolution takes the best of
              escape experiences and amplifies it. We’re rugged, real, bold, and
              authentic.
            </p>
          </div>
          <div>
            <img src={img1} alt="slike" />
            <h5>How it works</h5>
            <div className="line"></div>
            <h2>Gather team</h2>
            <p>
              Want to compete with the players around the world? The Escape
              Revolution takes the best of escape experiences and amplifies it.
              We’re rugged, real, bold, and authentic. Want to compete with the
              players around the world? The Escape Revolution takes the best of
              escape experiences and amplifies it. We’re rugged, real, bold, and
              authentic.
            </p>
          </div>
          <div>
            <img src={img1} alt="slike" />
            <h5>How it works</h5>
            <div className="line"></div>
            <h2>Gather your team</h2>
            <p>
              Want to compete with the players around the world? The Escape
              Revolution takes the best of escape experiences and amplifies it.
              We’re rugged, real, bold, and authentic. Want to compete with the
              players around the world? The Escape Revolution takes the best of
              escape experiences and amplifies it. We’re rugged, real, bold, and
              authentic.
            </p>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default HowItWorks;
