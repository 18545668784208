import React, { useState } from "react";
import "../../style/landing/Navigation.scss";

import logo from "../../images/logo.svg";
import hamburgerMenu from "../../images/icons/Icon_Hamburger.svg";
import close from "../../images/icons/Icon_Close.svg";

import { useTransition, animated } from "react-spring";

import Button from "../../elements/Button.js";

const Header = () => {
  const [open, setOpen] = useState(false);

  // Menu transition
  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const menuTransitions = useTransition(open, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
  });

  return (
    <div className="navigation">
      <div className="menu">
        <div>
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="hamburgerMenu">
          <img
            src={hamburgerMenu}
            alt="hamburgerMenu"
            onClick={() => setOpen(!open)}
          />

          {transitions(
            (styles, item) =>
              item && (
                <animated.div
                  style={styles}
                  className="menuBackground"
                  onClick={() => setOpen(false)}
                ></animated.div>
              )
          )}

          {/* Menu sidebar */}
          {menuTransitions(
            (styles, item) =>
              item && (
                <animated.div style={styles} className="mobileContentMenu">
                  <div className="mobileMenuHeader">
                    <div className="mobileTitle">
                      <img src={logo} alt="logo" />
                    </div>
                    <div className="closeMenu">
                      <img
                        src={close}
                        alt="close"
                        onClick={() => setOpen(!open)}
                      />
                    </div>
                  </div>
                  <div className="menuItems">
                    <ul>
                      <li>
                        <a href="/">ABOUT THE EXPERIENCE</a>
                      </li>
                      <li>
                        <a href="/">HOW IT WORKS</a>
                      </li>
                      <li>
                        <a href="/">FAQ</a>
                      </li>
                      <li>
                        <a href="/">Menu 4</a>
                      </li>
                    </ul>
                  </div>
                </animated.div>
              )
          )}
          {/* End menu sidebar */}
        </div>

        <div className="list">
          <ul>
            <li>
              <a href="/#about-the-experience">ABOUT THE EXPERIENCE</a>
            </li>
            <li>
              <a href="/#how-it-works">HOW IT WORKS</a>
            </li>
            <li>
              <a href="/#faq">FAQ</a>
            </li>
            <li>
              {/* <a href="/" className="button">
                Button
              </a> */}
              <Button title="Let’s do it!" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
