import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// Style
import "../../../style/home/Baner.scss";

export const breakpoints = {
  mobile: `(max-width: 768px)`,
  tablet: `(min-width: 769px) and (max-width: 1199px)`,
  desktop: `(min-width: 1200px)`,
};

const Baner = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: breakpoints.mobile });
  const isTablet = useMediaQuery({ query: breakpoints.tablet });

  const handleLoginRoute = () => {
    navigate("/login");
  };

  return (
    <div className="banerWrapper">
      <div className="banerContent">
        {isMobile ? (
          <h1 className="mobile">
            ARENA <br /> Changes <br /> Everything <br /> for Escape <br />{" "}
            Rooms
          </h1>
        ) : (
          <>
            {" "}
            {isTablet ? (
              <h1 className="desktop">
                ARENA Changes <br /> Everything for <br /> Escape Rooms
              </h1>
            ) : (
              <h1 className="desktop">
                ARENA Changes <br /> Everything <br /> for Escape Rooms
              </h1>
            )}
          </>
        )}

        <div className="chipWrapper">
          <div className="chip">
            <h3>REVENUE INCREASE</h3>
          </div>
          <div className="chip">
            <h3>GLOBAL COMPETITION</h3>
          </div>
          <div className="chip">
            <h3>PATENTED SOFTWARE</h3>
          </div>
        </div>

        {isMobile ? (
          <>
            <p className="mobileText">
              ARENA is the world's first and only software that allows escape
              rooms to compete in real-time from anywhere on the planet.
            </p>
            <div className="loginHeaderButton" onClick={handleLoginRoute}>
              <button className="button">Log in</button>
            </div>
          </>
        ) : (
          <>
            {isTablet ? (
              <>
                <p className="desktopText">
                  ARENA is the world's first and only software that allows
                  escape <br /> rooms to compete in real-time from anywhere on
                  the planet.
                </p>
                <div className="loginHeaderButton" onClick={handleLoginRoute}>
                  <button className="button">Log in</button>
                </div>
              </>
            ) : (
              <p className="desktopText">
                ARENA is the world's first and only software that allows escape{" "}
                <br /> rooms to compete in real-time from anywhere on the
                planet.
              </p>
            )}
          </>
        )}

        {/* {isMobile ? (
          <>
            <p className="mobileText">
              ARENA is the world's first and only software that allows escape
              rooms to compete in real-time from anywhere on the planet.
            </p>
            <div className="loginHeaderButton" onClick={handleLoginRoute}>
              <button className="button">Log in</button>
            </div>
          </>
        ) : (
          <p className="desktopText">
            ARENA is the world's first and only software that allows escape{" "}
            <br /> rooms to compete in real-time from anywhere on the planet.
          </p>
        )} */}
      </div>
    </div>
  );
};

export default Baner;
