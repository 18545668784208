export const SAVE_TEAM = "SAVE_TEAM";
export const SAVE_ROOM = "SAVE_ROOM";
export const SAVE_GAME = "SAVE_GAME";
export const UPDATE_GAME = "UPDATE_GAME";
export const GIVE_HINT = "GIVE_HINT";

export const UPDATE_TEAMS = "UPDATE_TEAMS";
export const TEAMS_ARRAY = "TEAMS_ARRAY";
export const GET_HINTS = "GET_HINTS";
export const AVAILABLE_MATCHES_TEAMS = "AVAILABLE_MATCHES_TEAMS";

export const JOINED_MEMBER = "JOINED_MEMBER";
export const ARRAY_MEMBERS = "ARRAY_MEMBERS";
export const UPDATE_MEMBERS = "UPDATE_MEMBERS";

export const TEST = "TEST";
export const PROGRESSID = "PROGRESSID";
export const PROGRESSCOUNT = "PROGRESSCOUNT";

// NEW
export const GET_TEAMS = "GET_TEAMS";
export const STARTED_GAME = "STARTED_GAME";
export const SHOW_GAME_ID = "SHOW_GAME_ID";
export const COMPLETED_STEPS = "COMPLETED_STEPS";
export const GIVEN_HINT = "GIVEN_HINT";
export const GIVEN_HINT_PAGE = "GIVEN_HINT_PAGE";
export const GET_ROOM_RESET = "GET_ROOM_RESET";
export const SAVE_MYMATCH_LOBBY = "SAVE_MYMATCH_LOBBY";
export const FINISHED_GAME = "FINISHED_GAME";
export const START_TIME = "START_TIME";
export const MEMBERS_JOINED = "MEMBERS_JOINED";
export const MEMBER_LEFT = "MEMBER_LEFT";
export const KICKED_TEAM = "KICKED_TEAM";
export const MEMBER_IS_KICKED = "MEMBER_IS_KICKED";
export const TEAM_NEW_VALUE = "TEAM_NEW_VALUE";
export const TEAM_FINISHED = "TEAM_FINISHED";

export const GET_UPDATE_ROOMS = "GET_UPDATE_ROOMS";
