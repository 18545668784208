import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import Button from "../elements/Button";
import { API } from "../services/base";
import "../stripe/CheckoutForm.scss";
import { useTransition, animated } from "react-spring";
import { TailSpin } from "react-loader-spinner";
import "../elements/button.scss";

function CheckoutForm({
  cardId,
  price,
  email,
  priceid,
  cardMethod,
  setOpen,
  setOpenSuccess,
  setOpenFalse,
  fetchDetails,
  totalPrice,
  currencySymbol,
  oneFee,
  fullInfo,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [loader, setLoader] = useState(false);
  const [code, setCode] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [error, setError] = useState("");

  const createSubscription = async () => {
    setLoader(true);
    try {
      const cardElement = elements?.getElement(CardElement);

      if (!cardElement) {
        console.error("Card Element not found");
        return;
      }

      // let paymentMethod;

      // create a payment method
      // if (cardMethod === null) {
      //   paymentMethod = await stripe?.createPaymentMethod({
      //     type: "card",
      //     card: cardElement,
      //     billing_details: {
      //       name: cardId,
      //       email: email,
      //     },
      //   });
      // }

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: "Jenny Rosen",
        },
      });

      console.log(paymentMethod, "paymentMethod");
      console.log(error, "error");

      const accessToken = localStorage.getItem("accessTokenLogin");

      // call the backend to create subscription
      const response = await fetch(`${API}/company/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod?.id,
          priceId: priceid,
          couponCode: code,
        }),
      }).then((res) => {
        if (res.ok) {
          return res.json().then(() => {
            setLoader(false);
            setOpen(false);

            setTimeout(() => {
              setOpenSuccess(true);
              fetchDetails();
            }, 500);

            setTimeout(() => {
              setOpenSuccess(false);
            }, 2500);
          });
        } else {
          return res.json().then((error) => {
            localStorage.setItem("error", error.errorMessage);
            setLoader(false);
            setOpen(false);

            setTimeout(() => {
              setOpenFalse(true);
            }, 500);

            setTimeout(() => {
              setOpenFalse(false);
            }, 2500);

            console.error("Error:", error);
          });
        }
      });

      const confirmPayment = await stripe?.confirmCardPayment(
        response.clientSecret
      );

      if (confirmPayment?.error) {
        alert(confirmPayment.error.message);
      } else {
        alert("Success! Check your email for the invoice.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(currencySymbol, "currencySymbol");

  const symbolCurr = (
    <div
      dangerouslySetInnerHTML={{
        __html: currencySymbol,
      }}
    />
  );

  return (
    <div className="stripeForm">
      <CardElement
        className="card-element"
        options={{
          hidePostalCode: true,
          disableLink: true,
          style: {
            border: "2px solid red",
            base: {
              fontSize: "16px",
              color: "#fff",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "red",
            },
          },
        }}
      />
      {!cardMethod && (
        <>
          <input
            style={{ background: "rgba(176, 173, 173, 0.118)" }}
            type="text"
            placeholder="Enter Promo Code..."
            onChange={(e) => setCode(e.target.value)}
          />
        </>
      )}
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        Total:
        <div
          style={{ marginLeft: "4px" }}
          dangerouslySetInnerHTML={{
            __html: currencySymbol,
          }}
        />
        {oneFee + totalPrice}
      </div> */}
      {!loader ? (
        <div className="btn" onClick={createSubscription} disabled={!stripe}>
          <button className="button">
            Total •
            <div
              style={{ marginLeft: "4px" }}
              dangerouslySetInnerHTML={{
                __html: currencySymbol,
              }}
            />{" "}
            {oneFee + totalPrice}
          </button>
        </div>
      ) : (
        <TailSpin
          height="40"
          width="40"
          color="#808080"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass="loaderSpiner"
          visible={true}
        />
      )}
      {!cardMethod && (
        <span className="oneTimeFee">
          {fullInfo} x{" "}
          <div
            style={{ marginLeft: "4px" }}
            dangerouslySetInnerHTML={{
              __html: currencySymbol,
            }}
          />
          {currencySymbol === "A$" ? 655 : 399} =
          <div
            style={{ marginLeft: "4px" }}
            dangerouslySetInnerHTML={{
              __html: currencySymbol,
            }}
          />
          {oneFee}{" "}
          <span className="shortExplanation">(one time setup fee/room)</span>
        </span>
      )}
      <span className="monthlyTotal">
        {fullInfo} x{" "}
        <div
          style={{ marginLeft: "4px" }}
          dangerouslySetInnerHTML={{
            __html: currencySymbol,
          }}
        />{" "}
        {price} =
        <div
          style={{ marginLeft: "4px" }}
          dangerouslySetInnerHTML={{
            __html: currencySymbol,
          }}
        />{" "}
        {totalPrice}{" "}
        <span className="shortExplanation">(monthly package fee/room)</span>
      </span>
      <span className="fullTotal">
        Total =
        <div
          style={{ marginLeft: "4px" }}
          dangerouslySetInnerHTML={{
            __html: currencySymbol,
          }}
        />{" "}
        {oneFee + totalPrice}{" "}
        <span className="shortExplanation">
          {!cardMethod && "(without promo code)"}
        </span>
      </span>
      {/* {fullInfo} - {price} */}
    </div>
  );
}

export default CheckoutForm;
