import React, { useState } from "react";
import contact from "../../../images/img_winning-screen.webp";
import contactMob from "../../../images/img_winning-screen.webp";
import line from "../../../images/workLine.svg";
import contactLines from "../../../images/contactLine.svg";
import contactLinesMob from "../../../images/tackice.png";

import "../../../style/newlanding/contact.scss";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { API } from "../../../services/base";
import { TailSpin } from "react-loader-spinner";

export const breakpoints = {
  mobile: `max-width: 1200px`,
  desktop: `min-width: 1200px`,
};

const Contact = () => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [escapeRoomName, setEscapeRoomName] = useState("");
  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = () => {
    setLoader(true);

    const data = {
      name: name,
      email: email,
      phone: phone,
      escapeRoomName: escapeRoomName,
      content: message,
    };
    axios
      .post(`${API}/contact`, data)
      .then((res) => {
        setPopup(true);
        setLoader(false);
        setTimeout(() => {
          setPopup(false);
        }, 3000);
      })
      .catch((err) => {
        setLoader(false);
        setError(err.response.data.messages);
        console.log(err.response.data.messages);
      });
  };

  const handleName = (e) => {
    setName(e.target.value);
    setError("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
    setError("");
  };

  const escapeRoom = (e) => {
    setEscapeRoomName(e.target.value);
    setError("");
  };

  const handleContent = (e) => {
    setMessage(e.target.value);
    setError("");
  };

  return (
    <div className="contact">
      {popup && (
        <div className="backdrop" onClick={() => setPopup(false)}>
          <div className="popupCard">
            <h3>
              message <br /> Successfully sent
            </h3>
            <p>We will respond to you within 48 hours</p>
          </div>
        </div>
      )}
      {isMobile ? (
        ""
      ) : (
        <img className="contactLines" src={contactLines} alt={contactLines} />
      )}
      {isMobile ? (
        <img src={contactMob} className="image" alt="contactMob" />
      ) : (
        <img className="image" src={contact} alt="contact" />
      )}
      <div className="redLine"></div>
      <div className="container">
        <img className="line" src={line} alt="line" />
        <div className="title" id="contactUs">
          <h1>CONTACT US FOR MORE INFO</h1>
        </div>
        <div className="contactForm">
          <div className="inputWrap">
            <label>Your Name</label>
            <input type="text" placeholder="Enter" onChange={handleName} />
            {error ? <p className="errorMessage">{error.name}</p> : ""}
          </div>
          <div className="inputWrap">
            <label>Email Address</label>
            <input type="email" placeholder="Enter" onChange={handleEmail} />
            {error ? <p className="errorMessage">{error.email}</p> : ""}
          </div>
          <div className="inputWrap">
            <label>Phone Number</label>
            <input type="number" placeholder="Enter" onChange={handlePhone} />
            {error ? <p className="errorMessage">{error.phone}</p> : ""}
          </div>
          <div className="inputWrap">
            <label>Name of Your Escape Room</label>
            <input type="text" placeholder="Enter" onChange={escapeRoom} />
            {error ? (
              <p className="errorMessage">{error.escapeRoomName}</p>
            ) : (
              ""
            )}
          </div>
          <div className="inputWrap">
            <label>Message</label>
            <textarea
              rows="10"
              placeholder="Enter"
              onChange={handleContent}
            ></textarea>
            {error ? <p className="errorMessage">{error.content}</p> : ""}
          </div>
          {loader ? (
            <button className="submitButton" onClick={handleSubmit}>
              <span>
                submit{" "}
                <TailSpin
                  height="20"
                  width="20"
                  color="#808080"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass="loaderContact"
                  visible={true}
                />
              </span>
            </button>
          ) : (
            <button className="submitButton" onClick={handleSubmit}>
              <span>submit</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
